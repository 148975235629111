import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { SortEvent, FilterService, MenuItem } from 'primeng/api';

import { MessageService } from '../services/message.service';
import { IAuthService } from '../auth/iAuthService';
import { LearnerService } from '../services/learner.service';
import { UtilService } from '../services/util.service';
import { UserService } from '../services/user.service';
import { SplashScreenComponent } from '../splash-screen/splash-screen.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { LearnerStatus } from '../models/learnerStatus';
import {
    CombinedAssignment,
    ItemAssignmentRequest,
    CurriculumAssignmentRequest,
} from '../models/combinedAssignment';
import { WaiverRequest } from '../models/waiverRequest';
import { Curriculum, DeferralRequest } from '../models/deferralRequest';
import { TrainingBase } from '../models/trainingBase';
import { ComplianceSetting } from '../models/complianceSetting';
import { forkJoin, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UserFullData } from '../models/userFullData';
import { DirectReport } from '../models/directReport';

@Component({
    selector: 'app-learner-status',
    templateUrl: './learner-status.component.html',
    styleUrls: ['./learner-status.component.css'],
})
export class LearnerStatusComponent implements OnInit, OnDestroy {
    user: UserFullData | DirectReport;
    manager: UserFullData;
    trainings: LearnerStatus[];
    itemForWaiver: WaiverRequest = undefined;
    itemForDeferral: DeferralRequest = undefined;
    showWaiverRequest = false;
    showWaiverConfirmation = false;
    showDeferralConfirmation = false;
    reviewContentUrl: string;

    listOfStatuses: any;
    listOfAssignedBy: any;

    showAdvSearch: boolean = false;
    expandedRows: {} = {};
    areRowsExpanded: boolean = false;

    today: Date = new Date();

    showSplashScreen: string;
    isManager: boolean = false;
    isManagerProxy: boolean = false;
    private sub: any;
    private subData: Subscription;
    private subUser: Subscription;
    private subManager: Subscription;

    globalSearchInput: string;
    myLCodeInput: string;
    titleInput: string;
    dueDateInput: string;

    @ViewChild('dt') table: Table;
    @ViewChild('splashScreen') splashScreen: SplashScreenComponent;
    @ViewChild('longTermLeaveConfirmationDialog')
    longTermLeaveConfirmationDialog: ConfirmationDialogComponent;
    @ViewChild('reviewContentConfirmationDialog')
    reviewContentConfirmationDialog: ConfirmationDialogComponent;
    @ViewChild('waiverWarning') waiverWarning: ConfirmationDialogComponent;
    @ViewChild('deferralWarning') deferralWarning: ConfirmationDialogComponent;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly messageService: MessageService,
        private readonly authService: IAuthService,
        private readonly learnerService: LearnerService,
        public utilService: UtilService,
        private readonly userService: UserService,
        private readonly filterService: FilterService
    ) {}

    getMenuItems(training: LearnerStatus, curriculum: LearnerStatus): any[] {
        let items = [];
        if (!this.isManager && training.completionStatus !== 'Item Waived') {
            if (
                training.completionStatus !== 'Item Completed' &&
                training.completionStatus !== 'Curriculum Completed'
            ) {
                items.push({
                    label:
                        training.trainingType === 'Curriculum'
                            ? 'Display Curriculum in myLearning'
                            : 'Launch Training',
                    url: training.linkUrl,
                    target: '_blank',
                });
            } else {
                items.push({
                    label:
                        training.trainingType === 'Curriculum'
                            ? 'Display Curriculum in myLearning'
                            : 'Review Content',
                    command: () => this.reviewContent(training),
                });
            }
        }

        if (this.displayWaiverIcon(training)) {
            items.push({
                label: this.resolveWaiverTooltip(training, this.isManager),
                disabled: this.waiverButtonDisabled(training),
                command: () => this.waiver(training, curriculum),
            });
        }

        if (this.displayDeferralIcon(training)) {
            items.push({
                label: this.resolveDeferralTooltip(training, this.isManager),
                disabled: this.deferralButtonDisabled(training),
                command: () => this.deferral(training, null),
            });
        }

        return items;
    }

    displayMenu(training: LearnerStatus): boolean {
        return this.getMenuItems(training, null).some((t) => t);
    }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe((params) => {
            this.showSplashScreen = params['showSplashScreen'];
        });

        this.subData = this.route.data.subscribe((data) => {
            this.isManager = data.manager === true;
            this.isManagerProxy = data.managerProxy === true;

            if (this.isManager) {
                this.subUser = this.authService.directsMud.subscribe(
                    (result) => {
                        this.user = result;
                    }
                );
                this.subManager = this.authService.userValue.subscribe(
                    (result) => {
                        this.manager = result;
                    }
                );
            } else {
                this.subUser = this.authService.userValue.subscribe(
                    (result) => {
                        this.user = result;
                        if (this.showSplashScreen === 'true') {
                            this.learnerService
                                .checkSplashScreenSettings()
                                .subscribe((showSplashScreen) => {
                                    if (showSplashScreen === false) {
                                        this.openModal();
                                    }
                                });
                        }
                    }
                );
            }
        });
        if (this.user?.mudId) {
            this.getLearnerStatus(this.user.mudId);
        }
        this.filterService.register(
            'customFilter',
            this.utilService.seearchInsideCurriculaFilter
        );
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.subData.unsubscribe();
        this.subUser.unsubscribe();
        if (this.subManager) {
            this.subManager.unsubscribe();
        }
    }

    private getLearnerStatus(mudId: string): void {
        this.learnerService.getLearnerStatus(mudId).subscribe({
            next: (result) => {
                this.listOfStatuses = result
                    .filter((x) => !x.parentId)
                    .map((item) => item.completionStatus)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.listOfAssignedBy = result
                    .filter((x) => !x.parentId)
                    .map((item) => item.displayAssignedBy)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.trainings = result.filter((x) => !x.parentId);

                //load curricula items
                const curriculaTrainings = this.trainings.filter(
                    (v) => v.trainingType === 'Curriculum'
                );
                for (let i = 0; i < curriculaTrainings.length; i++) {
                    const cItems = result.filter(
                        (v) =>
                            v.parentId === curriculaTrainings[i].myLearningCode
                    );
                    if (cItems && cItems.length > 0) {
                        curriculaTrainings[i].items = cItems;
                    }
                }

                //automatically reassign trainings assigned by manager and role trainings
                if (!this.isManager) {
                    this.reassignTrainings(
                        result.filter(
                            (s) =>
                                !s.parentId &&
                                s.completionStatus ===
                                    'Not Assigned in myLearning'
                        )
                    );
                }

                //return from leave
                if (!this.isManager && this.user.onLongTermLeave) {
                    this.userService
                        .setUsersLongTermLeave(this.user.mudId, false)
                        .subscribe({
                            next: () => {
                                this.user.onLongTermLeave = false;
                                console.log(
                                    'User successfully returned from leave.'
                                );
                            },
                            error: () =>
                                this.messageService.add({
                                    text: `There was an error when removing 'on leave' status.`,
                                    timeout: 30000,
                                    style: 'is-danger',
                                }),
                        });
                }
            },
            error: () => {
                this.messageService.add({
                    text: 'There was an error when retrieving user data.',
                    timeout: 30000,
                    style: 'is-danger',
                });
            },
        });
    }

    formatDate(date) {
        let month = date.getMonth() + 1;
        let day = date.getDate();

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        return date.getFullYear() + '-' + month + '-' + day;
    }

    onAssignedByChange(event) {
        this.table.filter(event.value, 'displayAssignedBy', 'in');
    }

    toggleAdvSearch(event: boolean) {
        this.showAdvSearch = event;
    }

    toggleAllRows() {
        const thisRef = this;
        if (!this.areRowsExpanded) {
            this.trainings.forEach(function (training) {
                thisRef.expandedRows[training.myLearningCode] = true;
            });
            this.areRowsExpanded = true;
        } else {
            this.expandedRows = {};
            this.areRowsExpanded = false;
        }
    }

    openModal() {
        this.splashScreen.openDialog();
        this.splashScreen.splashScreenResult.subscribe((result) => {
            this.learnerService
                .splashScreenSettings(result.dontShowAgain)
                .subscribe();
            if (result.destination === '/learner/manage') {
                this.router.navigate(['/learner/manage']);
            }
        });
    }

    assignTraining(training: LearnerStatus) {
        this.reassignTrainings([training]);
    }

    reassignTrainings(unassignedTrainings: LearnerStatus[]) {
        if (unassignedTrainings.length === 0) {
            return;
        }

        const combinedSelf = new CombinedAssignment();
        const combinedManager = new CombinedAssignment();
        const combinedDelegatedManager = new CombinedAssignment();
        const combinedAdmin = new CombinedAssignment();

        unassignedTrainings.forEach((training) => {
            switch (training.selectedByRelation) {
                case 'User':
                    this.getCombinedAssignement(combinedSelf, training);
                    break;
                case 'Manager':
                    this.getCombinedAssignement(combinedManager, training);
                    break;
                case 'DelegatedManager':
                    this.getCombinedAssignement(
                        combinedDelegatedManager,
                        training
                    );
                    break;
                case 'Admin':
                    this.getCombinedAssignement(combinedAdmin, training);
                    break;
                default:
                    throw new Error('Uknown assignment relation');
            }
        });

        const observables: { [key: string]: any } = {};
        const observableKeys: string[] = [];

        if (
            (combinedSelf.itemAssignments &&
                combinedSelf.itemAssignments.length > 0) ||
            (combinedSelf.curriculumAssignments &&
                combinedSelf.curriculumAssignments.length > 0)
        ) {
            observables['MyGadgetSelfAssign'] =
                this.learnerService.assignTrainings(
                    combinedSelf,
                    'MyGadgetSelfAssign'
                );
            observableKeys.push('MyGadgetSelfAssign');
        }

        if (
            (combinedManager.itemAssignments &&
                combinedManager.itemAssignments.length > 0) ||
            (combinedManager.curriculumAssignments &&
                combinedManager.curriculumAssignments.length > 0)
        ) {
            observables['MyGadgetManager'] =
                this.learnerService.assignTrainings(
                    combinedManager,
                    'MyGadgetManager'
                );
            observableKeys.push('MyGadgetManager');
        }

        if (
            (combinedDelegatedManager.itemAssignments &&
                combinedDelegatedManager.itemAssignments.length > 0) ||
            (combinedDelegatedManager.curriculumAssignments &&
                combinedDelegatedManager.curriculumAssignments.length > 0)
        ) {
            observables['MyGadgetManagerDelegate'] =
                this.learnerService.assignTrainings(
                    combinedDelegatedManager,
                    'MyGadgetManagerDelegate'
                );
            observableKeys.push('MyGadgetManagerDelegate');
        }

        if (
            (combinedAdmin.itemAssignments &&
                combinedAdmin.itemAssignments.length > 0) ||
            (combinedAdmin.curriculumAssignments &&
                combinedAdmin.curriculumAssignments.length > 0)
        ) {
            observables['MyGadgetAdmin'] = this.learnerService.assignTrainings(
                combinedAdmin,
                'MyGadgetAdmin'
            );
            observableKeys.push('MyGadgetAdmin');
        }

        if (observableKeys.length > 0) {
            forkJoin(observables).subscribe({
                next: () =>
                    this.refreshData(
                        `${unassignedTrainings.length} trainings have been successfully reassigned.`
                    ),
                error: () => {
                    this.refreshData();
                    this.messageService.add({
                        text: 'There was an error when reassigning trainings.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                },
            });
        }
    }

    refreshData(message: string = null) {
        this.learnerService
            .getLearnerStatus(this.user.mudId)
            .subscribe((result) => {
                this.listOfStatuses = result
                    .filter((x) => !x.parentId)
                    .map((item) => item.completionStatus)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.listOfAssignedBy = result
                    .filter((x) => !x.parentId)
                    .map((item) => item.displayAssignedBy)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.trainings = result.filter((x) => !x.parentId);

                //load curricula items
                const curriculaTrainings = this.trainings.filter(
                    (v) => v.trainingType === 'Curriculum'
                );
                for (let i = 0; i < curriculaTrainings.length; i++) {
                    const cItems = result.filter(
                        (v) =>
                            v.parentId === curriculaTrainings[i].myLearningCode
                    );
                    if (cItems && cItems.length > 0) {
                        curriculaTrainings[i].items = cItems;
                    }
                }

                if (message) {
                    this.messageService.add({
                        text: message,
                        timeout: 30000,
                        style: 'is-success',
                    });
                }
            });
    }

    private getCombinedAssignement(
        combined: CombinedAssignment,
        training: LearnerStatus
    ) {
        if (training.myLearningTypeId) {
            const itemRequest = new ItemAssignmentRequest();
            itemRequest.userId = this.user.mudId;
            itemRequest.itemId = training.myLearningCode;
            itemRequest.itemTypeId = training.myLearningTypeId;
            itemRequest.revisionDate = training.myLearningRevisionDate;
            itemRequest.assignmentDate = new Date().toISOString();
            itemRequest.assignmentType = 'REQUIRED';
            itemRequest.assigner = training.selectedBy;

            if (combined.itemAssignments === undefined)
                combined.itemAssignments = [];
            combined.itemAssignments.push(itemRequest);
        } else {
            const curricullumRequest = new CurriculumAssignmentRequest();
            curricullumRequest.userId = this.user.mudId;
            curricullumRequest.curriculumId = training.myLearningCode;
            curricullumRequest.assignmentDate = new Date().toISOString();
            curricullumRequest.assigner = training.selectedBy;

            if (combined.curriculumAssignments === undefined)
                combined.curriculumAssignments = [];
            combined.curriculumAssignments.push(curricullumRequest);
        }
    }

    customSort(event: SortEvent) {
        this.utilService.sortArray(event.data, event.field, event.order);
    }

    reviewContent(training: LearnerStatus) {
        this.reviewContentUrl = training.linkUrl;
        this.reviewContentConfirmationDialog.openDialog();
    }

    onReviewContentConfirmed() {
        window.open(this.reviewContentUrl, '_blank').focus();
        this.reviewContentUrl = '';
    }

    waiver(training: LearnerStatus, curriculum: LearnerStatus): void {
        this.itemForWaiver = {
            ...training,
            requestStatus: training.waiverStatus,
            userId: this.user.mudId,
            learnerName: this.user.displayName,
            revisionDate: training.myLearningRevisionDate,
            waiveDetails: training.waiveDetails,
            itemTitle: training.title,
            curriculumCode: curriculum ? curriculum.myLearningCode : '',
            curriculumTitle: curriculum ? curriculum.title : '',
        };
        if (curriculum) {
            this.itemForWaiver.trainingAssignmentId =
                curriculum.trainingAssignmentId;
        }

        if (!training.waiverStatus || training.waiverStatus === 'Rejected') {
            if (this.isTrainingMultiplied(training)) {
                this.waiverWarning.text = this.isManager
                    ? 'Waiver Note: this training is assigned to your direct report multiple times. If the waiver is approved, it will be processed for all instances.'
                    : 'Request Waiver Note: this training is assigned to you multiple times. If the waiver is approved, it will be processed for all instances.';
                this.waiverWarning.showDialog = true;
                return;
            }
        }

        this.showWaiverConfirmation = true;
    }

    onWaiverConfirmed() {
        this.waiverWarning.showDialog = false;
        this.showWaiverConfirmation = true;
    }

    deferral(training: LearnerStatus, curriculum: LearnerStatus): void {
        let matchingTraining = this.getMatchingTraining(training);

        this.itemForDeferral = {
            ...training,
            requestStatus: training.deferralStatus,
            userId: this.user.mudId,
            learnerName: this.user.displayName,
            revisionDate: training.myLearningRevisionDate,
            itemTitle: training.title,
            curricula: matchingTraining
                .filter((t) => t.trainingType === 'Curriculum')
                .map((t) => new Curriculum(t.myLearningCode, t.title)),
            complianceSetting: matchingTraining.some(
                (t) => t.complianceSetting === ComplianceSetting.Role_Training
            )
                ? ComplianceSetting.Role_Training
                : ComplianceSetting.Task_Based,
            trainingAssignedBy: this.resolveTrainingAssignment(
                curriculum ?? training
            ),
            deferSingleItem: matchingTraining.some(
                (t) => t.trainingType === 'Item'
            ),
        };
        if (curriculum) {
            this.itemForDeferral.trainingAssignmentId =
                curriculum.trainingAssignmentId;
        }

        if (
            !training.deferralStatus ||
            training.deferralStatus === 'Rejected' ||
            training.deferralStatus === 'Error' ||
            training.deferralStatus === 'Completed'
        ) {
            if (this.isTrainingMultiplied(training)) {
                this.deferralWarning.text = this.isManager
                    ? 'Deferral Note: this training is assigned to your direct report multiple times. If the deferral is approved, it will be processed for all instances.'
                    : 'Request Deferral Note: this training is assigned to you multiple times. If the deferral is approved, it will be processed for all instances.';
                this.deferralWarning.showDialog = true;
                return;
            }
        }
        this.showDeferralConfirmation = true;
    }

    onDeferralConfirmed() {
        this.deferralWarning.showDialog = false;
        this.showDeferralConfirmation = true;
    }

    displayWaiverIcon(training: LearnerStatus): boolean {
        return (
            training.trainingType === 'Item' &&
            (training.completionStatus === 'Item Not Completed' ||
                training.completionStatus === 'Past Due') &&
            ['GSOP', 'LSP', 'OJT', 'COURSE', 'RU', 'EXAM', 'LSOP'].includes(
                training.myLearningTypeId
            )
        );
    }

    displayDeferralIcon(training: LearnerStatus): boolean {
        return (
            training.dueDate &&
            training.trainingType === 'Item' &&
            (training.completionStatus === 'Item Not Completed' ||
                training.completionStatus === 'Past Due') &&
            ['GSOP', 'LSP', 'OJT', 'COURSE', 'RU', 'EXAM', 'LSOP'].includes(
                training.myLearningTypeId
            )
        );
    }

    resolveWaiverTooltip(training: LearnerStatus, isManager: boolean): string {
        let t = this.getMatchingTraining(training);
        if (t.some((t) => !t.waiversEnabled))
            return 'Waivers Disabled by Admin';
        if (!training.waiverStatus || training.waiverStatus === 'Rejected') {
            if (isManager) return 'Waiver';
            return 'Request Waiver';
        }
        return 'Waiver Request Pending';
    }

    resolveDeferralTooltip(
        training: LearnerStatus,
        isManager: boolean
    ): string {
        let t = this.getMatchingTraining(training);
        if (t.some((t) => !t.deferralsEnabled))
            return 'Deferrals Disabled by Admin';
        if (
            !training.deferralStatus ||
            training.deferralStatus === 'Rejected' ||
            training.deferralStatus === 'Error' ||
            training.deferralStatus === 'Completed'
        ) {
            if (training.deferralCount > 3)
                return 'Training already deferred 4 times';
            if (isManager) return 'Deferral';
            return 'Request Deferral';
        }
        if (training.deferralStatus === 'Approved') {
            return 'Deferral Processing';
        }
        return 'Deferral Request Pending';
    }

    deferralButtonDisabled(training: LearnerStatus) {
        let t = this.getMatchingTraining(training);
        return (
            training.deferralStatus === 'Approved' ||
            (!this.isManager && training.deferralStatus === 'Requested') ||
            ((training.deferralStatus === 'Completed' ||
                training.deferralStatus === 'Error' ||
                training.deferralStatus === 'Rejected') &&
                training.deferralCount > 3) ||
            t.some((t) => !t.deferralsEnabled)
        );
    }

    waiverButtonDisabled(training: LearnerStatus) {
        let t = this.getMatchingTraining(training);
        return (
            t.some((t) => !t.waiversEnabled) ||
            (!this.isManager && training.waiverStatus === 'Requested')
        );
    }

    onWaiverDialogSuccess() {
        this.refreshData();
        this.expandedRows = {};
        this.areRowsExpanded = false;
    }

    manageStaffOnLeave() {
        const thisRef = this;
        function setOnLongTermLeave(onLeave: boolean) {
            thisRef.user.onLongTermLeave = onLeave;
            thisRef.authService.direct = thisRef.user as DirectReport;

            const directReports = thisRef.authService.reports;
            const index = directReports.findIndex(
                (x) => x.userId == thisRef.user.mudId
            );
            directReports[index].onLongTermLeave = onLeave;
            thisRef.authService.reports = directReports;
        }

        if (this.user.onLongTermLeave) {
            this.userService
                .setUsersLongTermLeave(
                    this.user.mudId,
                    false,
                    undefined,
                    this.isManagerProxy
                )
                .subscribe({
                    next: () => {
                        setOnLongTermLeave(false);
                        this.reassignTrainings(
                            this.trainings.filter(
                                (t) =>
                                    t.completionStatus ===
                                    'Not Assigned in myLearning'
                            )
                        );
                    },
                    error: (e) => {
                        this.messageService.add({
                            text: e.error,
                            timeout: 600000,
                            style: 'is-danger',
                        });
                    },
                });
        } else {
            const trainingsToUnassign = this.trainings
                .filter(
                    (t) =>
                        t.completionStatus !== 'Item Waived' &&
                        t.completionStatus !== 'Item Completed' &&
                        t.completionStatus !== 'Not Assigned in myLearning' &&
                        (!t.myLearningAssigner ||
                            t.myLearningAssigner === 'myG_SelfAssign' ||
                            t.myLearningAssigner === 'myG_ManagerAssign' ||
                            t.myLearningAssigner ===
                                'myG_DelegateManagerAssign' ||
                            t.myLearningAssigner.startsWith('mygadget_'))
                )
                .map((x) => {
                    return <TrainingBase>{
                        myLearningCode: x.myLearningCode,
                        myLearningTypeId: x.myLearningTypeId,
                        myLearningRevisionDate: x.myLearningRevisionDate,
                    };
                });
            this.userService
                .setUsersLongTermLeave(
                    this.user.mudId,
                    true,
                    trainingsToUnassign,
                    this.isManagerProxy
                )
                .subscribe({
                    next: () => {
                        setOnLongTermLeave(true);
                        this.refreshData(
                            'User has succesfully been marked as away/on long term leave and all their trainings have been unassigned in myLearning.'
                        );
                    },
                    error: (e) => {
                        this.messageService.add({
                            text: e.error,
                            timeout: 600000,
                            style: 'is-danger',
                        });
                    },
                });
        }
    }

    excelExport = () => {
        this.utilService.exportToExcel(
            this.getExcelOutputData(this.trainings),
            'Learning_plan',
            'Learning_plan_export'
        );
    };

    escapeString(str: string): string {
        return _.escape(str);
    }

    private isTrainingMultiplied(training: LearnerStatus): boolean {
        if (
            training.trainingType === 'Curriculum' ||
            (training.completionStatus !== 'Item Not Completed' &&
                training.completionStatus !== 'Past Due')
        )
            return false;

        return this.trainings.some(
            (t) =>
                (t !== training &&
                    t.trainingType === 'Item' &&
                    this.itemsEquals(training, t)) ||
                (t !== training &&
                    t.trainingType === 'Curriculum' &&
                    t.items &&
                    t.items.some(
                        (nested) =>
                            nested !== training &&
                            this.itemsEquals(training, nested)
                    ))
        );
    }

    private getMatchingTraining(training: LearnerStatus): LearnerStatus[] {
        return this.trainings.filter(
            (t) =>
                (t.trainingType === 'Item' && this.itemsEquals(training, t)) ||
                (t.trainingType === 'Curriculum' &&
                    t.items &&
                    t.items.some((nested) =>
                        this.itemsEquals(training, nested)
                    ))
        );
    }

    private itemsEquals(t1: LearnerStatus, t2: LearnerStatus): boolean {
        return (
            t1.myLearningCode === t2.myLearningCode &&
            t1.myLearningTypeId === t2.myLearningTypeId &&
            t1.myLearningRevisionDate === t2.myLearningRevisionDate
        );
    }

    private resolveTrainingAssignment(
        training: LearnerStatus
    ): 'Learner' | 'Manager' {
        return training.assignedBy === this.user.mudId ? 'Learner' : 'Manager';
    }

    private getExcelOutputData(trainings: LearnerStatus[]): excelOutput {
        const output: excelOutput = [];
        trainings.forEach((training) => {
            if (training.trainingType === 'Item') {
                output.push({
                    Type: 'Item',
                    'Curriculum Code': undefined,
                    'Item Code': training.myLearningCode,
                    Title: training.title,
                    'Completion Status': training.completionStatus,
                    'Due Date': training.dueDate,
                    'Assigned By': training.assignedBy,
                });
            } else {
                const curriculum = training;
                output.push({
                    Type: 'Curriculum',
                    'Curriculum Code': curriculum.myLearningCode,
                    'Item Code': undefined,
                    Title: curriculum.title,
                    'Completion Status': curriculum.completionStatus,
                    'Due Date': curriculum.dueDate,
                    'Assigned By': curriculum.assignedBy,
                });
                curriculum.items?.forEach((item) => {
                    output.push({
                        Type: 'Item',
                        'Curriculum Code': curriculum.myLearningCode,
                        'Item Code': item.myLearningCode,
                        Title: item.title,
                        'Completion Status': item.completionStatus,
                        'Due Date': item.dueDate,
                        'Assigned By': curriculum.assignedBy,
                    });
                });
            }
        });

        return output;
    }
}

type excelOutput = Array<{
    Type: string;
    'Curriculum Code': string;
    'Item Code': string;
    Title: string;
    'Completion Status': string;
    'Due Date': Date;
    'Assigned By': string;
}>;
