import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { IAuthService } from 'src/app/auth/iAuthService';
import { ManagerProxyService } from 'src/app/manager-proxy.service';
import { IAdminReport } from 'src/app/models/adminReport';
import { DirectReport } from 'src/app/models/directReport';
import { MessageService } from 'src/app/services/message.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
    selector: 'app-learner-status-report',
    templateUrl: './learner-status-report.component.html',
    styleUrls: ['./learner-status-report.component.css'],
})
export class LearnerStatusReportComponent
    implements OnInit, IAdminReport, OnDestroy
{
    constructor(
        private authService: IAuthService,
        private reportService: ReportsService,
        private messageService: MessageService,
        private managerProxyService: ManagerProxyService
    ) {}

    listOfDirects: DirectReport[] = [];
    selectedDirects: DirectReport[];

    private subReports: Subscription;

    reset() {
        this.selectedDirects = null;
    }

    generateReport(): Observable<void> {
        if (!this.selectedDirects) {
            this.messageService.add({
                text: 'You need to select at least one direct to generate the report.',
                timeout: 200000,
                style: 'is-danger',
            });
            return;
        }
        return this.reportService.addLearnerStatusReport(
            this.selectedDirects,
            this.managerProxyService.proxiedManager ?? undefined
        );
    }

    ngOnInit(): void {
        this.subReports = this.authService.reportList.subscribe(
            (result) => (this.listOfDirects = result)
        );
    }

    ngOnDestroy(): void {
        if (this.subReports && !this.subReports.closed) {
            this.subReports.unsubscribe();
        }
    }
}
