import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAuthService } from '../auth/iAuthService';
import { DirectReport } from '../models/directReport';
import { UserFullData } from '../models/userFullData';

@Component({
    selector: 'app-learner',
    templateUrl: './learner.component.html',
    styleUrls: ['./learner.component.css', '../shared-styles/tabs.css'],
})
export class LearnerComponent implements OnInit, OnDestroy {
    user: UserFullData | DirectReport;

    private subData: Subscription;
    private subUser: Subscription;

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        public authService: IAuthService
    ) {}

    ngOnInit(): void {
        this.subData = this.route.data.subscribe((data) => {
            if (!data) {
                this.router.navigate(['/login']);
                return;
            }

            if (data.manager) {
                this.subUser = this.authService.directsMud.subscribe(
                    (result) => {
                        if (!result) {
                            this.router.navigate(['/login']);
                            return;
                        }
                        this.user = result;
                    }
                );
            } else {
                this.subUser = this.authService.userValue.subscribe(
                    (result) => {
                        if (!result) {
                            this.router.navigate(['/login']);
                            return;
                        }
                        this.user = result;
                    }
                );
            }
        });
    }

    ngOnDestroy() {
        if (this.subData) {
            this.subData.unsubscribe();
        }
        if (this.subUser) {
            this.subUser.unsubscribe();
        }
    }
}
