import { Component, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

import { ManagerSearchItem } from '../models/managerSearchItem';

@Component({
    selector: 'manager-search',
    templateUrl: './manager-search.component.html',
    styleUrls: ['./manager-search.component.css'],
})
export class ManagerSearchComponent {
    showDialog: boolean = false;
    searchResults: ManagerSearchItem[];

    expandedRows: {} = {};
    showAdvSearch: boolean = false;
    advSearchTrainingType: any;
    advSearchCompletionStatus: any;
    globalSearchInput: string;
    userNameInput: string;
    roleInput: string;
    trainingInput: string;
    titleInput: string;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    openDialog(results: ManagerSearchItem[]) {
        this.showDialog = true;
        this.searchResults = results;
        this.disableBodyScroll(true);
        this.getDataToDisplay(results);
    }

    closeDialog() {
        this.showDialog = false;
        this.disableBodyScroll(false);
    }

    async disableBodyScroll(disable: boolean) {
        await new Promise((f) => setTimeout(f, 500)); //otherwise loadingService fires after openDialog has completed and overrides the scroll toggle
        this.document.body.classList.toggle('loading-noscroll', disable);
    }

    toggleAdvSearch(event: boolean) {
        this.showAdvSearch = event;
    }

    private getDataToDisplay(results: ManagerSearchItem[]) {
        this.advSearchTrainingType = results
            .map((item) => item.trainingType)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((i) => {
                return { label: i, value: i };
            })
            .sort((a, b) => (a.label < b.label ? -1 : 1));

        this.advSearchCompletionStatus = results
            .map((item) => item.completionStatus)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((i) => {
                return { label: i, value: i };
            })
            .sort((a, b) => (a.label < b.label ? -1 : 1));
    }
}
