import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingService {

    active: number = 0;

    constructor(@Inject(DOCUMENT) private document: Document) { }

    show() {
        if (this.active === 0) {
            this.document.body.classList.toggle("loading-noscroll", true);
        }
        this.active++;
    }

    hide() {
        this.active--;
        if (this.active === 0) {
            this.document.body.classList.toggle("loading-noscroll", false);
        }
    }
}