import { Component, OnInit, ViewChild } from '@angular/core';
import { ManagerProxyService } from '../manager-proxy.service';
import { Report } from '../models/report';
import { LearnerStatusReportComponent } from '../reports/learner-status-report/learner-status-report.component';
import { RolesReportComponent } from '../shared/roles-report/roles-report.component';
import { MessageService } from '../services/message.service';
import { ReportsService } from '../services/reports.service';
import * as saveAs from 'file-saver';
import { IAdminReport } from '../models/adminReport';

@Component({
    selector: 'app-manager-reporting',
    templateUrl: './manager-reporting.component.html',
    styleUrls: ['./manager-reporting.component.css'],
})
export class ManagerReportingComponent implements OnInit {
    constructor(
        private reportService: ReportsService,
        private messageService: MessageService,
        private managerProxyService: ManagerProxyService
    ) {}
    ngOnInit(): void {
        this.refreshReports();
    }

    @ViewChild('rolesReport') rolesReport: RolesReportComponent;
    @ViewChild('learnerStatusReport')
    learnerStatusReport: LearnerStatusReportComponent;

    generatedReports: Report[];
    listOfReports: string[] = ['Learner Status Report', 'Roles Report'];
    selectedReport: string;

    public reset() {
        this.getSelectedReport().reset();
    }

    public generateButton() {
        this.getSelectedReport()
            .generateReport()
            .subscribe(() => this.refreshReports());
    }
    public delete(report: Report) {
        this.reportService
            .deleteReport(
                report,
                this.managerProxyService.proxiedManager ?? undefined
            )
            .subscribe(() => this.refreshReports());
    }
    public download(report: Report) {
        this.reportService
            .downloadReport(
                report.id,
                this.managerProxyService.proxiedManager ?? undefined
            )
            .subscribe({
                next: (blob) =>
                    saveAs(
                        blob,
                        `${report.reportName}_${report.generatedOn}.xls`
                    ),
                error: () =>
                    this.messageService.add({
                        text: 'There was a problem while downloading the report.',
                        timeout: 600000,
                        style: 'is-danger',
                    }),
            });
    }

    getSelectedReport(): IAdminReport {
        switch (this.selectedReport) {
            case 'Roles Report':
                return this.rolesReport;
            case 'Learner Status Report':
                return this.learnerStatusReport;
        }
    }

    private refreshReports() {
        this.reportService
            .getAllReports(this.managerProxyService.proxiedManager ?? undefined)
            .subscribe((r) => (this.generatedReports = r));
    }
}
