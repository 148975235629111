import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EditablePathwayDetailsComponent } from '../editable-pathway-details/editable-pathway-details.component';
import { ManagerProxyService } from '../../manager-proxy.service';
import { Pathway } from '../../models/pathway';
import { UserBase } from '../../models/userBase';
import { MessageService } from '../../services/message.service';
import { PathwayService } from '../../services/pathway.service';

@Component({
    selector: 'admin-edit-pathway',
    templateUrl: './edit-pathway.component.html',
    styleUrls: ['./edit-pathway.component.css'],
})
export class EditPathwayComponent implements OnInit, OnDestroy {
    constructor(
        private readonly pathwayService: PathwayService,
        private readonly managerProxyService: ManagerProxyService,
        private route: ActivatedRoute,
        private messageService: MessageService
    ) {}

    @ViewChild('pathwayDetails')
    pathwayDetails: EditablePathwayDetailsComponent;

    @Input() listOfOwners: UserBase[];
    @Input() isAdmin: boolean;

    @Output() pathwayEdited = new EventEmitter<Pathway>();

    public pathway: Pathway;
    public showModal: boolean = false;
    public isProxyManager: boolean;

    private subData: Subscription;

    ngOnInit(): void {
        this.subData = this.route.data.subscribe((data) => {
            this.isProxyManager = data.managerProxy;
        });
    }

    ngOnDestroy(): void {
        this.subData.unsubscribe();
    }

    public open(pathway: Pathway) {
        this.pathway = JSON.parse(JSON.stringify(pathway));
        this.showModal = true;
    }

    public close = () => {
        this.pathway = undefined;
        this.showModal = false;
    };

    public save = () => {
        this.pathwayDetails.validate();

        if (Pathway.isValid(this.pathway)) {
            this.pathwayService
                .editPathway(
                    this.pathway,
                    this.isProxyManager
                        ? this.managerProxyService.proxiedManager
                        : undefined,
                    this.isAdmin
                )
                .subscribe({
                    next: () => {
                        this.pathwayEdited.emit(this.pathway);
                        this.close();
                    },
                    error: (error) => {
                        this.messageService.add({
                            text: error.error,
                            style: 'is-danger',
                            timeout: 10000,
                        });
                    },
                });
        }
    };
}
