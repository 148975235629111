import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatNativeDateModule,
    MAT_DATE_LOCALE,
    MAT_DATE_FORMATS,
} from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DatePipe } from '@angular/common';
import { UrlSelectorInterceptor } from './url-selector.interceptor';
import { AuthInterceptor } from './auth/auth.interceptor';
import { SsoAuthInterceptor } from './auth/sso-auth.interceptor';
import { ErrorInterceptor } from './error.interceptor';
import { MY_DATE_FORMATS } from './consts';
import { ChipsModule } from 'primeng/chips';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { SsoAuthStorage } from './auth/sso-auth-storage';
import { TreeSelectModule } from 'primeng/treeselect';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageComponent } from './message/message.component';
import { LoadingComponent } from './loading/loading.component';
import { LoginComponent } from './auth/login/login.component';
import { LearnerStatusComponent } from './learner-status/learner-status.component';
import { LearnerRolesComponent } from './learner-roles/learner-roles.component';
import { LearnerManageComponent } from './learner-manage/learner-manage.component';
import { LearnerTrainingComponent } from './learner-training/learner-training.component';
import { LearnerMylearningPlanComponent } from './learner-mylearning-plan/learner-mylearning-plan.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { ManagerComponent } from './manager/manager.component';
import { LearnerComponent } from './learner/learner.component';
import { WaiverConfirmationComponent } from './waiver-confirmation/waiver-confirmation.component';
import { WaiverRequestsComponent } from './waiver-requests/waiver-requests.component';
import { SsoLoginComponent } from './auth/sso-login/sso-login.component';
import { IAuthService } from './auth/iAuthService';
import { SsoAuthService } from './auth/sso-auth.service';
import { AuthService } from './auth/auth.service';
import { DeferralConfirmationComponent } from './deferral-confirmation/deferral-confirmation.component';
import { ManagerSearchComponent } from './manager-search/manager-search.component';
import { CopyRoleAssignemtComponent } from './copy-role-assignemt/copy-role-assignemt.component';
import { AdminComponent } from './admin/admin.component';
import { SsoLogoutComponent } from './auth/sso-logout/sso-logout.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { DeferralRequestsComponent } from './deferral-requests/deferral-requests.component';
import { JoyrideModule } from 'ngx-joyride';
import { HelpButtonComponent } from './help-button/help-button.component';
import { ManagerProxyComponent } from './manager-proxy/manager-proxy.component';
import { ManagerReportingComponent } from './manager-reporting/manager-reporting.component';
import { LearnerStatusReportComponent } from './reports/learner-status-report/learner-status-report.component';
import { TourContentComponent } from './tour-content/tour-content.component';
import { RoleSelectionComponent } from './role-selection/role-selection.component';
import { ErrorComponent } from './error/error.component';
import { SharedModule } from './shared/shared.module';
import { RefreshAuthInterceptor } from './refreshAuth.interceptor';
import { BannerComponent } from './banner/banner.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { AdminSharedModule } from './admin-shared/admin-shared.module';

@NgModule({ declarations: [
        AppComponent,
        MessageComponent,
        LoadingComponent,
        LoginComponent,
        LearnerStatusComponent,
        LearnerRolesComponent,
        LearnerManageComponent,
        LearnerTrainingComponent,
        LearnerMylearningPlanComponent,
        SplashScreenComponent,
        ManagerComponent,
        LearnerComponent,
        WaiverConfirmationComponent,
        WaiverRequestsComponent,
        SsoLoginComponent,
        DeferralConfirmationComponent,
        ManagerSearchComponent,
        CopyRoleAssignemtComponent,
        ManagerSearchComponent,
        AdminComponent,
        SsoLogoutComponent,
        SuperAdminComponent,
        DeferralRequestsComponent,
        HelpButtonComponent,
        HelpButtonComponent,
        ManagerProxyComponent,
        ManagerReportingComponent,
        LearnerStatusReportComponent,
        TourContentComponent,
        RoleSelectionComponent,
        ErrorComponent,

        BannerComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        ChipsModule,
        TreeSelectModule,
        MenuModule,
        OAuthModule.forRoot(),
        JoyrideModule.forRoot(),
        TabViewModule,
        SharedModule,
        AdminSharedModule,
    ],
    providers: [
        { provide: OAuthStorage, useClass: SsoAuthStorage },
        {
            provide: IAuthService,
            useClass: environment.SSO.useSSO ? SsoAuthService : AuthService,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UrlSelectorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: environment.SSO.useSSO
                ? SsoAuthInterceptor
                : AuthInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshAuthInterceptor,
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
        DatePipe,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync('noop'),
    ],
})
export class AppModule {}
