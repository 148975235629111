<ng-container>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Country</p>
            <p-dropdown [options]="locations.countries" appendTo="body" [(ngModel)]="location.country"
                (onChange)="countrySelected($event.value)" styleClass="p-column-filter" placeholder="Select Country"
                [showClear]="true" optionLabel="value" [filter]="true">
            </p-dropdown>
        </div>
    </div>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Site</p>
            <p-dropdown class="p-mb-4" [options]="listOfSitesFiltered" appendTo="body" [disabled]="ddSitesDisabled"
                [(ngModel)]="location.site" styleClass="p-column-filter" placeholder="Select Site" [showClear]="true"
                optionLabel="value" [filter]="true" (onChange)="selectionChanged.emit()">
            </p-dropdown>
        </div>
    </div>
</ng-container>