import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BusinessArea } from '../../models/businessArea';
import { BusinessUnit } from '../../models/businessUnit';
import { Group } from '../../models/group';
import { Hierarchies } from '../../models/hierarchies';
import { Hierarchy } from '../../models/hierarchy';
import { MetadataService } from '../../services/metadata.service';

@Component({
  selector: 'shared-hierarchies',
  templateUrl: './hierarchies.component.html',
  styleUrls: ['./hierarchies.component.css']
})
export class HierarchiesComponent implements OnInit {

  constructor(private metadataService: MetadataService) { }

  @Output() selectionChanged = new EventEmitter();

  public hierarchies: Hierarchies = { businessUnits: [], businessAreas: [], groups: [] };
  public hierarchy: Hierarchy = {} as Hierarchy;

  listOfBusinessAreasFiltered: BusinessArea[];
  listOfGroupsFiltered: Group[];
  ddBADisabled: boolean = true;
  ddGroupsDisabled: boolean = true;

  ngOnInit(): void {
    this.metadataService.getHierarchies(this.hierarchies).subscribe(r => this.hierarchies = r);
  }

  public clearSelections() {
    this.listOfBusinessAreasFiltered = null;
    this.listOfGroupsFiltered = null;
    this.ddBADisabled = true;
    this.ddGroupsDisabled = true;
    this.hierarchy.businessUnit = null;
    this.hierarchy.businessArea = null;
    this.hierarchy.group = null;
    this.selectionChanged.emit();
  }

  businessUnitSelected(bu: BusinessUnit) {
    if (!bu) {
      this.listOfBusinessAreasFiltered = null;
      this.ddBADisabled = true;
      this.ddGroupsDisabled = true;
      this.hierarchy.businessArea = null;
      this.hierarchy.group = null;
      this.selectionChanged.emit();
      return;
    }

    this.ddBADisabled = false;
    if (this.hierarchies.businessAreas) {
      this.listOfBusinessAreasFiltered = this.hierarchies.businessAreas.filter(x => x.businessUnitId === bu?.id);
      this.hierarchy.businessArea = null;
      this.hierarchy.group = null;
    }
    this.selectionChanged.emit();
  }

  businessAreaSelected(ba: BusinessArea) {
    if (!ba) {
      this.listOfGroupsFiltered = null;
      this.ddGroupsDisabled = true;
      this.hierarchy.group = null;
      this.selectionChanged.emit();
      return;
    }

    this.ddGroupsDisabled = false;
    if (this.hierarchies.groups) {
      this.listOfGroupsFiltered = this.hierarchies.groups.filter(x => x.businessAreaId === ba?.id);
    }
    this.selectionChanged.emit();
  }
}
