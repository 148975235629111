import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { CurriculumDetailsComponent } from '../../shared/curriculum-details/curriculum-details.component';
import { Pathway } from '../../models/pathway';
import { PathwayTraining } from '../../models/pathwayTraining';
import { StageGate } from '../../models/stageGate';
import { UserBase } from '../../models/userBase';

@Component({
  selector: 'admin-editable-pathway-details',
  templateUrl: './editable-pathway-details.component.html',
  styleUrls: ['./editable-pathway-details.component.css']
})
export class EditablePathwayDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.rebuildStageGate(0);
    this.rebuildStageGate(1);
    this.rebuildStageGate(2);
    this.rebuildStageGate(3);
    this.selectedOwner = this.pathway.owner;
    this.backupOwners = this.getListOfOwners();
  }

  public isValidated: boolean;
  public selectedOwner: UserBase;

  public stageGate1Flat: TreeNode<PathwayTraining>[];
  public stageGate1Tree: TreeNode<PathwayTraining>[];
  public stageGate2Flat: TreeNode<PathwayTraining>[];
  public stageGate2Tree: TreeNode<PathwayTraining>[];
  public stageGate3Flat: TreeNode<PathwayTraining>[];
  public stageGate3Tree: TreeNode<PathwayTraining>[];
  public stageGate4Flat: TreeNode<PathwayTraining>[];
  public stageGate4Tree: TreeNode<PathwayTraining>[];

  public backupOwners: UserBase[];

  @ViewChild('curriculumDetails') curriculumDetails: CurriculumDetailsComponent;

  @Input() public pathway: Pathway;
  @Input() public listOfOwners: UserBase[] = [];
  @Input() public isAdmin: boolean;

  public validateBackupOwners(): boolean {
    return !this.pathway.backupOwners?.map(ba => ba.mudId).includes(this.pathway.owner?.mudId);
  }

  public getListOfOwners(): UserBase[] {
    if (this.pathway?.backupOwners?.map(ba => ba.mudId).includes(this.pathway.owner?.mudId)) {
      return this.listOfOwners;
    }
    else {
      return this.listOfOwners?.filter(a => a.mudId !== this.pathway.owner?.mudId);
    }
  }

  public onTrainingMoved(event: { node: TreeNode<PathwayTraining>, source: number, dest: number }) {
    let trainingToMove = this.getChildren(event.node);
    trainingToMove.push(event.node.data)
    this.addTraining(event.dest, trainingToMove);

    this.removeTraining(event.source, trainingToMove);
  }

  public validate() {
    this.isValidated = true;
  }

  public getTriggeredBy(training: PathwayTraining, stage: number): PathwayTraining {
    return this.getStageGate(stage).training.find(t => t.roleTrainingId === training.trigger?.roleTrainingId);
  }

  public displayCurriculum(myLearningCode: string) {
    this.curriculumDetails.showModal(myLearningCode);
  }

  public onOwnerChange(event) {
    this.pathway.owner = event.value;
    this.backupOwners = this.getListOfOwners();
  }

  private addTraining(sgNumber: number, training: PathwayTraining[]) {
    const sg = this.getStageGate(sgNumber);
    for (let i = 0; i < training.length; i++) {
      sg.training.push(training[i]);
    }
    this.rebuildStageGate(sgNumber)
  }

  private removeTraining(sgNumber: number, training: PathwayTraining[]) {
    const sg = this.getStageGate(sgNumber);
    for (let i = 0; i < training.length; i++) {
      const index = sg.training.indexOf(training[i]);
      sg.training.splice(index, 1);
    }
    this.rebuildStageGate(sgNumber);
  }

  private buildTree(nodes: TreeNode<PathwayTraining>[]) {
    var map = {}, node: TreeNode, roots = [], i: number;

    for (i = 0; i < nodes.length; i += 1) {
      map[nodes[i].data.roleTrainingId] = i; // initialize the map
      nodes[i].children = []; // initialize the children
    }

    for (i = 0; i < nodes.length; i += 1) {
      node = nodes[i];
      node.expanded = true;
      if (node.data.trigger?.roleTrainingId) {
        nodes[map[node.data.trigger?.roleTrainingId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  public onTriggerTypeSelectionChanged(type: string, sgNumber: number) {
    if (type === 'StageGate') {
      this.rebuildStageGate(sgNumber);
    }
  }

  public onAdjustTree(sgNumber: number) {
    this.rebuildStageGate(sgNumber);
  }

  private getChildren(node: TreeNode<PathwayTraining>): PathwayTraining[] {
    let children = [];
    if (node.children) {
      for (let i = 0; i < node.children.length; i++) {
        let child = node.children[i];
        children.push(child.data);
        if (child.children && child.children.length > 0) {
          children.push(...this.getChildren(child));
        }
      }
    }
    return children;
  }

  private getStageGate(stage: number): StageGate {
    switch (stage) {
      case 0:
        return this.pathway.stageGate1;
      case 1:
        return this.pathway.stageGate2;
      case 2:
        return this.pathway.stageGate3;
      case 3:
        return this.pathway.stageGate4;
      default:
        throw new Error('Out of stages index');
    }
  }

  private rebuildStageGate(sgNumber: number) {
    switch (sgNumber) {
      case 0:
        return this.rebuildStageGate1();
      case 1:
        return this.rebuildStageGate2();
      case 2:
        return this.rebuildStageGate3();
      case 3:
        return this.rebuildStageGate4();
    }
  }

  private rebuildStageGate1() {
    this.pathway.stageGate1.training.forEach(t => {
      t.stageGate = 0;
      if (t.trigger) { t.trigger = this.getTriggeredBy(t, 0); }
    });

    this.stageGate1Flat = this.pathway.stageGate1.training.map(t => <TreeNode<PathwayTraining>>{ data: t });
    this.stageGate1Tree = this.buildTree(this.stageGate1Flat);
  }

  private rebuildStageGate2() {
    this.pathway.stageGate2.training.forEach(t => {
      t.stageGate = 1;
      if (t.trigger) { t.trigger = this.getTriggeredBy(t, 1); }
    });

    this.stageGate2Flat = this.pathway.stageGate2.training.map(t => <TreeNode<PathwayTraining>>{ data: t });
    this.stageGate2Tree = this.buildTree(this.stageGate2Flat);
  }

  private rebuildStageGate3() {
    this.pathway.stageGate3.training.forEach(t => {
      t.stageGate = 2;
      if (t.trigger) { t.trigger = this.getTriggeredBy(t, 2); }
    });
    this.stageGate3Flat = this.pathway.stageGate3.training.map(t => <TreeNode<PathwayTraining>>{ data: t });
    this.stageGate3Tree = this.buildTree(this.stageGate3Flat);
  }

  private rebuildStageGate4() {
    this.pathway.stageGate4.training.forEach(t => {
      t.stageGate = 3;
      if (t.trigger) { t.trigger = this.getTriggeredBy(t, 3); }
    });

    this.stageGate4Flat = this.pathway.stageGate4.training.map(t => <TreeNode<PathwayTraining>>{ data: t });
    this.stageGate4Tree = this.buildTree(this.stageGate4Flat);
  }

}
