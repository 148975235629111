import { Component, Input } from '@angular/core';

@Component({
    selector: 'shared-excel-export-button',
    templateUrl: './excel-export-button.component.html',
    styleUrls: ['./excel-export-button.component.css'],
})
export class ExcelExportButtonComponent {
    @Input() exportAction: () => void;
}
