<div class="modal is-active" *ngIf="display">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">Select Pathway</p>
            <div class="columns">
                <div class="column is-4">
                    <p style="font-size: 16px;"><strong>Pathway to apply</strong></p>
                    <p-dropdown styleClass="p-column-filter" [options]="role.pathways" [(ngModel)]="selectedPathway"
                        optionLabel="name" placeholder="Select a Pathway"></p-dropdown>
                </div>
            </div>
            <p-divider></p-divider>
            <shared-pathway-details #pathwayDetails *ngIf="selectedPathway" [pathway]="selectedPathway">
            </shared-pathway-details>
            <p-divider></p-divider>
            <div class="columns">
                <div class="column is-3 is-offset-9">
                    <button pButton type="button" label="CANCEL"
                        class="floatRight p-button-rounded p-button-outlined stdColor p-ml-3"
                        (click)="closeModal()"></button>
                    <button pButton type="button" label="SUBMIT" class="floatRight p-button-rounded stdColor"
                        (click)="submit()" [disabled]="!selectedPathway"></button>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeModal()"></button>
</div>