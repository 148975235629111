import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DeferralRequest } from '../models/deferralRequest';
import { LearnerService } from "../services/learner.service";
import { MessageService } from '../services/message.service';

@Component({
  selector: 'deferral-confirmation',
  templateUrl: './deferral-confirmation.component.html',
  styleUrls: ['./deferral-confirmation.component.css']
})
export class DeferralConfirmationComponent {

  step: 'learner' | 'manager';
  display = false;

  @Input() request: DeferralRequest;
  @Input() isManager: boolean;
  @Input() set showDialog(showDialog: boolean) {
    if (showDialog && this.isManager === undefined) { throw 'isManager property not set' }
    if (showDialog) { this.step = this.isManager ? 'manager' : 'learner' }
    this.display = showDialog;
  }

  @Output() showDialogChange = new EventEmitter<boolean>();
  @Output() requestStateChanged = new EventEmitter<DeferralRequest>();

  constructor(private learnerService: LearnerService, private messageService: MessageService) { }

  closeDialog() {
    this.showDialogChange.emit(false);
  }

  goBack() {
    this.step = 'manager'
  }

  approve() {
    this.step = 'manager';
  }

  sendRequest() {
    this.request.requestStatus = 'Requested';
    this.handleDeferral(this.request);
  }

  sendReject() {
    this.request.requestStatus = 'Rejected';
    this.handleDeferral(this.request);
  }

  sendGrantDeferral() {
    this.request.requestStatus = 'Approved';
    this.handleDeferral(this.request);
  }

  getMultipleCurriculaText(): string {
    return this.request.curricula ? `This training is part of the following assigned curricula:\n${this.request.curricula
      .sort((a, b) => a.code?.localeCompare(b.code))
      .map(c => `${c.code ?? 'Single Item'} ${c.title ? '-' : ''} ${c.title ?? ''}`)
      .join(",\n")}` : '';
  }

  private handleDeferral(request: DeferralRequest): void {
    this.learnerService.handleDeferralRequest(request).subscribe({
      next: _ => {
        this.requestStateChanged.emit(request);
        this.closeDialog();
      },
      error: _ => this.messageService.add({ text: 'There was an error when raising deferral request.', timeout: 30000, style: 'is-danger' })
    });
  }

}
