<div class="modal is-active" *ngIf="display">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">Copy Roles</p>
            <div class="columns">
                <div class="column is-full">
                    <p>Select roles to copy from <strong>{{userName}}</strong></p>
                    <p-listbox [options]="userRoles" [(ngModel)]="selectedRoles" [metaKeySelection]="false"
                        [checkbox]="true" [filter]="false" [multiple]="true" optionLabel="roleName"
                        [listStyle]="{'max-height':'200px'}" [style]="{'width':'25rem'}"></p-listbox>
                </div>
            </div>
            <div class=" column is-8">
                <p>Assign selected roles to these direct reports:</p>
                <p-multiSelect [options]="listOfDirects" [(ngModel)]="selectedDirects" defaultLabel="Select directs"
                    optionLabel="userName" appendTo="body" [resetFilterOnHide]="true"></p-multiSelect>
            </div>
            <div class="columns">
                <div class="column is-2 is-offset-10">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Save" (click)="showWarning()"
                        [disabled]="!selectedRoles.length || !selectedDirects.length"></button>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeDialog()"></button>
    <shared-confirmation-dialog #warningDialog [showYes]="false" noText="OK" title="WARNING"
        (confirmationDialogReject)="assignRoles()"
        text="Copied roles are inactive until they are selected and confirmed by either the Manager or the Learner.">
    </shared-confirmation-dialog>
</div>