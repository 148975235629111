<ng-container *ngIf="user">
    <div class="columns">
        <div class="column">
            <p-table #dt [value]="roles" styleClass="p-datatable-sm" sortField="dueDate" [sortOrder]="1" dataKey="id">
                <ng-template pTemplate="caption" class="narrow">
                    <div class="columns">
                        <div class="column is-8 short narrow">
                            <div class="info-label">View roles already assigned to the learner in myGadget,
                                select roles/responsibilities and deselect assigned roles as needed.
                            </div>
                        </div>
                        <div class="column is-3 is-offset-1 short narrow important">
                            <button *ngIf="isManager" pButton type="button" label="COPY ROLES"
                                class="p-button-rounded p-button-outlined stdColor" style="margin-left: 2rem;"
                                (click)="openCopyRolesModal()"></button>
                            <button [joyrideStep]="tourSteps[0]" title="Add Roles" pButton type="button"
                                label="ADD ROLES" class="p-button-rounded p-button-outlined stdColor floatRight"
                                [prevTemplate]="prevButton" [nextTemplate]="nextButton" [doneTemplate]="doneButton"
                                [stepContent]="stepContent"
                                [stepContentParams]="{text:'Start here if you don\'t have any roles selected, or if you need to select more roles.'}"
                                (click)="openModal()"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="3" pSortableColumn="roleName">Role Name<p-sortIcon field="roleName"></p-sortIcon>
                        </th>
                        <th colspan="2">Pathway</th>
                        <th colspan="3" pSortableColumn="description">Target Audience<p-sortIcon field="description">
                            </p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="displaySelectedBy">Activated By<p-sortIcon
                                field="displaySelectedBy">
                            </p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="createdAt">Added On<p-sortIcon field="createdAt"></p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="isActive">Active Roles<p-sortIcon field="isActive">
                            </p-sortIcon>
                        </th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-role>
                    <tr [class.has-line-through]="role.delete">
                        <td colspan="3">
                            {{ role.roleName }}
                            <a (click)="openRoleMetadata(role.roleId)">
                                <span class="icon"><i class="pi pi-info-circle"></i></span>
                            </a>
                        </td>
                        <td colspan="2">
                            <a #selectPathwayContainer *ngIf="displaySelectPathwayIcon(role)"
                                class="tooltip-container tooltip-super-narrow" (click)="selectPathway(role.roleId)">
                                <span class="icon" pTooltip="Select Pathway" [appendTo]="selectPathwayContainer"
                                    tooltipPosition="top">
                                    <i style="font-size: 1.5em; color: green; font-weight: bold;"
                                        class="pi pi-plus-circle"></i>
                                </span>
                            </a>
                            <a #displayPathwayContainer class="tooltip-container tooltip-super-narrow"
                                (click)="showPathwayDetails(role.pathwayPk)" *ngIf="role.pathwayPk">
                                <span pTooltip="{{role.pathwayName}}" [appendTo]="displayPathwayContainer"
                                    tooltipPosition="top" class="icon">
                                    <i style="font-size: 1.5em;" class="bi bi-p-circle"></i>
                                </span>
                            </a>
                            <ng-container *ngIf="role.pathwayPk"
                                [ngTemplateOutlet]="pathwayRemovalEnabled(role) ? activePathwayRemoval: disabledPathwayRemoval"
                                [ngTemplateOutletContext]="{role:role}">
                            </ng-container>

                            {{role.pathwayPendingRemoval ? 'Pending' : ''}}
                        </td>
                        <td colspan="3">{{ role.description }}</td>
                        <td colspan="2">{{ role.displaySelectedBy }}
                            <ng-container *ngIf="manager && manager.mudId !== role.selectedBy
                                && role.isActive === role.isActiveOriginal && !role.delete && !role.isNew">
                                <div class="is-pulled-right">
                                    <a *ngIf="!role.managerMudIdForOverride else undo"
                                        (click)="toggleManagerOverride(role)">
                                        <span class="icon tooltip-container tooltip-super-narrow" #overrideContainer>
                                            <i pTooltip="Over-Ride: change the selection/assignment name to mine."
                                                class="pi pi-user" tooltipPosition="top"
                                                [appendTo]="overrideContainer"></i>
                                        </span>
                                    </a>
                                    <ng-template #undo>
                                        <a class="" (click)="toggleManagerOverride(role)">
                                            <span class="icon"><i class="pi pi-undo"></i></span>
                                        </a>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </td>
                        <td colspan="2">{{ role.createdAt | date: 'dd-MMM-yyyy' }}</td>
                        <td colspan="2">
                            <div *ngIf="!role.isNew else new" class="center">
                                <p-checkbox [disabled]="(role.selectedBy !== user.mudId && !manager && role.isActiveOriginal) || (!manager && role.selectedByRelation === 'Admin')"
                                [binary]="true" [(ngModel)]="role.isActive"></p-checkbox>
                            </div>
                            <ng-template #new>Pending</ng-template>
                        </td>
                        <td *ngIf="(role.assignedBy === user.mudId && role.selectedByRelation !== 'Admin') || manager">
                            <ng-container *ngIf="!role.delete else undo">
                                <a (click)="toggleDeleteRole(role)">
                                    <span class="icon"><i class="pi pi-trash"></i></span>
                                </a>
                            </ng-container>
                            <ng-template #undo>
                                <a class="" (click)="toggleDeleteRole(role)">
                                    <span class="icon"><i class="pi pi-undo"></i></span>
                                </a>
                            </ng-template>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No roles found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="sticky-bottom">
                <button pButton type="button" label="CONFIRM ROLE(S) & SELECT TRAINING"
                    class="p-button-rounded stdColor floatRight mt-4" (click)="checkChangesAndConfirm()"
                    [joyrideStep]="tourSteps[1]" title="Confirm Selection" [stepContent]="stepContent"
                    [stepContentParams]="{text:'After making changes, select to confirm.'}"></button>
            </div>
        </div>
    </div>
    <app-role-selection #roleSearchDialog (rolesSelected)="onRolesSelected($event)" [alreadySelectedRoles]="roles">
    </app-role-selection>
    <shared-confirmation-dialog #confirmationDialog noText="CANCEL" yesText="CONFIRM">
    </shared-confirmation-dialog>
    <copy-role-assignemt #copyRoleDialog [userId]="user.mudId" [userName]="user.displayName" [userRoles]="roles"
        [isManager]='this.isManager' [isManagerProxy]='isManagerProxy'>
    </copy-role-assignemt>
    <shared-pathway-selection #pathwaySelection (pathwaySelected)="onPathwaySelected($event)"></shared-pathway-selection>
    <shared-confirmation-dialog #pathwayDeletionDialog noText="Cancel" yesText="Deselect Pathway"
        (confirmationDialogAccept)="onPathwayRemoved()"
        text="Deselecting a Pathway will result in the assignment of all Role Training for this myGadget Role after the Manage Compliance Training steps are confirmed.">
    </shared-confirmation-dialog>
    <shared-display-pathway #displayPathwayModal></shared-display-pathway>
    <div class="modal is-active" *ngIf="showRoleMetadata">
        <div class="modal-background"></div>
        <div class="modal-content">
            <div class="box">
                <p class="menu-label">Role Metadata</p>
                <shared-read-only-role-metadata [role]="selectedRole" #roleMetadata></shared-read-only-role-metadata>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" (click)="closeRoleMetadata()"></button>
    </div>
</ng-container>

<ng-template #activePathwayRemoval let-role="role">
    <a #removePathwayContainer (click)="removePathway(role)" class="tooltip-container tooltip-super-narrow">
        <span class="icon" pTooltip="Remove Pathway" [appendTo]="removePathwayContainer" tooltipPosition="top">
            <i class="pi pi-trash"></i>
        </span>
    </a>
</ng-template>

<ng-template #disabledPathwayRemoval>
    <span #removePathwayContainer class="tooltip-container tooltip-narrow">
        <span class="icon" pTooltip="You can only remove self assigned pathways" [appendTo]="removePathwayContainer"
            tooltipPosition="top">
            <i class="pi pi-trash"></i>
        </span>
    </span>
</ng-template>