<ng-container *ngIf="user">
    <div class="columns">
        <div class="column">
            <p-table #dt [value]="myLearningItems" styleClass="p-datatable-sm" sortField="complianceSetting"
                [sortOrder]="1" dataKey="id" [globalFilterFields]="['myLearningCode','title','roleNames']"
                (sortFunction)="customSort($event)" [customSort]="true">
                <ng-template pTemplate="caption" class="narrow">
                    <div class="columns">
                        <div class="column is-6 short narrow">
                            <div class="info-label">See trainings associated to selected myGadget roles and
                                assign/unassign
                                training.</div>
                        </div>
                        <div class="column is-3 is-offset-3 short narrow">
                            <span class="p-input-icon-left fill">
                                <i class="pi pi-search"></i>
                                <input class="fill" pInputText type="text" [(ngModel)]="globalSearchInput"
                                    (input)="dt.filterGlobal(globalSearchInput, 'contains')"
                                    placeholder="Global Search" />
                            </span>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-2 short narrow important">
                        </div>
                        <div class="column is-2 is-offset-8 short narrow important">
                            <shared-toggle-table-filters-button (buttonToggled)="toggleAdvSearch($event)">
                            </shared-toggle-table-filters-button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="8" pSortableColumn="myLearningCode">myL Code
                            <p-sortIcon field="myLearningCode">
                            </p-sortIcon>
                        </th>
                        <th colspan="6" pSortableColumn="trainingType">myL Type
                            <p-sortIcon field="trainingType"></p-sortIcon>
                        </th>
                        <th colspan="12" pSortableColumn="title"> myL Title
                            <p-sortIcon field="title"></p-sortIcon>
                        </th>
                        <th colspan="7" pSortableColumn="roleNames">Role<p-sortIcon field="roleNames">
                            </p-sortIcon></th>
                        <th colspan="10" pSortableColumn="displayAssignedBy">Added By<p-sortIcon
                                field="displayAssignedBy">
                            </p-sortIcon>
                        </th>
                        <th colspan="8" pSortableColumn="complianceSetting">Compliance Setting<p-sortIcon
                                field="complianceSetting"></p-sortIcon>
                        </th>
                        <th colspan="4" [joyrideStep]="tourSteps[0]" title="Assign" [prevTemplate]="prevButton"
                            [nextTemplate]="nextButton" [doneTemplate]="doneButton" [stepContent]="stepContent"
                            [stepContentParams]="{text:'Select any Task-Based training you need assigned now in myLearning.'}">
                            Assign</th>
                    </tr>
                    <tr #advSearch [ngClass]="{'collapsedContent': showAdvSearch === false}" class="notAHeader">
                        <th colspan="8">
                            <input pInputText type="text" [(ngModel)]="myLCodeInput"
                                (input)="dt.filter(myLCodeInput, 'myLearningCode', 'contains')"
                                placeholder="Search myL Code" class="p-column-filter">
                        </th>
                        <th colspan="6">
                            <p-dropdown [options]="types" appendTo="body"
                                (onChange)="dt.filter($event.value, 'trainingType', 'equals')"
                                styleClass="p-column-filter" [showClear]="true" placeholder="Select myL Type">
                                <ng-template let-option pTemplate="item">
                                    {{option.label}}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th colspan="12">
                            <input pInputText type="text" [(ngModel)]="titleInput"
                                (input)="dt.filter(titleInput, 'title', 'contains')" placeholder="Search Title"
                                class="p-column-filter">
                        </th>
                        <th colspan="7">
                            <input pInputText type="text" [(ngModel)]="roleNameInput"
                                (input)="dt.filter(roleNameInput, 'roleNames', 'contains')" placeholder="Search Roles"
                                class="p-column-filter">
                        </th>
                        <th colspan="10">
                            <p-multiSelect [options]="listOfAssignedBy" appendTo="body" placeholder="All"
                                (onChange)="dt.filter($event.value, 'displayAssignedBy', 'in')"
                                styleClass="p-column-filter" [resetFilterOnHide]="true">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        {{option.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                        <th colspan="8">
                            <p-dropdown [options]="listOfSettings" appendTo="body"
                                (onChange)="dt.filter($event.value, 'complianceSetting', 'equals')"
                                styleClass="p-column-filter" placeholder="compliance Setting" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    {{option.label}}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th colspan="4"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-training let-expanded="expanded">
                    <tr [class.has-line-through]="training.isStruckThrough">
                        <td colspan="8">{{ training.myLearningCode }}<span
                                *ngIf="training.trainingType === 'Item' && utilService.isNewTraining(training.revisionDate)"
                                class='pi pi-exclamation-circle'
                                pTooltip="This training has been revised in the last 90 days."></span>
                        </td>
                        <td colspan="6">{{ training.trainingType }}</td>
                        <td colspan="12">{{ training.title }}</td>
                        <td colspan="7">
                            <ng-container *ngFor="let role of training.roles">
                                {{ role.roleName }}
                                <a #displayPathwayContainer class="tooltip-container tooltip-super-narrow"
                                    (click)="showPathwayDetails(role.pathwayPk)" *ngIf="role.pathwayPk">
                                    <span pTooltip="{{role.pathwayName}}" [appendTo]="displayPathwayContainer"
                                        tooltipPosition="top" class="icon">
                                        <i style="font-size: 1.5em;" class="bi bi-p-circle"></i>
                                    </span>
                                </a>
                            </ng-container>
                        </td>
                        <td colspan="10">{{ training.displayAssignedBy }}
                            <ng-container
                                *ngIf="manager && training.assignedBy && manager.mudId !== training.assignedBy && training.assigned">
                                <div class="is-pulled-right">
                                    <a *ngIf="!training.managerMudIdForOverride else undo"
                                        (click)="toggleManagerOverride(training)">
                                        <span class="icon tooltip-container tooltip-super-narrow" #overrideContainer>
                                            <i pTooltip="Over-Ride: change the selection/assignment name to mine."
                                                class="pi pi-user" tooltipPosition="top"
                                                [appendTo]="overrideContainer"></i>
                                        </span>
                                    </a>
                                    <ng-template #undo>
                                        <a class="" (click)="toggleManagerOverride(training)">
                                            <span class="icon"><i class="pi pi-undo"></i></span>
                                        </a>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </td>
                        <td colspan="8">{{ training.complianceSetting }}</td>
                        <td colspan="4"
                            *ngIf="!training.isStruckThrough || (!training.assigned && training.assignedOriginal)">
                            <div class="center" *ngIf="training.complianceSetting === 'Role Training' && training.isAssignedInMyLearning
                                || (training.assignedBy && training.assignedBy !== user.mudId && !manager)">
                                <span class="icon gray"><i class="pi pi-trash"></i></span>
                            </div>
                            <div class="center"
                                *ngIf="training.complianceSetting === 'Role Training' && !training.isAssignedInMyLearning && training.status !== 'PathwayAssignment'">
                                Pending
                            </div>
                            <div class="center" style="text-align: center;"
                                *ngIf="training.status === 'PathwayAssignment'">
                                <a style="color: blue;" (click)="assignNow(training)"><u>Assign Now</u></a>
                                Pending Pathway Assignment
                            </div>
                            <a *ngIf="training.isPendingOverride" class="" (click)="undoAssignNow(training)">
                                <span class="icon"><i class="pi pi-undo"></i></span>
                            </a>
                            <div class="center" *ngIf="training.complianceSetting !== 'Role Training'
                                && training.isAssignedInMyLearning
                                && (!training.assignedBy || training.assignedBy === user.mudId || manager)">
                                <a *ngIf="training.assigned" class="" (click)="toggleTraining(training)">
                                    <span class="icon"><i class="pi pi-trash"></i></span>
                                </a>
                                <a *ngIf="!training.assigned" class="" (click)="toggleTraining(training)">
                                    <span class="icon"><i class="pi pi-undo"></i></span>
                                </a>
                            </div>
                            <div class="center"
                                *ngIf="training.complianceSetting !== 'Role Training' && !training.isAssignedInMyLearning">
                                <p-checkbox [binary]="true" [(ngModel)]="training.assigned"></p-checkbox>
                                <hr class="linebreak">
                                <span *ngIf="training.assigned">Pending</span>
                            </div>
                        </td>
                        <td colspan="4"
                            *ngIf="training.isStruckThrough && (training.assigned === training.assignedOriginal)">
                            Pending
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="56">No trainings found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="sticky-bottom">
                <button pButton type="button" [disabled]="user.onLongTermLeave"
                    label="UPDATE ALL CHANGES IN MYLEARNING PLAN" [joyrideStep]="tourSteps[1]"
                    title="Update Changes in myLearning" class="p-button-rounded stdColor floatRight mt-4"
                    (click)="confirmTrainingAssignments()" stepPosition="top" [stepContent]="stepContent"
                    [stepContentParams]="{text:'Confirm selections in this view.'}"></button>
            </div>
        </div>
    </div>
    <shared-confirmation-dialog #confirmationDialog></shared-confirmation-dialog>
    <shared-display-pathway #displayPathwayModal></shared-display-pathway>
</ng-container>