<ng-container>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Business Unit</p>
            <p-dropdown [options]="hierarchies.businessUnits" appendTo="body" autoWidth="false"
                [(ngModel)]="hierarchy.businessUnit" styleClass="p-column-filter" placeholder="Select BU"
                [showClear]="true" (onChange)="businessUnitSelected($event.value)" optionLabel="value" [filter]="true">
            </p-dropdown>
        </div>
    </div>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Business Area / Department</p>
            <p-dropdown [options]="listOfBusinessAreasFiltered" appendTo="body" [disabled]="ddBADisabled"
                [(ngModel)]="hierarchy.businessArea" (onChange)="businessAreaSelected($event.value)"
                styleClass="p-column-filter" placeholder="Select BA/Dpt" [showClear]="true" optionLabel="value"
                [filter]="true">
            </p-dropdown>
        </div>
    </div>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Group</p>
            <p-dropdown [options]="listOfGroupsFiltered" appendTo="body" [disabled]="ddGroupsDisabled"
                [(ngModel)]="hierarchy.group" styleClass="p-column-filter" placeholder="Select Group" [showClear]="true"
                optionLabel="value" [filter]="true" (onChange)="selectionChanged.emit()">
            </p-dropdown>
        </div>
    </div>
</ng-container>