import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { IAuthService } from './iAuthService';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

    constructor(private router: Router, private authService: IAuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const admin = route.data.adminOnly ?? false;
        const superAdmin = route.data.superAdmin ?? false;
        const manager = route.data.manager ?? false;
        const managerProxy = route.data.managerProxy ?? false;

        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        else {
            if ((admin && !this.authService.user.isAdmin) ||
                (manager && !managerProxy && !this.authService.user.isManager) ||
                (superAdmin && !this.authService.user.isSuperAdmin) ||
                (managerProxy && !this.authService.user.isAdmin)) {
                this.router.navigate(['/']);
                return false;
            }
            return true;
        }
    }
}