import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';
import { AdminRole } from '../models/adminRole';
import { Pathway } from '../models/pathway';

@Injectable({
  providedIn: 'root',
})
export class PathwayService {

  constructor(private http: HttpClient,
    private loadingService: LoadingService) { }

  addRoles(roles: AdminRole[], asSuperAdmin = false): Observable<void> {
    this.loadingService.show();
    const endpoint = asSuperAdmin ? '/mygadgetsuperadmin/roles' : `/mygadget/roles`;

    return this.http.post<void>(endpoint, roles).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  getRolePathways(roleId: number): Observable<Pathway[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/pathways/RolePathways?roleId=${roleId}`;

    return this.http.get<Pathway[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  getAllVersionsOfPathways(pathwayId: string): Observable<Pathway[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/pathways/allVersionsOfPathways?pathwayId=${pathwayId}`;

    return this.http.get<Pathway[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  getPathways(pathwayPks: string[]): Observable<Pathway[]> {
    this.loadingService.show();
    const endpoint = '/mygadget/pathways/pathways';

    return this.http.post<Pathway[]>(endpoint, pathwayPks).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  addPathway(pathway: Pathway, proxiedManager: string): Observable<void> {
    this.loadingService.show();
    const endpoint = proxiedManager ? `/mygadget/managerProxy/${proxiedManager}/pathways/Add` : '/mygadget/pathways/Add';

    return this.http.post<void>(endpoint, pathway).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  deletePathways(pathwayId: string, proxiedManager: string, isAdmin: boolean): Observable<void> {
    this.loadingService.show();
    const ids = [pathwayId];
    var endpoint = '';
    if (proxiedManager) {
      endpoint = `/mygadget/managerProxy/${proxiedManager}/pathways/Delete`;
    }
    else if (isAdmin) {
      endpoint = '/mygadget/pathways/adminDelete';
    }
    else {
      endpoint = '/mygadget/pathways/delete';
    }

    return this.http.post<void>(endpoint, ids).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  editPathway(pathway: Pathway, proxiedManager: string, isAdmin: boolean): Observable<void> {
    this.loadingService.show();

    var endpoint = '';
    if (proxiedManager) {
      endpoint = `/mygadget/managerProxy/${proxiedManager}/pathways/edit`;
    }
    else if (isAdmin) {
      endpoint = '/mygadget/pathways/adminEdit';
    }
    else {
      endpoint = '/mygadget/pathways/edit';
    }

    return this.http.put<void>(endpoint, pathway).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  getListOfTrainingThatAreTriggers(roleTrainingIds: number[]): Observable<string[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/pathways/getListOfTrainingThatAreTriggers`;

    return this.http.post<string[]>(endpoint, roleTrainingIds).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  getPathway(pathwayPk: string): Observable<Pathway> {
    this.loadingService.show();
    const endpoint = `/mygadget/pathways/pathway?pathwayPk=${pathwayPk}`;

    return this.http.get<Pathway>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }
}
