import { DatePipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin } from 'rxjs';
import { BusinessArea } from '../models/businessArea';
import { BusinessUnit } from '../models/businessUnit';
import { Country } from '../models/country';
import { Group } from '../models/group';
import { RoleAssignment } from '../models/roleAssignment';
import { SearchedRole } from '../models/searchedRole';
import { Site } from '../models/site';
import { MessageService } from '../services/message.service';
import { MetadataService } from '../services/metadata.service';
import { UtilService } from '../services/util.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
    selector: 'app-role-selection',
    templateUrl: './role-selection.component.html',
    styleUrls: ['./role-selection.component.css'],
})
export class RoleSelectionComponent implements OnInit {
    constructor(
        private utilService: UtilService,
        private readonly messageService: MessageService,
        private readonly metadataService: MetadataService,
        private readonly datePipe: DatePipe
    ) {}

    ngOnInit(): void {}

    @Output() rolesSelected = new EventEmitter<SearchedRole[]>();
    @Input() alreadySelectedRoles: RoleAssignment[];

    @ViewChild('dt_addRoles') addRolesTable: Table;

    public isVisible: boolean;
    public searchedRoles: SearchedRole[];

    searchInput: string;
    showAdvSearch: boolean = false;
    advSearchLastReviews: any;
    advSearchAppropriateFor: any;
    advSearchAdmins: any;
    advSearchOwners: any;
    contactInput: string;
    targetAudienceInput: string;
    roleNameInput: string;

    selectedBusinessUnitId: number;
    selectedBusinessAreaId: number;
    selectedGroupId: number;
    selectedCountryId: number;
    selectedSiteId: number;

    listOfBusinessUnits: BusinessUnit[];
    listOfBusinessAreas: BusinessArea[];
    listOfBusinessAreasFiltered: BusinessArea[];
    listOfGroups: Group[];
    listOfGroupsFiltered: Group[];
    listOfCountries: Country[];
    listOfSites: Site[];
    listOfSitesFiltered: Site[];

    ddBADisabled: boolean = true;
    ddGroupsDisabled: boolean = true;
    ddSitesDisabled: boolean = true;
    roleSearchDisabled: boolean = true;

    public showModal() {
        this.isVisible = true;
        //reset dropdowns
        this.searchedRoles = null;
        this.listOfBusinessAreasFiltered = null;
        this.listOfGroupsFiltered = null;
        this.listOfSitesFiltered = null;
        this.ddBADisabled = true;
        this.ddGroupsDisabled = true;
        this.ddSitesDisabled = true;
        this.roleSearchDisabled = true;

        //get dropdown data if not already available
        const observables: { [key: string]: any } = {};
        const observableKeys: string[] = [];

        if (!this.listOfBusinessUnits) {
            observables['businessUnits'] =
                this.metadataService.getBusinessUnits();
            observableKeys.push('businessUnits');
        }

        if (!this.listOfBusinessAreas) {
            observables['businessAreas'] =
                this.metadataService.getBusinessAreas();
            observableKeys.push('businessAreas');
        }
        if (!this.listOfGroups) {
            observables['groups'] = this.metadataService.getGroups();
            observableKeys.push('groups');
        }

        if (!this.listOfCountries) {
            observables['countries'] = this.metadataService.getCountries();
            observableKeys.push('countries');
        }
        if (!this.listOfSites) {
            observables['sites'] = this.metadataService.getSites();
            observableKeys.push('sites');
        }

        if (observableKeys.length > 0) {
            forkJoin(observables).subscribe({
                next: (result) => {
                    if (result.businessUnits) {
                        this.listOfBusinessUnits =
                            this.utilService.sortBusinessUnits(
                                result.businessUnits as BusinessUnit[]
                            );
                    }
                    if (result.businessAreas) {
                        this.listOfBusinessAreas = this.utilService.sortArray(
                            result.businessAreas as BusinessArea[],
                            'value',
                            1
                        ) as BusinessArea[];
                    }
                    if (result.groups) {
                        this.listOfGroups = this.utilService.sortArray(
                            result.groups as Group[],
                            'value',
                            1
                        ) as Group[];
                    }
                    if (result.countries) {
                        this.listOfCountries = this.utilService.sortArray(
                            result.countries as Country[],
                            'value',
                            1
                        ) as Country[];
                    }
                    if (result.sites) {
                        this.listOfSites = this.utilService.sortArray(
                            result.sites as Site[],
                            'value',
                            1
                        ) as Site[];
                    }
                },
                error: (_) => {
                    this.messageService.add({
                        text: 'There was an error when retrieving user data.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                },
                complete: () => {
                    this.isVisible = true;
                },
            });
        }
    }

    public hideModal = () => (this.isVisible = false);

    public addSelectedRoles = () => {
        const selectedRoles = this.searchedRoles.filter(
            (x) => x.isSelected == true && !x.alreadyAdded
        );
        this.rolesSelected.emit(selectedRoles);
        this.hideModal();
    };

    public horizontalScroll(firstWrapper, secondWrapper) {
        secondWrapper.scrollLeft = firstWrapper.scrollLeft;
    }

    public selectAllRoles(event: CheckboxChangeEvent) {
        if (this.searchedRoles) {
            this.searchedRoles.forEach((element) => {
                if (!element.alreadyAdded) {
                    element.isSelected = event.checked;
                }
            });
        }
    }

    public customSort(event: SortEvent) {
        const backupFieldsForSorting = {
            roleContentOwner: ['roleContentOwner', 'roleContentOwnerMudId'],
            admin: ['admin', 'adminMudId'],
        };

        this.utilService.sortArray(
            event.data,
            backupFieldsForSorting[event.field]
                ? backupFieldsForSorting[event.field]
                : event.field,
            event.order
        );
    }

    public toggleAdvSearch(event: boolean) {
        this.showAdvSearch = event;
    }

    public globalSearchForAddRolesTable() {
        this.addRolesTable.filterGlobal(this.searchInput, 'contains');
    }

    public applyRoleFilters() {
        this.metadataService
            .getRoles(
                this.selectedBusinessUnitId,
                this.selectedBusinessAreaId,
                this.selectedGroupId,
                this.selectedCountryId,
                this.selectedSiteId
            )
            .subscribe({
                next: (result) => {
                    this.advSearchAppropriateFor = result
                        .map((item) => item.appropriateFor)
                        .filter(
                            (value, index, self) =>
                                self.indexOf(value) === index
                        )
                        .map((i) => {
                            return { label: i, value: i };
                        })
                        .sort((a, b) => (a.label < b.label ? -1 : 1));

                    this.advSearchAdmins = result
                        .map((item) => item.admin ?? item.adminMudId)
                        .filter(
                            (value, index, self) =>
                                self.indexOf(value) === index
                        )
                        .map((i) => {
                            return { label: i, value: i };
                        })
                        .sort((a, b) => (a.label < b.label ? -1 : 1));

                    this.advSearchOwners = result
                        .map(
                            (item) =>
                                item.roleContentOwner ??
                                item.roleContentOwnerMudId
                        )
                        .filter(
                            (value, index, self) =>
                                self.indexOf(value) === index
                        )
                        .map((i) => {
                            return { label: i, value: i };
                        })
                        .sort((a, b) => (a.label < b.label ? -1 : 1));

                    this.advSearchLastReviews = result
                        .map((item) => ({
                            value: item.lastReview,
                            label: this.datePipe.transform(
                                item.lastReview,
                                'dd-MMM-yyyy'
                            ),
                        }))
                        .filter(
                            (value, index, self) =>
                                self
                                    .map((s) => {
                                        return s.value;
                                    })
                                    .indexOf(value.value) === index
                        )
                        .map((i) => {
                            return i.value
                                ? { label: i.label, value: i.value }
                                : 0;
                        })
                        .sort((a, b) => (a.value < b.value ? -1 : 1));

                    result.forEach((element) => {
                        if (
                            this.alreadySelectedRoles.some(
                                (x) => x.roleId == element.id
                            )
                        ) {
                            element.alreadyAdded = true;
                            element.isSelected = true;
                        }
                        if (!element.lastReview) {
                            element.lastReview = 0;
                        }
                        if (!element.admin) {
                            element.admin = element.adminMudId;
                        }
                        if (!element.roleContentOwner) {
                            element.roleContentOwner =
                                element.roleContentOwnerMudId;
                        }
                    });

                    this.searchedRoles = result;
                },
                error: () => {
                    this.messageService.add({
                        text: 'There was an error when retrieving user data.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                },
            });
    }

    public businessUnitSelected(event) {
        this.selectedBusinessUnitId = event.value?.id;

        if (event.value == null) {
            this.listOfBusinessAreasFiltered = null;
            this.selectedBusinessAreaId = null;
            this.selectedGroupId = null;
            this.ddBADisabled = true;
            this.ddGroupsDisabled = true;
            this.roleSearchDisabled = true;
            return;
        }

        this.ddBADisabled = false;
        this.roleSearchDisabled = false;

        this.listOfBusinessAreasFiltered = this.listOfBusinessAreas.filter(
            (x) => x.businessUnitId == event.value.id
        );
    }

    public businessAreaSelected(event) {
        this.selectedBusinessAreaId = event.value?.id;

        if (event.value == null) {
            this.listOfGroupsFiltered = null;
            this.selectedGroupId = null;
            this.ddGroupsDisabled = true;
            return;
        }

        this.ddGroupsDisabled = false;

        this.listOfGroupsFiltered = this.listOfGroups.filter(
            (x) => x.businessAreaId == event.value.id
        );
    }

    public groupSelected(event) {
        this.selectedGroupId = event.value?.id;
    }

    public countrySelected(event) {
        this.selectedCountryId = event.value?.id;

        if (event.value == null) {
            this.listOfSitesFiltered = null;
            this.selectedSiteId = null;
            this.ddSitesDisabled = true;
            return;
        }

        this.ddSitesDisabled = false;

        this.listOfSitesFiltered = this.listOfSites.filter(
            (x) => x.countryId == event.value.id
        );
    }

    public siteSelected(event) {
        this.selectedSiteId = event.value?.id;
    }
}
