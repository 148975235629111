import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TwoButtonsContainerComponent } from './two-buttons-container/two-buttons-container.component';
import { ButtonModule } from 'primeng/button';
import { ToggleTableFiltersButtonComponent } from './toggle-table-filters-button/toggle-table-filters-button.component';
import { TrashButtonComponent } from './trash-button/trash-button.component';
import { ExcelExportButtonComponent } from './excel-export-button/excel-export-button.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DividerModule } from 'primeng/divider';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeTableModule } from 'primeng/treetable';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { HierarchyPipe } from './pipes/hierarchy.pipe';
import { LocationPipe } from './pipes/location.pipe';
import { UserBasesPipe } from './pipes/userBases.pipe';
import { EditReferenceDialogComponent } from './edit-reference-dialog/edit-reference-dialog.component';
import { CurriculumDetailsComponent } from './curriculum-details/curriculum-details.component';
import { DisplayPathwayComponent } from './display-pathway/display-pathway.component';
import { PathwayDetailsComponent } from './pathway-details/pathway-details.component';
import { ReadOnlyRoleMetadataComponent } from './read-only-role-metadata/read-only-role-metadata.component';
import { StageGateComponent } from './stage-gate/stage-gate.component';
import { PathwaySelectionComponent } from './pathway-selection/pathway-selection.component';
import { RolesReportComponent } from './roles-report/roles-report.component';
import { AdminsAndBackupadminsComponent } from './admins-and-backupadmins/admins-and-backupadmins.component';
import { ContentOwnersComponent } from './content-owners/content-owners.component';
import { HierarchiesComponent } from './hierarchies/hierarchies.component';
import { LocationsComponent } from './locations/locations.component';

@NgModule({
  declarations: [
    TwoButtonsContainerComponent,
    ToggleTableFiltersButtonComponent,
    TrashButtonComponent,
    ExcelExportButtonComponent,
    EditReferenceDialogComponent,
    ConfirmationDialogComponent,
    CurriculumDetailsComponent,
    DisplayPathwayComponent,
    PathwayDetailsComponent,
    ReadOnlyRoleMetadataComponent,
    StageGateComponent,
    PathwaySelectionComponent,
    RolesReportComponent,
    AdminsAndBackupadminsComponent,
    ContentOwnersComponent,
    HierarchiesComponent,
    LocationsComponent,

    HierarchyPipe,
    LocationPipe,
    UserBasesPipe,
  ],
  exports: [
    TwoButtonsContainerComponent,
    ToggleTableFiltersButtonComponent,
    TrashButtonComponent,
    ExcelExportButtonComponent,
    EditReferenceDialogComponent,
    ConfirmationDialogComponent,
    CurriculumDetailsComponent,
    DisplayPathwayComponent,
    PathwayDetailsComponent,
    ReadOnlyRoleMetadataComponent,
    StageGateComponent,
    PathwaySelectionComponent,
    RolesReportComponent,
    AdminsAndBackupadminsComponent,
    ContentOwnersComponent,
    HierarchiesComponent,
    LocationsComponent,

    DividerModule,
    FormsModule,
    CommonModule,
    ButtonModule,
    DropdownModule,
    PanelModule,
    InputTextModule,
    CheckboxModule,
    TooltipModule,
    TableModule,
    ListboxModule,
    InputNumberModule,
    TreeTableModule,
    AutoCompleteModule,
    CalendarModule,
    MultiSelectModule,
    RadioButtonModule,

    HierarchyPipe,
    LocationPipe,
    UserBasesPipe,
  ],
  imports: [
    FormsModule,
    CommonModule,
    ButtonModule,
    DropdownModule,
    PanelModule,
    InputTextModule,
    CheckboxModule,
    TooltipModule,
    TableModule,
    DividerModule,
    ListboxModule,
    InputNumberModule,
    TreeTableModule,
    AutoCompleteModule,
    CalendarModule,
    MultiSelectModule,
    RadioButtonModule
  ],
  providers: [
    LocationPipe,
    HierarchyPipe,
    UserBasesPipe,
    DatePipe
  ]
})
export class SharedModule { }
