<div class="modal is-active" *ngIf="display">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">Add Pathway</p>
            <admin-editable-pathway-details #pathwayDetails [isAdmin]="isAdmin" [pathway]="pathway"
                [listOfOwners]="listOfOwners">
            </admin-editable-pathway-details>
            <div class="columns">
                <div class="column is-3 is-offset-9">
                    <shared-two-buttons-container confirmLabel="SUBMIT" cancelLabel="CANCEL" [onConfirm]="submit"
                        [onCancel]="closeDialog"></shared-two-buttons-container>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeDialog()"></button>
</div>