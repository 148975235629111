import { Pipe, PipeTransform } from '@angular/core';
import { UserBase } from '../../models/userBase';
@Pipe({
  name: 'userBases'
})
export class UserBasesPipe implements PipeTransform {

  transform(value: UserBase[]): string {
    if (!value) { return undefined; }
    return value.map(a => `${a.name} (${a.mudId})`).join(', ')
  }
}