<div class="modal is-active" *ngIf="isDisplayed">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p-table #dt [value]="training" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="3" pSortableColumn="myLearningCode">myL Code
                            <p-sortIcon field="myLearningCode">
                            </p-sortIcon>
                        </th>
                        <th colspan="5" pSortableColumn="title">myL Title
                            <p-sortIcon field="title"></p-sortIcon>
                        </th>
                        <th colspan="6" pSortableColumn="description">Description
                            <p-sortIcon field="description"></p-sortIcon>
                        </th>
                        <th pSortableColumn="initNumber">Training Time Allowed
                            <p-sortIcon field="initNumber"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-training>
                    <tr>
                        <td colspan="3">{{ training.myLearningCode }}</td>
                        <td colspan="5">{{ training.title }}</td>
                        <td colspan="6">{{ training.description }}</td>
                        <td>{{ training.initNumber === 0 ? '' : training.initNumber }} {{training.initPeriodType}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No trainings found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeModal()"></button>
</div>