import { Component } from '@angular/core';

@Component({
    selector: 'super-admin',
    templateUrl: './super-admin.component.html',
    styleUrls: ['./super-admin.component.css', '../shared-styles/tabs.css'],
})
export class SuperAdminComponent {
    constructor() {}
}
