<p-treeTable [value]="treeNodes" styleClass="p-datatable-sm">
    <ng-template pTemplate="header">
        <ng-container *ngTemplateOutlet="tableHeader"></ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
        <tr>
            <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                <span class="center tooltip-container tooltip-super-narrow" #trainingTypeContainer>
                    <a *ngIf="rowData.type === 'Curriculum'" (click)="displayCurriculum(rowData.myLearningCode)">
                        <span class="icon linked-icon" pTooltip="Curriculum" [appendTo]="trainingTypeContainer"
                            tooltipPosition="right"><i class="pi pi-book"></i></span>
                    </a>
                    <span *ngIf="rowData.type === 'Item'" class="icon" pTooltip="Item"
                        [appendTo]="trainingTypeContainer" tooltipPosition="right"><i class="pi pi-file"></i></span>
                </span>
                <span pTooltip="{{rowData.title}}">{{rowData.myLearningCode}}</span>
            </td>
            <td *ngIf="isEditable">
                <p-dropdown *ngIf="displayStageGateDropdown(rowNode)" styleClass="p-column-filter"
                    [options]="[{ value: 0, label: '0' }, { value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' }]"
                    [showClear]="false" optionLabel="label" optionValue="value" [(ngModel)]="rowData.stageGate"
                    (onChange)="moveTraining($event, rowNode)">
                </p-dropdown>
            </td>
            <td>
                <p-dropdown *ngIf="isEditable" styleClass="p-column-filter" [(ngModel)]="rowData.triggerType"
                    [options]="getTriggerOption(rowNode)" [showClear]="false"
                    (onChange)="adjustTrigerType($event, rowData)">
                </p-dropdown>
                <span *ngIf="!isEditable">{{rowData.triggerType}}</span>
            </td>
            <td>
                <ng-container *ngIf="isEditable && rowData.triggerType === 'TrainingCompletion'">
                    <p-dropdown styleClass="p-column-filter" [(ngModel)]="rowData.trigger"
                        [options]="getTriggeredByOption(rowNode)" [showClear]="false"
                        [class.ng-invalid]="isValidated && !rowData.trigger" optionLabel="myLearningCode"
                        placeholder="Select training" (onChange)="adjustTree()">
                    </p-dropdown>
                    <p *ngIf="isValidated && !rowData.trigger" class="help is-danger">
                        You must select the training.
                    </p>
                </ng-container>
                <span *ngIf="!isEditable">{{rowData.trigger?.myLearningCode ?? 'n/a'}}</span>
            </td>
            <td>
                <ng-container *ngIf="isEditable && rowData.triggerType === 'TrainingCompletion'">
                    <p-inputNumber styleClass="p-column-filter" [(ngModel)]="rowData.delay" mode="decimal" [min]="0"
                        [showButtons]="true" [allowEmpty]="false">
                    </p-inputNumber>
                    <p *ngIf="isValidated && !(rowData.delay >= 0)" class="help is-danger">You must select
                        the delay.</p>
                </ng-container>
                <span *ngIf="!isEditable">{{rowData.delay ?? 'n/a'}}</span>
            </td>
        </tr>
    </ng-template>
</p-treeTable>

<ng-template #tableHeader>
    <tr *ngIf="isEditable; else readonlyHeader">
        <th style="width: 43%;">Training ID</th>
        <th style="width: 8%;">Stage Gate</th>
        <th style="width: 13%;">Trigger</th>
        <th style="width: 23%;">Triggered By</th>
        <th style="width: 13%;">Delay in days</th>
    </tr>
</ng-template>

<ng-template #readonlyHeader>
    <tr>
        <th style="width: 48%;">Training ID</th>
        <th style="width: 16%;">Trigger</th>
        <th style="width: 23%;">Triggered By</th>
        <th style="width: 13%;">Delay in days</th>
    </tr>
</ng-template>