<ng-container *ngIf="user">
    <div class="columns">
        <div class="column">
            <p-table #dt [value]="trainings" styleClass="p-datatable-sm" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true" [expandedRowKeys]="expandedRows"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10,25,50]" [filterDelay]="0"
                [globalFilterFields]="['myLearningCode','title','description','items']" [sortOrder]="1"
                dataKey="myLearningCode" (sortFunction)="customSort($event)" [customSort]="true">
                <ng-template pTemplate="caption" class="narrow">
                    <div class="columns">
                        <div class="column is-6 short narrow">
                            <div class="info-label">See myGadget compliance training that is currently due and/or
                                completed, launch training and access support materials</div>
                        </div>
                        <div class="column is-3 is-offset-3 short narrow">
                            <span class="p-input-icon-left fill">
                                <i class="pi pi-search"></i>
                                <input class="fill" pInputText type="text" [(ngModel)]="globalSearchInput"
                                    (input)="dt.filterGlobal(globalSearchInput, 'customFilter')"
                                    placeholder="Global Search" />
                            </span>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-2 short narrow important">
                            <button pButton type="button no-padding"
                                [label]="areRowsExpanded ? 'Collapse all' : 'Expand all'"
                                [icon]="areRowsExpanded ? 'pi pi-minus' : 'pi pi-plus'" iconPos="left"
                                class="p-button-text no-padding stdColor" (click)="toggleAllRows()"></button>
                        </div>
                        <div class="column is-2 short narrow important">
                            <button *ngIf="isManager" pButton type="button no-padding"
                                [ngClass]="{'redText': user?.onLongTermLeave, 'stdColor': !user?.onLongTermLeave}"
                                [label]="!user?.onLongTermLeave ? 'Mark on Leave' : 'Return from Leave'"
                                icon="pi pi-briefcase" iconPos="left" class="p-button-text no-padding"
                                (click)="!user?.onLongTermLeave ? longTermLeaveConfirmationDialog.openDialog() : longTermLeaveReturnConfirmationDialog.openDialog()"></button>
                        </div>
                        <div class="column is-3 is-offset-5 short narrow important">
                            <shared-excel-export-button [exportAction]="excelExport"></shared-excel-export-button>
                            <shared-toggle-table-filters-button (buttonToggled)="toggleAdvSearch($event)">
                            </shared-toggle-table-filters-button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 2rem"></th>
                        <th style="width: 3rem"></th>
                        <th pSortableColumn="myLearningCode">myL Code<p-sortIcon field="myLearningCode"></p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="title">myL Title<p-sortIcon field="title"></p-sortIcon>
                        </th>
                        <th pSortableColumn="completionStatus">myL Compl. Status<p-sortIcon field="completionStatus">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="dueDate">myL Due Date<p-sortIcon field="dueDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="displayAssignedBy">Assigned By<p-sortIcon field="displayAssignedBy">
                            </p-sortIcon>
                        </th>
                        <th>Action</th>
                    </tr>
                    <tr #advSearch [ngClass]="{'collapsedContent': showAdvSearch === false}" class="notAHeader">
                        <th colspan="2"></th>
                        <th>
                            <input pInputText type="text" [(ngModel)]="myLCodeInput"
                                (input)="dt.filter(myLCodeInput, 'myLearningCode', 'contains')"
                                placeholder="Search myL Code" class="p-column-filter">
                        </th>
                        <th colspan="2">
                            <input pInputText type="text" (input)="dt.filter(titleInput, 'title', 'contains')"
                                [(ngModel)]="titleInput" placeholder="Search Title" class="p-column-filter">
                        </th>
                        <th>
                            <p-dropdown [options]="listOfStatuses" appendTo="body"
                                (onChange)="dt.filter($event.value, 'completionStatus', 'equals')"
                                styleClass="p-column-filter" placeholder="Status" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    {{option.label}}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th>
                            <input pInputText type="text" (input)="dt.filter(dueDateInput, 'dueDate', 'contains')"
                                [(ngModel)]="dueDateInput" placeholder="Search Due Date" class="p-column-filter">
                        </th>
                        <th>
                            <p-multiSelect [options]="listOfAssignedBy" appendTo="body" placeholder="All"
                                (onChange)="onAssignedByChange($event)" styleClass="p-column-filter"
                                [resetFilterOnHide]="true">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        {{option.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                        <th>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-training let-expanded="expanded">
                    <tr [class.redText]="training.completionStatus === 'Not Assigned in myLearning' && !user?.onLongTermLeave"
                        [class.has-line-through]="user?.onLongTermLeave">
                        <td>
                            <div class="center tooltip-container tooltip-super-narrow" #typeIconCurriculum>
                                <i *ngIf="training.trainingType === 'Curriculum'" class="pi pi-book"
                                    pTooltip="Curriculum" tooltipPosition="top" [appendTo]="typeIconCurriculum"></i>
                            </div>
                            <div class="center tooltip-container tooltip-super-narrow" #typeIconItem>
                                <i *ngIf="training.trainingType === 'Item'" class="pi pi-file" pTooltip="Item"
                                    tooltipPosition="top" [appendTo]="typeIconItem"></i>
                            </div>
                        </td>
                        <td><button type="button" pButton pRipple [pRowToggler]="training"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
                        <td>{{ training.myLearningCode }}<span
                                *ngIf="training.trainingType === 'Item' && utilService.isNewTraining(training.myLearningRevisionDate)"
                                class='pi pi-exclamation-circle'
                                pTooltip="This item has been created or revised in the last 90 days."></span>
                        </td>
                        <td colspan="2">{{ training.title }}</td>
                        <td [class.redText]="training.completionStatus === 'Past Due'"
                            [class.tealText]="training.completionStatus === 'Curriculum Not Completed' || training.completionStatus === 'Item Not Completed'">
                            {{ training.completionStatus }}
                            <div *ngIf="training.completionComments" #commentsContainer
                                class="center tooltip-container">
                                <i pTooltip="{{escapeString(training.completionComments)}}" tooltipPosition="top"
                                    class="pi pi-comments" [appendTo]="commentsContainer"></i>
                            </div>
                        </td>
                        <td>
                            <span *ngIf="training.completionStatus === 'Past Due'"
                                class="pi pi-exclamation-triangle pr-1 big-icon"></span>
                            {{ training.dueDate | date: 'dd-MMM-yyyy' }}
                        </td>
                        <td>{{training.displayAssignedBy}}</td>
                        <td *ngIf="training.completionStatus !== 'Not Assigned in myLearning' && displayMenu(training)">
                            <button button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                                class="p-button-rounded p-button-success" (click)="menu.toggle($event)"></button>
                            <p-menu #menu appendTo="body" [popup]="true" [model]="getMenuItems(training, null)">
                            </p-menu>
                        </td>
                        <td
                            *ngIf="training.completionStatus === 'Not Assigned in myLearning' && !user?.onLongTermLeave">
                            <button pButton type="button" label="Assign Training" class="p-button-text link"
                                (click)="assignTraining(training)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No items found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-training>
                    <tr class="additionalInfo">
                        <td></td>
                        <td colspan="3" class="CellWithComment">
                            <strong>myL Description</strong><br>
                            <div pTooltip="{{escapeString(training.description)}}" [showDelay]="500" [hideDelay]="250">
                                {{ training.shortDescription }}</div>
                            <div *ngIf="!training.shortDescription">N/A</div>
                        </td>
                        <td *ngIf="training.trainingType === 'Item'" colspan="2" class="CellWithComment">
                            <strong>myL Completion Comment</strong><br>
                            <div>{{ training.completionComments}}</div>
                            <div *ngIf="!training.completionComments">N/A</div>
                        </td>
                        <td *ngIf="training.trainingType === 'Curriculum'" colspan="2"></td>
                        <td colspan="2" class="CellWithComment">
                            <strong>Link to Associated Material</strong><br>
                            <a *ngFor="let link of training.documentLinks; let last = last;" class="link"
                                href="{{ link.documentLocation }}" target='_blank'>{{link.documentTitle}}{{last ? '' :
                                ','}} </a>
                            <div *ngIf="!training.documentLinks || training.documentLinks.length === 0">N/A</div>
                        </td>
                    </tr>
                    <tr *ngIf="training.trainingType === 'Curriculum'">
                        <td></td>
                        <td colspan="7">
                            <div class="pt-5 pb-5">
                                <p-table [value]="training.items" styleClass="p-datatable-sm" [sortOrder]="1"
                                    dataKey="myLearningCode">
                                    <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 12rem" pSortableColumn="myLearningCode">myL Code<p-sortIcon
                                field="myLearningCode"></p-sortIcon>
                        </th>
                        <th colspan="2" style="width: 20rem" pSortableColumn="title">myL Title<p-sortIcon field="title">
                            </p-sortIcon>
                        </th>
                        <th style="width: 2rem"></th>
                        <th style="width: 10rem" pSortableColumn="completionStatus">myL Compl. Status<p-sortIcon
                                field="completionStatus">
                            </p-sortIcon>
                        </th>
                        <th style="width: 8rem" pSortableColumn="dueDate">myL Due Date<p-sortIcon field="dueDate">
                            </p-sortIcon>
                        </th>
                        <th style="width: 10rem">Associated Material</th>
                        <th style="width: 4rem">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-curriculumTraining>
                    <tr [class.redText]="curriculumTraining.completionStatus === 'Not Assigned in myLearning' && !user?.onLongTermLeave"
                        [class.has-line-through]="curriculumTraining.completionStatus === 'Not Assigned in myLearning' && user?.onLongTermLeave">
                        <td>{{ curriculumTraining.myLearningCode }}
                            <span
                                *ngIf="curriculumTraining.trainingType === 'Item' && utilService.isNewTraining(curriculumTraining.myLearningRevisionDate)"
                                class='pi pi-exclamation-circle'
                                pTooltip="This item has been created or revised in the last 90 days."></span>
                        </td>
                        <td colspan="2">{{ curriculumTraining.title }}</td>
                        <td>
                            <div #descriptionContainer class="center tooltip-container tooltip-wide">
                                <i pTooltip="{{escapeString(curriculumTraining.description)}}" tooltipPosition="top"
                                    class="pi pi-ellipsis-h pr-1" [appendTo]="descriptionContainer"></i>
                            </div>
                        </td>
                        <td [class.redText]="curriculumTraining.completionStatus === 'Past Due'"
                            [class.tealText]="curriculumTraining.completionStatus === 'Curriculum Not Completed' || curriculumTraining.completionStatus === 'Item Not Completed'">
                            {{
                            curriculumTraining.completionStatus }}
                            <div *ngIf="curriculumTraining.completionComments" #commentsContainer
                                class="center tooltip-container">
                                <i pTooltip="{{escapeString(curriculumTraining.completionComments)}}"
                                    tooltipPosition="top" class="pi pi-comments" [appendTo]="commentsContainer"></i>
                            </div>
                        </td>
                        <td>
                            <span *ngIf="curriculumTraining.completionStatus !== 'Item Waived'">
                                {{ curriculumTraining.dueDate | date: 'dd-MMM-yyyy' }}
                                <br />
                                <span *ngIf="curriculumTraining.completionStatus === 'Past Due'"
                                    class=" pi pi-exclamation-triangle red pr-1"></span>
                            </span>

                        </td>
                        <td>
                            <a *ngFor="let link of curriculumTraining.documentLinks; let last = last;" class="link"
                                href="{{ link.documentLocation }}" target='_blank'>{{link.documentTitle}}{{last ? '' :
                                ','}} </a>
                        </td>
                        <td *ngIf="!user?.onLongTermLeave && displayMenu(curriculumTraining)">
                            <button pButton pRipple type="button" icon="pi pi-ellipsis-v"
                                class="p-button-rounded p-button-success"
                                (click)="curriculumItemMenu.toggle($event)"></button>
                            <p-menu #curriculumItemMenu appendTo="body" [popup]="true"
                                [model]="getMenuItems(curriculumTraining, training)">
                            </p-menu>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
    </div>
    <splash-screen #splashScreen></splash-screen>
    <waiver-confirmation [(showDialog)]='showWaiverConfirmation' [request]='itemForWaiver'
        (requestStateChanged)='onWaiverDialogSuccess()' [isManager]='this.isManager'></waiver-confirmation>
    <deferral-confirmation [(showDialog)]='showDeferralConfirmation' [request]='itemForDeferral'
        (requestStateChanged)='onWaiverDialogSuccess()' [isManager]='this.isManager'></deferral-confirmation>
    <shared-confirmation-dialog #longTermLeaveConfirmationDialog noText="CANCEL" yesText="CONFIRM"
        text="You are about to mark this user as away/on long term leave. All of their myGadget training will be unassigned. All myGadget assigned training will be re-assigned upon the user's next login or when the manager marks the staff as returned."
        title="LONG TERM LEAVE CONFIRMATION" (confirmationDialogAccept)="manageStaffOnLeave()">
    </shared-confirmation-dialog>
    <shared-confirmation-dialog #longTermLeaveReturnConfirmationDialog noText="CANCEL" yesText="CONFIRM"
        text="You are about to mark this user as no longer away/on long term leave. All of their previously assigned myGadget training will be reassigned."
        title="LONG TERM LEAVE CONFIRMATION" (confirmationDialogAccept)="manageStaffOnLeave()">
    </shared-confirmation-dialog>
    <shared-confirmation-dialog #reviewContentConfirmationDialog yesText="OK" noText="CANCEL"
        text="WARNING! The Review Content link will only work properly if you are already logged in to myLearning as a learner. If you are not logged in, please open a separate tab and log in to myLearning before clicking 'OK'."
        title="REVIEW CONTENT WARNING" (confirmationDialogAccept)="onReviewContentConfirmed()"></shared-confirmation-dialog>
    <shared-confirmation-dialog #waiverWarning yesText="OK" noText="CANCEL" title="WAIVER WARNING"
        (confirmationDialogAccept)="onWaiverConfirmed()"></shared-confirmation-dialog>
    <shared-confirmation-dialog #deferralWarning yesText="OK" noText="CANCEL" title="DEFERRAL WARNING"
        (confirmationDialogAccept)="onDeferralConfirmed()"></shared-confirmation-dialog>
</ng-container>