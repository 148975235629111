import { Component } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css', '../shared-styles/tabs.css']
})
export class AdminComponent {

  constructor() { }

}
