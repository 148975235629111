import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AddPathwayComponent } from '../add-pathway/add-pathway.component';
import { IAuthService } from '../../auth/iAuthService';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { EditPathwayComponent } from '../edit-pathway/edit-pathway.component';
import { ManagerProxyService } from '../../manager-proxy.service';
import { AdminRole } from '../../models/adminRole';
import { Pathway } from '../../models/pathway';
import { PathwayTraining } from '../../models/pathwayTraining';
import { StageGate } from '../../models/stageGate';
import { UserBase } from '../../models/userBase';
import { UserFullData } from '../../models/userFullData';
import { PathwayDetailsComponent } from '../../shared/pathway-details/pathway-details.component';
import { AdminService } from '../../services/admin.service';
import { MessageService } from '../../services/message.service';
import { PathwayService } from '../../services/pathway.service';
import { RoleService } from '../../services/role.service';

@Component({
    selector: 'admin-pathways',
    templateUrl: './pathways.component.html',
    styleUrls: ['./pathways.component.css'],
})
export class PathwaysComponent implements OnInit, OnDestroy {
    @ViewChild('addPathway') addPathway: AddPathwayComponent;
    @ViewChild('deleteConfirmation')
    deleteConfirmation: ConfirmationDialogComponent;
    @ViewChild('editPathway') editPathway: EditPathwayComponent;
    @ViewChild('pathwayDetails') pathwayDetails: PathwayDetailsComponent;

    public isAdmin: boolean;
    public isProxyManager: boolean;
    public pathways: Pathway[];
    public listOfRoles: AdminRole[] = [];
    public filteredRoles: AdminRole[] = [];
    public selectedRole: number;
    public listOfAdmins: UserBase[] = [];
    public adminsSelected: string[] = [];
    public rolesOption: 'AllRoles' | 'JustMine';
    public listOfOwners: UserBase[];
    public selectedPathway: Pathway;
    public rolesWithPathways: boolean = true;

    private subUser: Subscription;
    private subData: Subscription;
    private user: UserFullData;

    constructor(
        private readonly adminService: AdminService,
        private route: ActivatedRoute,
        private readonly messageService: MessageService,
        private readonly roleService: RoleService,
        private readonly pathwayService: PathwayService,
        private readonly managerProxyService: ManagerProxyService,
        private authService: IAuthService
    ) {}

    async ngOnInit(): Promise<void> {
        this.subData = this.route.data.subscribe((data) => {
            this.isAdmin = data.admin;
            this.isProxyManager = data.managerProxy;
            this.rolesOption =
                this.isAdmin && !this.isProxyManager ? 'JustMine' : 'AllRoles';
        });

        this.subUser = this.authService.userValue.subscribe((result) => {
            this.user = result;
        });

        const loadAmins = this.loadAdmins();
        const loadRolesAndFilters = this.loadRoles();
        await loadAmins;
        await loadRolesAndFilters;

        this.runFilters();

        this.adminService.getManagers().subscribe({
            next: (managers) => {
                const listOfManagers = managers;
                this.adminService.getAdminsAndSuperAdmins().subscribe((a) => {
                    listOfManagers.push(
                        ...a.map(
                            (u) =>
                                <UserBase>{
                                    name: u.displayName,
                                    mudId: u.mudId,
                                }
                        )
                    );
                    this.listOfOwners = [
                        ...new Map(
                            listOfManagers.map((m) => [m['mudId'], m])
                        ).values(),
                    ];
                });
            },
            error: () =>
                this.messageService.add({
                    text: 'Error when fetching owners.',
                    timeout: 30000,
                    style: 'is-danger',
                }),
        });
    }

    ngOnDestroy(): void {
        this.subUser.unsubscribe();
        this.subData.unsubscribe();
    }

    public runFilters() {
        this.selectedRole = undefined;
        this.selectedPathway = undefined;
        this.pathways = undefined;
        if (this.rolesOption === 'JustMine') {
            const user = this.isProxyManager
                ? this.managerProxyService.proxiedManager
                : this.user.mudId;
            this.filteredRoles = this.listOfRoles.filter(
                (r) =>
                    r.adminId === user ||
                    r.backupAdmins?.find((a) => a.mudId === user)
            );
        } else if (this.adminsSelected.length > 0) {
            this.filteredRoles = this.listOfRoles.filter(
                (r) =>
                    this.adminsSelected.includes(r.adminId) ||
                    r.backupAdmins?.some((a) =>
                        this.adminsSelected.includes(a.mudId)
                    )
            );
        } else {
            this.filteredRoles = [...this.listOfRoles];
        }

        if (this.rolesWithPathways) {
            this.filteredRoles = [
                ...this.filteredRoles.filter((r) => r.pathways),
            ];
        }
    }

    public loadPathways(selectedPathway: Pathway) {
        if (this.selectedRole) {
            this.pathwayService
                .getRolePathways(this.selectedRole)
                .subscribe((res) => {
                    this.pathways = res;
                    if (selectedPathway) {
                        this.selectedPathway = this.pathways.find(
                            (p) => p.name === selectedPathway.name
                        );
                    }
                });
        } else {
            this.pathways = undefined;
        }
        this.selectedPathway = undefined;
    }

    public add() {
        this.roleService.getRoleTraining(this.selectedRole).subscribe((t) => {
            const training = t.map((x) => {
                return {
                    roleTrainingId: x.roleTrainingId,
                    myLearningCode: x.myLearningCode,
                    myLearningTypeId: x.myLearningTypeId,
                    revisionDate: x.revisionDate,
                    triggerType: 'StageGate',
                    title: x.title,
                    type: x.type,
                    stageGate: 0,
                } as PathwayTraining;
            });

            const pathway = {
                roleId: this.selectedRole,
                roleName: this.filteredRoles.find(
                    (r) => r.roleId === this.selectedRole
                ).roleName,
                owner: this.getDefaultOwner(),
                version: 1,
                stageGate1: {
                    delay: 0,
                    training: training,
                } as StageGate,
                stageGate2: {
                    delay: 30,
                    training: [],
                } as StageGate,
                stageGate3: {
                    delay: 60,
                    training: [],
                } as StageGate,
                stageGate4: {
                    delay: 90,
                    training: [],
                } as StageGate,
            } as Pathway;

            this.addPathway.showDialog(pathway);
        });
    }

    public delete() {
        const proxiedManager = this.isProxyManager
            ? this.managerProxyService.proxiedManager
            : undefined;
        this.pathwayService
            .deletePathways(
                this.selectedPathway.pathwayId,
                proxiedManager,
                this.isAdmin
            )
            .subscribe({
                next: () => this.loadPathways(null),
                error: (error) =>
                    this.messageService.add({
                        text: error.error,
                        timeout: 30000,
                        style: 'is-danger',
                    }),
            });
    }

    public edit() {
        const pathway = { ...this.selectedPathway };
        this.editPathway.open(pathway);
    }

    public copy() {
        let pathway = { ...this.selectedPathway };

        pathway.name += ' copy';
        pathway.backupOwners = undefined;
        pathway.owner = this.getDefaultOwner();
        pathway.version = 1;
        pathway.pathwayId = undefined;
        pathway.pk = undefined;

        this.addPathway.showDialog(pathway);
    }

    public openDeleteConfirmation() {
        this.deleteConfirmation.openDialog();
    }

    public onPathwayAdded(pathway: Pathway) {
        var role = this.listOfRoles.find((r) => r.roleId === pathway.roleId);

        if (role.pathways) {
            role.pathways.push(pathway);
        } else {
            role.pathways = [pathway];
        }

        this.loadPathways(pathway);
        this.selectedPathway = pathway;
        this.pathwayDetails.reloadView();
    }

    onPathwayEdited(pathway: Pathway) {
        this.loadPathways(pathway);
    }

    public resolveEditAndDeleteDisabled(): boolean {
        if (!this.selectedPathway) {
            return true;
        }

        if (this.isAdmin) {
            return false;
        }

        let user = this.isProxyManager
            ? this.managerProxyService.proxiedManager
            : this.user.mudId;

        if (
            this.selectedPathway.owner.mudId !== user &&
            !this.selectedPathway.backupOwners.find((bo) => bo.mudId === user)
        ) {
            return true;
        }
        return false;
    }

    public selectedPathwayChanged() {
        if (this.pathwayDetails) {
            this.pathwayDetails.showOldVersions = false;
        }
    }

    private async loadAdmins(): Promise<void> {
        try {
            const admins = await this.adminService
                .getListOfAdmins()
                .toPromise();
            this.listOfAdmins = admins;
        } catch {
            this.messageService.add({
                text: 'There was an error when fetching admins.',
                timeout: 30000,
                style: 'is-danger',
            });
        }
    }

    private async loadRoles(): Promise<void> {
        try {
            const adminRoles = await this.adminService
                .getAllAdminRoles()
                .toPromise();
            this.listOfRoles = adminRoles.filter(
                (r) =>
                    adminRoles.indexOf(r) ===
                    adminRoles.findIndex((x) => x.roleId === r.roleId)
            );
            this.filteredRoles = [...adminRoles];
        } catch {
            this.messageService.add({
                text: 'There was an error when fetching trainings.',
                timeout: 30000,
                style: 'is-danger',
            });
        }
    }

    private getDefaultOwner(): UserBase {
        return this.isProxyManager
            ? {
                  mudId: this.managerProxyService.proxiedManager,
                  name: this.managerProxyService.proxiedManager,
              }
            : {
                  mudId: this.user.mudId,
                  name: this.user.displayName,
              };
    }
}
