<div class="modal is-active" *ngIf="isVisible">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">Add roles</p>
            <div class="columns">
                <div class="column is-7">
                    <div class="columns">
                        <div class="column is-5 modal-label">
                            <label class="label">Select your Business Unit*</label>
                        </div>
                        <div class="column is-7 modal-dropdown">
                            <p-dropdown [options]="listOfBusinessUnits" appendTo="body" autoWidth="false"
                                (onChange)="businessUnitSelected($event)" styleClass="p-column-filter"
                                placeholder="Select BU" [showClear]="true" optionLabel="value" [filter]="true">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-5 modal-label">
                            <label class="label">Select your Business Area/Department</label>
                        </div>
                        <div class="column is-7 modal-dropdown">
                            <p-dropdown [options]="listOfBusinessAreasFiltered" appendTo="body"
                                [disabled]="ddBADisabled" (onChange)="businessAreaSelected($event)"
                                styleClass="p-column-filter" placeholder="Select BA/Dpt" [showClear]="true"
                                optionLabel="value" [filter]="true">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-5 modal-label">
                            <label class="label">Select your Group</label>
                        </div>
                        <div class="column is-7 modal-dropdown">
                            <p-dropdown [options]="listOfGroupsFiltered" appendTo="body" [disabled]="ddGroupsDisabled"
                                (onChange)="groupSelected($event)" styleClass="p-column-filter"
                                placeholder="Select Group" [showClear]="true" optionLabel="value" [filter]="true">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="column is-5">
                    <div class="columns">
                        <div class="column is-4 modal-label">
                            <label class="label">Country</label>
                        </div>
                        <div class="column is-8 modal-dropdown">
                            <p-dropdown [options]="listOfCountries" appendTo="body" (onChange)="countrySelected($event)"
                                styleClass="p-column-filter" placeholder="Select Country" [showClear]="true"
                                optionLabel="value" [filter]="true">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-4 modal-label">
                            <label class="label">Site</label>
                        </div>
                        <div class="column is-8 modal-dropdown">
                            <p-dropdown [options]="listOfSitesFiltered" appendTo="body" [disabled]="ddSitesDisabled"
                                (onChange)="siteSelected($event)" styleClass="p-column-filter" placeholder="Select Site"
                                [showClear]="true" optionLabel="value" [filter]="true">
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <button pButton type="button" label="LOAD ROLES" [disabled]="roleSearchDisabled"
                                class="p-button-rounded p-button-outlined stdColor floatRight"
                                (click)="applyRoleFilters()"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="searchedRoles" class="caption">
                <div class="columns">
                    <div class="column is-6 short narrow">
                    </div>
                    <div class="column is-3 is-offset-3 short narrow">
                        <span class="p-input-icon-left fill">
                            <i class="pi pi-search"></i>
                            <input *ngIf="searchedRoles" class="fill" pInputText type="text" [(ngModel)]="searchInput"
                                (input)="globalSearchForAddRolesTable()" placeholder="Global Search" />
                        </span>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-2 is-offset-10 short narrow important">
                        <shared-toggle-table-filters-button (buttonToggled)="toggleAdvSearch($event)">
                        </shared-toggle-table-filters-button>
                    </div>
                </div>
            </div>
            <p-table #dt_addRoles *ngIf="searchedRoles" [value]="searchedRoles" styleClass="p-datatable-sm"
                sortField="roleName" [sortOrder]="1" dataKey="Id" [filterDelay]="0"
                [globalFilterFields]="['roleName','description']" (sortFunction)="customSort($event)"
                [customSort]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <div class="center p-mr-2">
                                <p-checkbox [binary]="true" (onChange)="selectAllRoles($event)"></p-checkbox>
                            </div>
                        </th>
                        <th pSortableColumn="roleName">Role Name<p-sortIcon field="roleName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="description">Target Audience<p-sortIcon field="description">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="appropriateFor">Role Level<p-sortIcon field="appropriateFor">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="admin">Admin<p-sortIcon field="admin"></p-sortIcon>
                        </th>
                        <th pSortableColumn="roleContentOwner">Content owner<p-sortIcon field="roleContentOwner">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="contactEmail">Contact<p-sortIcon field="contactEmail">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="lastReview">Last Review<p-sortIcon field="lastReview">
                            </p-sortIcon>
                        </th>
                    </tr>
                    <tr #advSearch [ngClass]="{'collapsedContent': showAdvSearch === false}" class="notAHeader">
                        <th></th>
                        <th>
                            <input pInputText type="text" [(ngModel)]="roleNameInput"
                                (input)="dt_addRoles.filter(roleNameInput, 'roleName', 'contains')"
                                placeholder="Search role name" class="p-column-filter">
                        </th>
                        <th>
                            <input pInputText type="text" [(ngModel)]="targetAudienceInput"
                                (input)="dt_addRoles.filter(targetAudienceInput, 'description', 'contains')"
                                placeholder="Search target audience" class="p-column-filter">
                        </th>
                        <th>
                            <p-multiSelect [options]="advSearchAppropriateFor" appendTo="body" placeholder="All"
                                (onChange)="dt_addRoles.filter($event.value, 'appropriateFor', 'in')"
                                styleClass="p-column-filter" [resetFilterOnHide]="true">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        {{option.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                        <th>
                            <p-multiSelect [options]="advSearchAdmins" appendTo="body" placeholder="All"
                                (onChange)="dt_addRoles.filter($event.value, 'admin', 'in')"
                                styleClass="p-column-filter" [resetFilterOnHide]="true" [resetFilterOnHide]="true">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        {{option.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                        <th>
                            <p-multiSelect [options]="advSearchOwners" appendTo="body" placeholder="All"
                                (onChange)="dt_addRoles.filter($event.value, 'roleContentOwner', 'in')"
                                styleClass="p-column-filter" [resetFilterOnHide]="true">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        {{option.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                        <th>
                            <input pInputText type="text" [(ngModel)]="contactInput"
                                (input)="dt_addRoles.filter(contactInput, 'contactEmail', 'contains')"
                                placeholder="Search Contact" class="p-column-filter">
                        </th>
                        <th>
                            <p-multiSelect [options]="advSearchLastReviews" appendTo="body" placeholder="All"
                                (onChange)="dt_addRoles.filter($event.value, 'lastReview', 'in')"
                                styleClass="p-column-filter" [resetFilterOnHide]="true">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        {{option.label | date: 'dd-MMM-yyyy'}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-role style="overflow: auto !important">
                    <tr [class.has-line-through]="role.delete">
                        <td>
                            <div class="center p-mr-2">
                                <p-checkbox [disabled]="role.alreadyAdded" [binary]="true"
                                    [(ngModel)]="role.isSelected"></p-checkbox>
                            </div>
                        </td>
                        <td>{{ role.roleName }}</td>
                        <td>{{ role.description }}</td>
                        <td>{{ role.appropriateFor }}</td>
                        <td>{{ role.admin }}</td>
                        <td>{{ role.roleContentOwner }}</td>
                        <td>{{ role.contactEmail }}</td>
                        <td>{{ (role.lastReview != 0 ? role.lastReview : null) | date: 'dd-MMM-yyyy' }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No roles found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <div *ngIf="searchedRoles" class="sticky-bottom">
                <div class="columns">
                    <div class="column">
                        <span class="is-pulled-right">
                            <shared-two-buttons-container confirmLabel="OK" cancelLabel="CANCEL"
                                [onConfirm]="addSelectedRoles" [onCancel]="hideModal"></shared-two-buttons-container>
                        </span>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="hideModal()"></button>
</div>