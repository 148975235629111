import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { ssoConfig } from "./sso-auth-config";
import { IAuthService } from "./iAuthService";
import { UserFullData } from '../models/userFullData';
import { DirectReport } from '../models/directReport';
import { UserViewData } from '../models/userViewData';

@Injectable()
export class SsoAuthService implements IAuthService {

  private _loadDiscoveryDocumentAndTryLogin: Promise<boolean>;
  private _refreshToken: Promise<any>;
  userValue = new BehaviorSubject<UserFullData>(this.user);
  directsMud = new BehaviorSubject<DirectReport>(this.direct);
  reportList = new BehaviorSubject<DirectReport[]>(this.reports);
  userView = new BehaviorSubject(this.userViewData);
  directsView = new BehaviorSubject(this.directsViewData);

  constructor(private readonly oAuthService: OAuthService) { }

  public isLoggedIn(adminOnly = false): boolean {
    this.refreshTokenIfNeeded();
    const exp = this.oAuthService.getAccessTokenExpiration() ? this.oAuthService.getAccessTokenExpiration() : 0;
    const isExpired = new Date(exp) < new Date();

    if (!adminOnly) {
      return !isExpired;
    }
    return !isExpired && this.user?.isAdmin;
  }

  private refreshTokenIfNeeded() {
    if (this.oAuthService.getAccessTokenExpiration()) {
      const expDate = new Date(this.oAuthService.getAccessTokenExpiration());
      const expDateMinus15Mins = new Date(expDate.getTime() - 15 * 60000);
      if (expDateMinus15Mins <= new Date() && !this._refreshToken) {
        this._refreshToken = this.oAuthService.refreshToken().finally(() => this._refreshToken = null);
      }
    }
  }

  public async initAuthenticationAndGetRedirectPath(): Promise<string> {
    await this.configureSingleSignOn();
    return this.oAuthService.state;
  }

  private async configureSingleSignOn(): Promise<void> {
    if (!this._loadDiscoveryDocumentAndTryLogin) {
      // this might be helpful when debugging
      // this.oAuthService.events.subscribe(e => console.log(e));
      this.oAuthService.configure(ssoConfig);
      this._loadDiscoveryDocumentAndTryLogin = this.oAuthService.loadDiscoveryDocumentAndTryLogin();
    }
    await this._loadDiscoveryDocumentAndTryLogin;
  }

  public async login(returnUrl?: string): Promise<void> {
    await this._loadDiscoveryDocumentAndTryLogin;
    this.oAuthService.initCodeFlow(returnUrl);
  }

  public async logout(): Promise<void> {
    await this._loadDiscoveryDocumentAndTryLogin;
    this.user = null;
    this.direct = null;
    this.reports = null;
    await this.oAuthService.revokeTokenAndLogout();
  }

  public getClaims(): object {
    return this.oAuthService.getIdentityClaims();
  }

  set user(value: UserFullData) {
    this.userValue.next(value); // this will make sure to tell every subscriber about the change.
    if (value) { sessionStorage.setItem('user', JSON.stringify(value)); }
    else { sessionStorage.removeItem('user'); }
  }

  get user(): UserFullData {
    if (sessionStorage.getItem('user')) {
      return JSON.parse(sessionStorage.getItem('user'));
    }
    else {
      return null;
    }
  }

  set userViewData(value: UserViewData) {
    this.userView.next(value); // this will make sure to tell every subscriber about the change.
    if (value) { localStorage.setItem('userViewData', JSON.stringify(value)); }
    else { localStorage.removeItem('userViewData'); }
  }

  get userViewData(): UserViewData {
    if (localStorage.getItem('userViewData')) {
      return JSON.parse(localStorage.getItem('userViewData'));
    }
    else {
      return null;
    }
  }

  set directsViewData(value: UserViewData) {
    this.userView.next(value); // this will make sure to tell every subscriber about the change.
    if (value) { localStorage.setItem('directsViewData', JSON.stringify(value)); }
    else { localStorage.removeItem('directsViewData'); }
  }

  get directsViewData(): UserViewData {
    if (localStorage.getItem('directsViewData')) {
      return JSON.parse(localStorage.getItem('directsViewData'));
    }
    else {
      return null;
    }
  }

  set direct(value: DirectReport) {
    this.directsMud.next(value);
    if (value) { sessionStorage.setItem('direct', JSON.stringify(value)); }
    else { sessionStorage.removeItem('direct'); }
  }

  get direct(): DirectReport {
    if (sessionStorage.getItem('direct')) {
      return JSON.parse(sessionStorage.getItem('direct'));
    }
    else {
      return null;
    }
  }

  set reports(value: DirectReport[]) {
    this.reportList.next(value);
    if (value) { sessionStorage.setItem('reportList', JSON.stringify(value)); }
    else { sessionStorage.removeItem('reportList'); }
  }

  get reports(): DirectReport[] {
    if (sessionStorage.getItem('reportList')) {
      const reports = JSON.parse(sessionStorage.getItem('reportList')) as DirectReport[];
      return reports.map(report => new DirectReport(report));
    }
    else {
      return null;
    }
  }
}
