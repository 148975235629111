import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { LoadingService } from './loading.service';
import { UserBase } from '../models/userBase';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { AdminRole } from '../models/adminRole';
import { MlTraining } from '../models/mlTraining';
import { SearchForTrainingRequest } from '../models/searchForTrainingRequest';
import { AdminUser } from '../models/adminUser';
import { AdminRoleAssignment } from '../models/adminRoleAssignment';
import { RoleAssignmentQueue } from '../models/roleAssignmentQueue';
import { KeyValuePair } from '../models/KeyValuePair';

@Injectable({
  providedIn: 'root',
})
export class AdminService {

  constructor(private http: HttpClient,
    private loadingService: LoadingService) { }

  getListOfAdmins(includeLeavers?: boolean): Observable<UserBase[]> {
    const endpoint = `/mygadget/getMyGadgetAdmins`;
    const params = new HttpParams().append('includeLeavers', includeLeavers === true);

    this.loadingService.show();
    return this.http.get<UserBase[]>(endpoint, { params: params }).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  getListOfContentOwners(): Observable<UserBase[]> {
    const endpoint = `/mygadget/getMyGadgetContentOwners`;

    this.loadingService.show();
    return this.http.get<UserBase[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  getAdminRoles(adminMudid?: string): Observable<AdminRole[]>
  getAdminRoles(adminMudids?: string[]): Observable<AdminRole[]>
  getAdminRoles(adminMudids?: any): Observable<AdminRole[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/adminRoles`;
    let httpParams = new HttpParams();
    if (adminMudids) {
      if (typeof adminMudids === 'string') {
        adminMudids = [adminMudids];
      }
      adminMudids.forEach(mudid => httpParams = httpParams.append('adminMudids', mudid));
    }

    return this.http.get<AdminRole[]>(endpoint, { params: httpParams }).pipe(
      tap(res => res.forEach(ad => {
        if (!ad.roleTrainings) {
          ad.roleTrainings = [];
        }
        ad.lastReviewDate = ad.lastReviewDate ? ad.lastReviewDate.split('T')[0] : undefined;
      })),
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  getAllAdminRoles(): Observable<AdminRole[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/allAdminRoles`;

    return this.http.get<AdminRole[]>(endpoint).pipe(
      tap(res => res.forEach(ad => {
        if (!ad.roleTrainings) {
          ad.roleTrainings = [];
        }
        ad.lastReviewDate = ad.lastReviewDate ? ad.lastReviewDate.split('T')[0] : undefined;
      })),
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  addRoles(roles: AdminRole[], asSuperAdmin = false): Observable<void> {
    this.loadingService.show();
    const endpoint = asSuperAdmin ? '/mygadgetsuperadmin/roles' : `/mygadget/roles`;

    return this.http.post<void>(endpoint, roles).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  updateRole(role: AdminRole, asSuperAdmin = false): Observable<AdminRole> {
    this.loadingService.show();
    const endpoint = asSuperAdmin ? `/mygadgetsuperadmin/roles/${role.roleId}` : `/mygadget/roles/${role.roleId}`;

    return this.http.put<AdminRole>(endpoint, role).pipe(
      tap(res => {
        if (!res.roleTrainings) {
          res.roleTrainings = [];
        }
        res.lastReviewDate = res.lastReviewDate ? res.lastReviewDate.split('T')[0] : undefined;
      }),
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  getMyLearningDomains(): Observable<string[]> {
    this.loadingService.show();
    const endpoint = '/mygadget/myLearningDomains';

    return this.http.get<string[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  searchForTrainings(
    request: SearchForTrainingRequest
  ): Observable<MlTraining[]> {
    this.loadingService.show();
    const endpoint = '/mygadget/searchForTrainings';

    return this.http.post<any[]>(endpoint, request).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  getTrainingByIds(ids: string[]): Observable<any> {
    this.loadingService.show();
    const endpoint = `/mygadget/bulkTrainingSearch`;

    return this.http.post<any>(endpoint, ids).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  getRolesAssignmentCount(roles: number[]): Observable<KeyValuePair[]> {
    this.loadingService.show();
    const endpoint = '/mygadget/rolesAssignmentCount';

    return this.http
      .post<KeyValuePair[]>(endpoint, roles)
      .pipe(finalize(() => this.loadingService.hide()));
  }

  getRoleAssignmentCount(roleId: number): Observable<number> {
    this.loadingService.show();
    const endpoint = `/mygadget/roleAssignmentCount/${roleId}`;

    return this.http
      .get<number>(endpoint)
      .pipe(finalize(() => this.loadingService.hide()));
  }

  deleteRole(roleId: number, asSuperAdmin = false) {
    this.loadingService.show();
    const endpoint = asSuperAdmin ? '/mygadgetsuperadmin/roles' : '/mygadget/roles';

    const roles = [roleId];

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: roles,
    };

    return this.http
      .delete(endpoint, options)
      .pipe(finalize(() => this.loadingService.hide()));
  }

  getAdminsAndSuperAdmins() {
    this.loadingService.show();
    const endpoint = '/mygadget/adminsAndSuperAdmins';

    return this.http.get<AdminUser[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  updateAdminUser(user: AdminUser): Observable<AdminUser> {
    this.loadingService.show();
    const endpoint = '/mygadget/adminUser';

    return this.http.put<AdminUser>(endpoint, user).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }


  getRoleAssignments(roleId: number): Observable<AdminRoleAssignment[]>
  getRoleAssignments(roleId: number[]): Observable<AdminRoleAssignment[]>
  getRoleAssignments(roleId: any): Observable<AdminRoleAssignment[]> {
    this.loadingService.show();
    if (typeof roleId === 'number') {
      roleId = [roleId];
    }
    const endpoint = `/mygadget/adminRoles/roleAssignments`;

    return this.http.post<AdminRoleAssignment[]>(endpoint, roleId).pipe(
      catchError(err => {
        if (err.status === 404) return of([]);
        else throw (err);
      }),
      finalize(() => this.loadingService.hide())
    );
  }

  getRoleAssignmentQueue(roleId: number): Observable<RoleAssignmentQueue[]>
  getRoleAssignmentQueue(roleId: number[]): Observable<RoleAssignmentQueue[]>
  getRoleAssignmentQueue(roleId: any): Observable<RoleAssignmentQueue[]> {
    this.loadingService.show();
    if (typeof roleId === 'number') {
      roleId = [roleId];
    }
    const endpoint = `/mygadget/adminRoles/roleAssignmentQueue`;

    return this.http.post<RoleAssignmentQueue[]>(endpoint, roleId).pipe(
      catchError(err => {
        if (err.status === 404) return of([]);
        else throw (err);
      }),
      finalize(() => this.loadingService.hide())
    );
  }

  getRolesAssignedToUser(userId: string): Observable<number[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/users/${userId}/roleAssignments`;

    return this.http.get<number[]>(endpoint).pipe(
      catchError(err => {
        if (err.status === 404) {
          return of([]);
        } else {
          throw (err);
        }
      }),
      finalize(() => this.loadingService.hide())
    );
  }

  updateRoleAssignmentQueue(roleId: number, queueItems: RoleAssignmentQueue[]): Observable<any> {
    this.loadingService.show();
    const endpoint = `/mygadget/adminRoles/${roleId}/roleAssignmentQueue`;

    return this.http.post(endpoint, queueItems).pipe(
      finalize(() => this.loadingService.hide())
    );
  }

  getManagers(): Observable<UserBase[]> {
    this.loadingService.show();
    const endpoint = '/mygadget/managers';

    return this.http.get<UserBase[]>(endpoint).pipe(
      map(managers => managers.sort((a, b) => {
        const valA = a.name ?? a.mudId;
        const valB = b.name ?? b.mudId;
        return valA.localeCompare(valB, undefined, { sensitivity: 'accent' });
      })),
      finalize(() => this.loadingService.hide())
    );
  }

  getUsersWithJobCode(jobCode: string): Observable<string[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/usersWithJobCode?jobCode=${jobCode}`;

    return this.http.get<string[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }

  unassignPathways(roleAssignments: { roleId: number, userId: string }[]) {
    this.loadingService.show();

    const endpoint = '/mygadget/admin/unassignPathways';
    return this.http.put<{ roleId: number, userId: string }>(endpoint, roleAssignments).pipe(
      finalize(() => {
        this.loadingService.hide();
      })
    );
  }
}
