<ng-container *ngIf="user">
    <div class="columns">
        <div class="column">
            <p-table #dt [value]="trainings" styleClass="p-datatable-sm" sortField="complianceSetting" [sortOrder]="1"
                dataKey="roleTrainingId" [expandedRowKeys]="expandedRows"
                [globalFilterFields]="['roleName','myLearningCode','title','items']" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10,25,50]" (sortFunction)="customSort($event)" [customSort]="true">
                <ng-template pTemplate="caption" class="narrow">
                    <div class="columns">
                        <div class="column is-6 short narrow">
                            <div class="info-label">View trainings associated to selected myGadget roles and
                                select/deselect
                                training.</div>
                        </div>
                        <div class="column is-3 is-offset-3 short narrow">
                            <span class="p-input-icon-left fill">
                                <i class="pi pi-search"></i>
                                <input class="fill" pInputText type="text" [(ngModel)]="globalSearchInput"
                                    (input)="dt.filterGlobal(globalSearchInput, 'customFilter')"
                                    placeholder="Global Search" />
                            </span>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-full short narrow important">
                            <button pButton type="button no-padding"
                                [label]="areRowsExpanded ? 'Collapse all' : 'Expand all'"
                                [icon]="areRowsExpanded ? 'pi pi-minus' : 'pi pi-plus'" iconPos="left"
                                class="p-button-text no-padding stdColor" (click)="toggleAllRows()"></button>
                            <div class="p-field-checkbox" style="display: inline; margin-left: 0.5rem;">
                                <p-checkbox (onChange)="dt.filter($event.checked, 'isSelected', 'gte')" [binary]="true"
                                    inputId="showOnlySelected"></p-checkbox>
                                <label for="showOnlySelected" style="font-weight: normal;">Show only selected</label>
                            </div>
                            <shared-excel-export-button [exportAction]="excelExport"></shared-excel-export-button>
                            <shared-toggle-table-filters-button (buttonToggled)="toggleAdvSearch($event)">
                            </shared-toggle-table-filters-button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th colspan="1" style="width: 3rem" joyrideStep="learner-training.table-checkbox"
                            stepPosition="right" title="Training Selection" [stepContent]="stepContent"
                            [stepContentParams]="{text:'Select any Task-Based training that is relevant for you.'}">
                            <div #narrowTooltipContainer class="center tooltip-container tooltip-narrow">
                                <i pTooltip="Select training to add it to the 'Manage myLearning Plan' tab."
                                    tooltipPosition="top" class="pi pi-info-circle"
                                    [appendTo]="narrowTooltipContainer"></i>
                            </div>
                        </th>
                        <th style="width: 3rem"></th>
                        <th colspan="2" pSortableColumn="roleName">Role
                            <p-sortIcon field="roleName"></p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="myLearningCode">myL Code
                            <p-sortIcon field="myLearningCode">
                            </p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="complianceSetting" [joyrideStep]="tourSteps[0]"
                            title="Compliance Setting" stepPosition="top" [prevTemplate]="prevButton"
                            [nextTemplate]="nextButton" [doneTemplate]="doneButton" [stepContent]="stepContent"
                            [stepContentParams]="{text:'Notice that role training is already selected for you.'}">
                            <div #wideTooltipContainer class="tooltip-container">
                                <i pTooltip="Role Training: Required training for learners with the role selected.
                                                        Task-based: Expected training for just-in-time training assignment."
                                    tooltipPosition="top" class="pi pi-info-circle"
                                    [appendTo]="wideTooltipContainer"></i>
                            </div>
                            Compliance Setting
                            <p-sortIcon field="complianceSetting"></p-sortIcon>
                        </th>
                        <th colspan="3" pSortableColumn="title">Title
                            <p-sortIcon field="title"></p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="selectedBy">Selected by<p-sortIcon field="selectedBy">
                            </p-sortIcon>
                        </th>
                    </tr>
                    <tr #advSearch [ngClass]="{'collapsedContent': showAdvSearch === false}" class="notAHeader">
                        <th colspan="2"></th>
                        <th colspan="2">
                            <input pInputText type="text" (input)="dt.filter(roleInput, 'roleName', 'contains')"
                                [(ngModel)]="roleInput" placeholder="Search Role" class="p-column-filter">
                        </th>
                        <th colspan="2">
                            <input pInputText type="text" [(ngModel)]="myLCodeInput"
                                (input)="dt.filter(myLCodeInput, 'myLearningCode', 'contains')"
                                placeholder="Search myL Code" class="p-column-filter">
                        </th>
                        <th colspan="2">
                            <p-dropdown [options]="listOfComplianceSettings" appendTo="body"
                                (onChange)="dt.filter($event.value, 'complianceSetting', 'equals')"
                                styleClass="p-column-filter" placeholder="Compliance Setting" [showClear]="true">
                                <ng-template let-option pTemplate="item">
                                    {{option.label}}
                                </ng-template>
                            </p-dropdown>
                        </th>
                        <th colspan="3">
                            <input pInputText type="text" (input)="dt.filter(titleInput, 'title', 'contains')"
                                [(ngModel)]="titleInput" placeholder="Search Title" class="p-column-filter">
                        </th>
                        <th>
                            <p-multiSelect [options]="listOfAssignedBy" appendTo="body" placeholder="All"
                                (onChange)="dt.filter($event.value, 'displaySelectedBy', 'in')"
                                styleClass="p-column-filter" [resetFilterOnHide]="true">
                                <ng-template let-option pTemplate="item">
                                    <div class="p-multiselect-representative-option">
                                        {{option.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-training let-expanded="expanded">
                    <tr [class.has-line-through]="training.status==='RoleDeleted'"
                        [class.is-disabled]="training.status==='NotAssignable'" [showDelay]="500"
                        pTooltip="{{ training.status==='NotAssignable' ? 'This training is associated to an assigned curriculum (' + training.blockingCurriculumCode + ') and can’t be assigned until/unless the curriculum is unassigned. Note: If this curriculum is assigned directly in myLearning, it will need to be unassigned in myLearning and the change will be visible to myGadget a day later.' : '' }}"
                        tooltipPosition="top">
                        <td colspan="1">
                            <div class="center">
                                <ng-container *ngIf="training.status !== 'RoleDeleted' && training.status !== 'NotAssignable'">
                                    <p-checkbox [binary]="true" [(ngModel)]="training.isSelected"
                                    [disabled]="training.complianceSetting==='Role Training'
                                        || training.status==='NotAssignable'
                                        || (training.selectedBy && training.selectedBy !== user.mudId && !manager)"
                                        ></p-checkbox>
                                </ng-container>
                                <a *ngIf="training.status === 'NotAssignable'"
                                    (click)="checkIfItemIsAssignable(training)">
                                    <span class="icon"><i class="pi pi-replay"></i></span>
                                </a>
                            </div>
                        </td>
                        <td>
                            <button type="button" pButton pRipple [pRowToggler]="training"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                *ngIf="training.status!=='RoleDeleted'">
                            </button>
                        </td>
                        <td colspan="2">{{ training.roleName }}</td>
                        <td colspan="2">{{ training.myLearningCode }}<span
                                *ngIf="training.trainingType === 'Item' && utilService.isNewTraining(training.myLearningRevisionDate)"
                                class='pi pi-exclamation-circle'
                                pTooltip="This training has been revised in the last 90 days."></span>
                            <div class="is-pulled-right" *ngIf="training.trainingType === 'Curriculum'">
                                <a (click)="openModal(training.myLearningCode)">
                                    <span class="icon"><i class="pi pi-file"></i></span>
                                </a>
                            </div>
                        </td>
                        <td colspan="2">{{ training.complianceSetting }}</td>
                        <td colspan="3">{{ training.title }}</td>
                        <td colspan="2">{{ resolveSelectedBy(training) }}
                            <ng-container
                                *ngIf="manager && training.selectedBy && manager.mudId !== training.selectedBy && training.isSelected">
                                <div class="is-pulled-right">
                                    <a *ngIf="!training.managerMudIdForOverride else undo"
                                        (click)="toggleManagerOverride(training)">
                                        <span class="icon tooltip-container tooltip-super-narrow" #overrideContainer>
                                            <i pTooltip="Over-Ride: change the selection/assignment name to mine."
                                                class="pi pi-user" tooltipPosition="top"
                                                [appendTo]="overrideContainer"></i>
                                        </span>
                                    </a>
                                    <ng-template #undo>
                                        <a (click)="toggleManagerOverride(training)">
                                            <span class="icon"><i class="pi pi-undo"></i></span>
                                        </a>
                                    </ng-template>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No trainings found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-training>
                    <tr class="additionalInfo" [class.is-disabled]="training.status==='NoCatalogAccess'">
                        <td colspan="2"></td>
                        <td colspan="2" class="CellWithComment is-offset-2">
                            <strong>myL Topic Area</strong><br>
                            <div>{{ training.topicArea }}</div>
                            <div *ngIf="!training.topicArea">N/A</div>
                        </td>
                        <td colspan="9" class="CellWithComment">
                            <strong>myL Description</strong><br>
                            <div>{{training.description}}</div>
                            <div *ngIf="!training.description">N/A</div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <div class="sticky-bottom">
                <button pButton type="button" label="CONFIRM SELECTION & MANAGE MYLEARNING PLAN"
                    class="p-button-rounded stdColor floatRight mt-4" (click)="openConfirmationPopUp()"
                    [joyrideStep]="tourSteps[2]" title="Confirm" stepPosition="top" [stepContent]="stepContent"
                    [stepContentParams]="{text: 'Confirm selections in this view.'}"></button>
            </div>
        </div>
    </div>
    <shared-curriculum-details #cutrriculumDetails></shared-curriculum-details>
    <shared-confirmation-dialog #confirmationDialog></shared-confirmation-dialog>
</ng-container>