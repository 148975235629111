import { Injectable } from '@angular/core';
import { Message } from '../models/message';

@Injectable({ providedIn: 'root' })
export class MessageService {

    messages: Message[] = [];

    add(message: Message) {
        this.messages.push(message);

        setTimeout(() => {
            this.messages.splice(this.messages.indexOf(message), 1);
        }, message.timeout);
    }

    clear() {
        this.messages = [];
    }

    dismiss(message: Message) {
        this.messages.splice(this.messages.indexOf(message), 1);
    }
}