<div class="columns">
    <div class="column is-one-third">
        <p-dropdown [options]="filteredRoles" placeholder="Role Name" appendTo="body" styleClass="p-column-filter"
            [(ngModel)]="selectedRole" optionLabel="roleName" optionValue="roleId" [filter]="true" filterBy="roleName"
            [showClear]="true" [resetFilterOnHide]="true" (onChange)="loadPathways(null)">
        </p-dropdown>
        <div class="p-mt-3 p-mb-2">
            <div class="row" *ngIf="isAdmin && !isProxyManager">
                <p-radioButton class="p-mr-3" name="rolesOption" label="My Roles" value="JustMine"
                    [(ngModel)]="rolesOption" (onClick)="runFilters()">
                </p-radioButton>
                <p-radioButton class="p-mr-3" name="rolesOption" label="All Roles" value="AllRoles"
                    [(ngModel)]="rolesOption" (onClick)="runFilters()">
                </p-radioButton>
            </div>
            <div class="row">
                <p-checkbox class="p-mt-3" [(ngModel)]="rolesWithPathways" [binary]="true"
                    label="Show only Roles with Pathways" (onChange)="runFilters()">
                </p-checkbox>
            </div>
        </div>
    </div>
    <div *ngIf="rolesOption === 'AllRoles'" class="column is-one-fifth">
        <p-multiSelect #adminMultiSelect [options]="listOfAdmins" placeholder="Admin/Back-up Admin" appendTo="body"
            styleClass="p-column-filter" optionValue="mudId" optionLabel="name" [(ngModel)]="adminsSelected"
            (onChange)="runFilters()" [resetFilterOnHide]="true">
        </p-multiSelect>
    </div>
    <div class="column is-half">
        <button pButton type="button" label="ADD" class="p-button-rounded p-button-outlined stdColor p-mr-1"
            style="width: 22%;" (click)="add()" [disabled]="!selectedRole"></button>
        <button pButton type="button" label="EDIT" class="p-button-rounded p-button-outlined stdColor p-mr-1"
            style="width: 22%;" (click)="edit()" [disabled]="resolveEditAndDeleteDisabled()"></button>
        <button pButton type="button" label="COPY" class="p-button-rounded p-button-outlined stdColor p-mr-1"
            style="width: 22%;" (click)="copy()" [disabled]="!selectedPathway"></button>
        <button pButton type="button" label="DELETE" class="p-button-rounded p-button-outlined stdColor"
            style="width: 22%;" (click)="openDeleteConfirmation()" [disabled]="resolveEditAndDeleteDisabled()"></button>
    </div>
</div>
<div class="columns">
    <div class="column is-3 report-list">
        <p>All Pathways for this Role</p>
        <p-listbox [listStyle]="{'max-height':'700px'}" [options]="pathways" [filter]="true" optionLabel="name"
            [(ngModel)]="selectedPathway" (onChange)="selectedPathwayChanged()">
        </p-listbox>
    </div>
    <div class="column is-9">
        <shared-pathway-details #pathwayDetails *ngIf="selectedPathway" [pathway]="selectedPathway">
        </shared-pathway-details>
    </div>
</div>
<admin-add-pathway (pathwayAdded)="onPathwayAdded($event)" [isAdmin]="isAdmin" [listOfOwners]="listOfOwners"
    #addPathway>
</admin-add-pathway>
<shared-confirmation-dialog #deleteConfirmation text="Are you sure you want to delete this pathway?" noText="CANCEL"
    yesText="CONFIRM" (confirmationDialogAccept)="delete()">
</shared-confirmation-dialog>
<admin-edit-pathway (pathwayEdited)="onPathwayEdited($event)" #editPathway [isAdmin]="isAdmin"
    [listOfOwners]="listOfOwners">
</admin-edit-pathway>