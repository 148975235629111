import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { MessageService } from '../services/message.service';
import { LearnerService } from '../services/learner.service';
import { IAuthService } from '../auth/iAuthService';
import { forkJoin, Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { RoleAssignment } from '../models/roleAssignment';
import { AssignRole } from '../models/assignRole';
import { CopyRoleAssignemtComponent } from '../copy-role-assignemt/copy-role-assignemt.component';
import { HasTour } from '../has-tour/has-tour';
import { JoyrideWrapperService } from '../services/joyride-wrapper.service';
import { UserFullData } from '../models/userFullData';
import { DirectReport } from '../models/directReport';
import { SelectedByRelation } from '../models/selectedByRelation';
import { UserService } from '../services/user.service';
import { ManageComplianceTrainingView } from '../models/enums/manageComplianceTrainingView';
import { RoleSelectionComponent } from '../role-selection/role-selection.component';
import { SearchedRole } from '../models/searchedRole';
import { ReadOnlyRoleMetadataComponent } from '../shared/read-only-role-metadata/read-only-role-metadata.component';
import { RoleService } from '../services/role.service';
import { AdminRole } from '../models/adminRole';
import { PathwaySelectionComponent } from '../shared/pathway-selection/pathway-selection.component';
import { Pathway } from '../models/pathway';
import { DisplayPathwayComponent } from '../shared/display-pathway/display-pathway.component';
import { PathwayService } from '../services/pathway.service';
import { UtilService } from '../services/util.service';

@Component({
    selector: 'app-learner-roles',
    templateUrl: './learner-roles.component.html',
    styleUrls: ['./learner-roles.component.css'],
})
export class LearnerRolesComponent
    extends HasTour
    implements OnInit, OnDestroy
{
    warningMessage: string = `Are you sure you want to confirm and continue?`;
    warningMessageSmall: string =
        "IMPORTANT NOTE: Managing compliance training is a 3-step process including 1) Select Role, 2) Select Training, and 3) Manage myLearning Plan. If you confirm changes in this step, they are confirmed ONLY for this step. You are required to “Confirm” through step 3 for those changes to be cascaded into the learner's myLearning Plan.";

    user: UserFullData | DirectReport;
    manager: UserFullData;
    roles: RoleAssignment[];
    roleIdForOverride: string;
    modal: boolean = false;
    copyRolesModal: boolean = false;
    searchedRoles: any;
    isManager: boolean;
    isManagerProxy: boolean = false;
    showRoleMetadata: boolean;
    selectedRole: AdminRole;
    roleToRemovePathwayFrom: RoleAssignment;

    private viewGeneratedOn: Date;

    private subData: Subscription;
    private subUser: Subscription;
    private subManager: Subscription;

    @ViewChild('dt') table: Table;
    @ViewChild('roleSearchDialog') roleSearchDialog: RoleSelectionComponent;
    @ViewChild('confirmationDialog')
    confirmationDialog: ConfirmationDialogComponent;
    @ViewChild('copyRoleDialog') copyRoleDialog: CopyRoleAssignemtComponent;
    @ViewChild('roleMetadata') roleMetadata: ReadOnlyRoleMetadataComponent;
    @ViewChild('pathwaySelection') pathwaySelection: PathwaySelectionComponent;
    @ViewChild('pathwayDeletionDialog')
    pathwayDeletionDialog: ConfirmationDialogComponent;
    @ViewChild('displayPathwayModal')
    displayPathwayModal: DisplayPathwayComponent;

    public tourSteps = ['learner-roles.add-roles', 'learner-roles.confirm'];
    protected tourLocalStorageKey = 'LearnerRoles';

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly messageService: MessageService,
        private readonly learnerService: LearnerService,
        private readonly authService: IAuthService,
        private readonly userService: UserService,
        private readonly roleService: RoleService,
        private readonly pathwayService: PathwayService,
        private readonly utilService: UtilService,
        joyrideService: JoyrideWrapperService
    ) {
        super(joyrideService, router);
    }

    private getViewData() {
        if (this.user?.mudId) {
            this.userService
                .getUserViewData(this.user.mudId)
                .subscribe((res) => {
                    this.authService.userViewData = res;
                });

            this.learnerService.getLearnerRoles(this.user.mudId).subscribe({
                next: (result) => {
                    this.roles = result.roles.filter(
                        (r) => r.status !== 'Deleted'
                    );
                    this.viewGeneratedOn = result.generatedOn;
                },
                error: () => {
                    this.messageService.add({
                        text: 'There was an error when retrieving user data.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                },
            });
        }
    }

    ngOnInit() {
        this.subData = this.route.data.subscribe((data) => {
            this.isManager = data.manager;
            this.isManagerProxy = data.managerProxy;
            if (this.isManager) {
                this.subUser = this.authService.directsMud.subscribe(
                    (result) => {
                        this.user = result;
                        this.getViewData();
                    }
                );
                this.subManager = this.authService.userValue.subscribe(
                    (result) => {
                        this.manager = result;
                    }
                );
            } else {
                this.subUser = this.authService.userValue.subscribe(
                    (result) => {
                        this.user = result;
                        this.getViewData();
                    }
                );
            }
        });

        return super.ngOnInit();
    }

    ngOnDestroy() {
        this.subData.unsubscribe();
        this.subUser.unsubscribe();
        if (this.subManager) {
            this.subManager.unsubscribe();
        }
        this.confirmationDialog.confirmationDialogAccept.unsubscribe();
        this.confirmationDialog.confirmationDialogReject.unsubscribe();

        return super.ngOnDestroy();
    }

    checkChangesAndConfirm() {
        const activated = this.roles
            .filter(
                (x) =>
                    x.isActive == true &&
                    x.isActiveOriginal == false &&
                    !x.delete &&
                    !x.isNew
            )
            .map((y) => ({ userId: y.userId, roleId: y.roleId }));
        const deactivated = this.roles
            .filter(
                (x) =>
                    x.isActive == false &&
                    x.isActiveOriginal == true &&
                    !x.delete &&
                    !x.isNew
            )
            .map((y) => ({ userId: y.userId, roleId: y.roleId }));
        const added = this.roles
            .filter((x) => x.isNew == true && !x.delete)
            .map((y) => ({ userId: y.userId, roleId: y.roleId }));
        const deleted = this.roles
            .filter((x) => x.delete && !x.isNew)
            .map((y) => ({ userid: y.userId, roleId: y.roleId }));
        const overridden = this.roles
            .filter(
                (x) =>
                    x.managerMudIdForOverride &&
                    x.isActive == x.isActiveOriginal &&
                    !x.delete &&
                    !x.isNew
            )
            .map((y) => ({
                userid: y.userId,
                roleId: y.roleId,
                managerid: y.managerMudIdForOverride,
            }));
        const pathwayRemoved = this.roles
            .filter((x) => x.isActive && x.pathwayPendingRemoval)
            .map((y) => ({ userId: y.userId, roleId: y.roleId }));

        if (
            activated.length == 0 &&
            deactivated.length == 0 &&
            added.length == 0 &&
            deleted.length == 0 &&
            overridden.length == 0 &&
            pathwayRemoved.length == 0
        ) {
            this.messageService.add({
                text: 'No changes detected.',
                timeout: 5000,
                style: 'is-info',
            });
            return;
        }

        this.learnerService
            .verifyViewStatus(
                this.user.mudId,
                this.viewGeneratedOn,
                ManageComplianceTrainingView.SelectRole
            )
            .subscribe((viewUpToDate) => {
                if (!viewUpToDate) {
                    this.confirmationDialog.showYes = false;
                    this.confirmationDialog.title =
                        'Manage Compliance Training: Refresh Needed';
                    this.confirmationDialog.text = `The myGadget Roles selection has synchronously been changed by your Manager/Direct Report or an Admin. The 'Add Roles' view will be refreshed. You will see updated selections and be able to make any additional changes.`;
                    this.confirmationDialog.noText = 'OK';
                    this.confirmationDialog.confirmationDialogReject.subscribe(
                        () => this.ngOnInit()
                    );
                    this.confirmationDialog.openDialog();
                } else {
                    const additionalPrompt =
                        this.displayNotAssignedPathwayPrompt()
                            ? `You are selecting to assign a myGadget Role <strong>without selecting a Pathway</strong> for that assignment.
                        (Note that Pathways can <strong>ONLY</strong> be assigned when a myGadget Role is assigned.)<br/>`
                            : '';

                    this.confirmationDialog.showYes = true;
                    this.confirmationDialog.title =
                        'MANAGE COMPLIANCE TRAINING: 3 STEP PROCESS';
                    this.confirmationDialog.text =
                        additionalPrompt + this.warningMessage;
                    this.confirmationDialog.warning = this.warningMessageSmall;
                    this.confirmationDialog.noText = 'CANCEL';
                    this.confirmationDialog.yesText = 'CONFIRM';
                    this.confirmationDialog.confirmationDialogAccept.subscribe(
                        () => this.confirmRoles()
                    );
                    this.confirmationDialog.openDialog();
                }
            });
    }

    confirmRoles() {
        const selectedByRelation = this.resolveSelectedByRelation();

        const activated = this.roles
            .filter(
                (x) =>
                    x.isActive == true &&
                    x.isActiveOriginal == false &&
                    !x.delete &&
                    !x.isNew
            )
            .map((y) => ({
                userId: y.userId,
                roleId: y.roleId,
                selectedByRelation,
                pathwayPk: y.pathwayPk,
            }));
        const deactivated = this.roles
            .filter(
                (x) =>
                    x.isActive == false &&
                    x.isActiveOriginal == true &&
                    !x.delete &&
                    !x.isNew
            )
            .map((y) => ({ userId: y.userId, roleId: y.roleId }));
        const added: AssignRole[] = this.roles
            .filter((x) => x.isNew == true && !x.delete)
            .map<AssignRole>((y) => ({
                userId: y.userId,
                roleId: y.roleId,
                selectedByRelation,
                pathwayPk: y.pathwayPk,
            }));
        const deleted = this.roles
            .filter((x) => x.delete && !x.isNew)
            .map((y) => ({ userid: y.userId, roleId: y.roleId }));
        const overridden = this.roles
            .filter(
                (x) =>
                    x.managerMudIdForOverride &&
                    x.isActive == x.isActiveOriginal &&
                    !x.delete &&
                    !x.isNew
            )
            .map((y) => ({
                userid: y.userId,
                roleId: y.roleId,
                managerid: y.managerMudIdForOverride,
            }));
        const pathwayRemoved = this.roles
            .filter((x) => x.isActive && x.pathwayPendingRemoval)
            .map((y) => ({ userId: y.userId, roleId: y.roleId }));

        const observables: { [key: string]: any } = {};
        const observableKeys: string[] = [];

        if (activated.length > 0) {
            observables['activated'] = this.learnerService.activateRoles(
                activated,
                this.isManagerProxy
            );
            observableKeys.push('activated');
        }

        if (deactivated.length > 0) {
            observables['deactivated'] = this.learnerService.deactivateRoles(
                deactivated,
                this.isManagerProxy
            );
            observableKeys.push('deactivated');
        }

        if (added.length > 0) {
            observables['added'] = this.learnerService.addRoles(
                added,
                true,
                this.isManagerProxy
            );
            observableKeys.push('added');
        }

        if (deleted.length > 0) {
            observables['deleted'] = this.learnerService.deleteRoles(
                deleted,
                this.isManagerProxy
            );
            observableKeys.push('deleted');
        }

        if (pathwayRemoved.length > 0) {
            observables['pathwayRemoved'] =
                this.learnerService.unassignPathways(
                    pathwayRemoved,
                    this.resolveRelationship()
                );
            observableKeys.push('pathwayRemoved');
        }

        const isDelegatedManager =
            this.resolveSelectedByRelation() === 'DelegatedManager';

        if (overridden.length > 0) {
            observables['overridden'] = this.learnerService.managerRoleOverride(
                overridden,
                isDelegatedManager,
                this.isManagerProxy
            );
            observableKeys.push('overridden');
        }

        if (observableKeys.length > 0) {
            forkJoin(observables).subscribe({
                next: () => {
                    this.router.navigate(['training'], {
                        relativeTo: this.route.parent,
                    });
                    this.messageService.add({
                        text: 'Roles successfully saved.',
                        timeout: 10000,
                        style: 'is-success',
                    });
                },
                error: () => {
                    this.messageService.add({
                        text: 'There was an error when retrieving user data.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                },
            });
        }
    }

    private resolveSelectedByRelation(): SelectedByRelation {
        if (!this.isManager) {
            return 'User';
        }
        if (this.isManagerProxy) {
            return 'Admin';
        }
        if (
            (this.user as DirectReport).delegatedTo ===
            this.authService.user.mudId
        ) {
            return 'DelegatedManager';
        }
        return 'Manager';
    }

    private resolveRelationship(): 'User' | 'Manager' | 'ProxyManager' {
        if (!this.isManager) {
            return 'User';
        }
        if (this.isManagerProxy) {
            return 'ProxyManager';
        }
        return 'Manager';
    }

    toggleDeleteRole(role) {
        role.delete = !role.delete;
    }

    toggleManagerOverride(role) {
        if (role.managerMudIdForOverride) {
            role.managerMudIdForOverride = null;
        } else {
            role.managerMudIdForOverride = this.manager.mudId;
        }
    }

    onRolesSelected(roles: SearchedRole[]) {
        const selectedRoles: RoleAssignment[] = roles.map(
            (y) =>
                ({
                    userId: this.user.mudId,
                    roleId: y.id,
                    roleName: y.roleName,
                    description: y.description,
                    assignedBy: `${
                        this.isManager ? this.manager.mudId : this.user.mudId
                    }`,
                    isActive: true,
                    isNew: true,
                    hasPathways: y.hasPathways,
                } as unknown as RoleAssignment)
        );

        this.roles.push(...selectedRoles);
        //make sure the view is refreshed when the roles change
        this.roles = this.roles.slice();
    }

    openCopyRolesModal() {
        this.copyRoleDialog.openDialog();
    }

    openModal() {
        this.roleSearchDialog.showModal();
    }

    openRoleMetadata(roleId: number) {
        this.roleService.getRoleMetadata(roleId).subscribe((role) => {
            this.selectedRole = role;
            this.showRoleMetadata = true;
        });
    }

    selectPathway(roleId: number) {
        this.roleService.getRoleMetadata(roleId).subscribe((role) => {
            this.selectedRole = role;
            this.pathwaySelection.openModal(role);
        });
    }

    closeRoleMetadata() {
        this.showRoleMetadata = false;
    }

    showPathwayDetails(pathwayPk: string) {
        this.pathwayService.getPathway(pathwayPk).subscribe((res) => {
            this.displayPathwayModal.showDialog(res);
        });
    }

    onPathwaySelected(res: { pathway: Pathway; roleId: number }) {
        const role = this.roles.find((r) => r.roleId === res.roleId);
        role.pathwayPk = res.pathway.pk;
        role.pathwayName = res.pathway.name;
        role.pathwayPendingRemoval = false;
    }

    displaySelectPathwayIcon(role: RoleAssignment): boolean {
        return (
            (role.isNew || (role.isActive && !role.isActiveOriginal)) &&
            role.hasPathways
        );
    }

    removePathway(role: RoleAssignment) {
        this.roleToRemovePathwayFrom = role;
        this.pathwayDeletionDialog.openDialog();
    }

    onPathwayRemoved() {
        this.roleToRemovePathwayFrom.pathwayPk = undefined;
        this.roleToRemovePathwayFrom.pathwayName = undefined;
        if (!this.roleToRemovePathwayFrom.isNew) {
            this.roleToRemovePathwayFrom.pathwayPendingRemoval = true;
        }
    }

    pathwayRemovalEnabled(role: RoleAssignment): boolean {
        return role.selectedByRelation === 'User' || this.isManager;
    }

    private displayNotAssignedPathwayPrompt = (): boolean =>
        this.roles.some(
            (r) => this.displaySelectPathwayIcon(r) && !r.pathwayPk
        );
}
