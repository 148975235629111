<div class="modal is-active" *ngIf="display">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box" *ngIf="step==='learner'">
            <p class="menu-label">Deferral Request</p>
            <div class="columns">
                <div class="column is-full">
                    <p>In requesting a Deferral for this training item,
                        you are confirming that training completion is not needed at this time and can be deferred for 3
                        months.
                        {{ request.trainingAssignedBy === 'Manager' || request.complianceSetting === 'Role Training'
                        ? 'An email will be sent to your manager to review your deferral request for this item. If
                        granted, this item will be deferred by 3 months in myLearning and your due date will be
                        updated.'
                        : ''}}
                        Note that deferrals are processed at night. Changes will not appear in myLearning until
                        tomorrow.
                    </p>
                </div>
            </div>
            <div class="columns">
                <div class="column is-10">
                    <button pButton type="button" class="p-button-rounded p-button-outlined stdColor"
                        label="{{request.trainingAssignedBy === 'Manager' || request.complianceSetting === 'Role Training' ? 'Send Request to Manager': 'Send Request'}}"
                        (click)="sendRequest()"></button>
                </div>
                <div class="column is-2">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Cancel"
                        (click)="closeDialog()"></button>
                </div>
            </div>
        </div>
        <div class="box" *ngIf="step==='manager'">
            <p class="menu-label">Deferral of Training</p>
            <div class="columns">
                <div class="column is-full">
                    <p style="white-space:pre-wrap">
                        In granting a deferral of training in myLearning, you are confirming that training
                        completion is not needed at this time and can be deferred for 3 months.
                        Please note that if this item is assigned more than once (e.g., via multiple curricula),
                        it will be deferred for every instance of this item assignment in myLearning.
                        Note that deferrals are processed at night. Changes will not appear in myLearning until
                        tomorrow.
                        <br><br>
                        <b>Item:</b> {{request.itemTitle}} ({{request.myLearningCode}})
                        <br>
                        <b>Learner:</b> {{request.learnerName}}
                        <br><br>
                        {{getMultipleCurriculaText()}}
                    </p>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <button *ngIf="request.requestStatus==='Requested'" pButton type="button"
                        class="p-button-rounded p-button-outlined stdColor" label="Deny the Request"
                        (click)="sendReject()"></button>
                </div>
                <div class="column is-2 is-offset-4">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Approve"
                        (click)="sendGrantDeferral()"></button>
                </div>
                <div class="column is-2">
                    <button pButton type="button" class="p-button-rounded p-button-outlined stdColor" label="Cancel"
                        (click)="closeDialog()"></button>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeDialog()"></button>
</div>