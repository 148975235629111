<div class="container is-fullhd menu">
    <div class="columns is-gapless">
        <div class="column">
            <a class="tab" routerLink="user-interface-controls" routerLinkActive="is-active">USER INTERFACE CONTROLS</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="edit-roles" routerLinkActive="is-active">EDIT OTHER'S ROLES</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="administrators" routerLinkActive="is-active">ADMINISTRATORS</a>
        </div>
    </div>
</div>
<div class="container is-fullhd">
    <router-outlet></router-outlet>
</div>