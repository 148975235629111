import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ManagerProxyService {

  private routeIsManagerProxy: boolean = false;
  private proxiedManagerMudid: string = null;
  public get proxiedManager(): string {
    return this.routeIsManagerProxy ? this.proxiedManagerMudid : null;
  }

  constructor(

    router: Router

  ) {

    router.events.subscribe({

      next: event => {

        if (event instanceof NavigationEnd) {

          this.routeIsManagerProxy = event.urlAfterRedirects.includes('manager-proxy');

        }

      }

    });

  }

  public setManagerProxy(mudid: string): void {
    if (mudid) {
      this.proxiedManagerMudid = mudid;
    }
    else {
      this.resetManagerProxy();
    }
  }

  public resetManagerProxy(): void {
    this.proxiedManagerMudid = null;
  }
}
