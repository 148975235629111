import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IAuthService } from '../auth/iAuthService';
import { DirectReport } from '../models/directReport';
import { AssignRole } from '../models/assignRole';
import { RoleAssignment } from '../models/roleAssignment';
import { LearnerService } from '../services/learner.service';
import { MessageService } from '../services/message.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { SelectedByRelation } from '../models/selectedByRelation';

@Component({
    selector: 'copy-role-assignemt',
    templateUrl: './copy-role-assignemt.component.html',
    styleUrls: ['./copy-role-assignemt.component.css'],
})
export class CopyRoleAssignemtComponent implements OnInit, OnDestroy {
    constructor(
        public authService: IAuthService,
        private learnerService: LearnerService,
        private messageService: MessageService
    ) {}

    @Input() userName: string;
    @Input() userId: string;
    @Input() userRoles: RoleAssignment[];
    @Input() isManager: boolean;
    @Input() isManagerProxy: boolean;

    @ViewChild('warningDialog') warningDialog: ConfirmationDialogComponent;

    listOfDirects: DirectReport[];
    private subReports: any;

    display: boolean;
    selectedRoles: RoleAssignment[] = [];
    selectedDirects: DirectReport[] = [];

    ngOnInit(): void {
        this.subReports = this.authService.reportList.subscribe((result) => {
            this.listOfDirects = [...result];
            const index = this.listOfDirects
                .map((d) => d.userId)
                .indexOf(this.userId);
            if (index > -1) this.listOfDirects.splice(index, 1);
        });
    }

    ngOnDestroy() {
        this.subReports.unsubscribe();
    }

    closeDialog() {
        this.display = false;
    }

    openDialog() {
        if (this.isManager) this.display = true;
    }

    assignRoles() {
        const roleAssignments: AssignRole[] = [];
        const selectedByRelation = this.resolveSelectedByRelation();

        this.selectedDirects.forEach((direct) => {
            this.selectedRoles.forEach((role) => {
                roleAssignments.push({
                    userId: direct.userId,
                    roleId: role.roleId,
                    selectedByRelation: selectedByRelation,
                });
            });
        });
        this.learnerService
            .addRoles(roleAssignments, false, this.isManagerProxy)
            .subscribe({
                next: () => this.closeDialog(),
                error: () =>
                    this.messageService.add({
                        text: 'There was an error when assigning roles',
                        timeout: 30000,
                        style: 'is-danger',
                    }),
            });
    }

    showWarning() {
        this.warningDialog.showDialog = true;
    }

    private resolveSelectedByRelation(): SelectedByRelation {
        if (this.isManagerProxy) {
            return 'Admin';
        }
        return 'Manager';
    }
}
