<ng-container>
    <div class="columns">
        <div class="column is-4">
            <div class="control p-mb-2">
                <p><strong>Pathway Name</strong></p>
                <input title="Pathway Name" type="text" pInputText [(ngModel)]="pathway.name" [style]="{'width':'100%'}"
                    [class.ng-invalid]="isValidated && !pathway.name" maxlength="100">
                <small>Character limit 100.</small>
                <p *ngIf="isValidated && !pathway.name" class="help is-danger">Pathway name is required.</p>
            </div>
            <div class="control">
                <p><strong>Pathway Version</strong></p>
                <p>{{pathway.version}}</p>
            </div>
            <div class="control">
                <p><strong>Role Name</strong></p>
                <p>{{pathway.roleName}}</p>
            </div>
        </div>
        <div class="column is-4">
            <div class="control p-mb-4">
                <p><strong>Owner</strong></p>
                <p-dropdown [options]="listOfOwners" [(ngModel)]="selectedOwner" optionLabel="name" [showClear]="true"
                    [filter]="true" styleClass="p-column-filter" [virtualScroll]="true" [virtualScrollItemSize]="30"
                    [resetFilterOnHide]="true" filterBy="name,mudId" placeholder="Select Owner" [disabled]="!isAdmin"
                    [class.ng-invalid]="isValidated && !selectedOwner" dataKey="mudId"
                    (onChange)="onOwnerChange($event)">
                </p-dropdown>
                <p *ngIf="isValidated && !selectedOwner" class="help is-danger">Owner is required.</p>
            </div>
            <div class="control">
                <p><strong>Back-up Owners</strong></p>
                <p-multiSelect [(ngModel)]="pathway.backupOwners" [options]="backupOwners" styleClass="p-column-filter"
                    defaultLabel="Select owners" optionLabel="name" filterBy="name,mudId" display="chip"
                    [resetFilterOnHide]="true" [virtualScroll]="true" [virtualScrollItemSize]="30"
                    [class.ng-invalid]="isValidated && (!pathway.backupOwners || pathway.backupOwners?.length === 0)">
                </p-multiSelect>
                <p *ngIf="isValidated && (!pathway.backupOwners || pathway.backupOwners.length === 0)"
                    class="help is-danger">
                    Backup owner is required.</p>
                <p *ngIf="isValidated && !validateBackupOwners()" class="help is-danger">
                    Backup owner must be other than primary owner.
                </p>
            </div>
        </div>
        <div class="column is-4">
            <div class="control p-mb-4">
                <p><strong>Description</strong></p>
                <textarea title="Pathway Description" [rows]="10" pInputTextarea [(ngModel)]="pathway.description"
                    autoResize="autoResize" maxlength="2500" style="padding: 2px; width: 100%; resize:none;"
                    [class.ng-invalid]="isValidated && !pathway.description">
                </textarea>
                <p *ngIf="isValidated && !pathway.description" class="help is-danger">Pathway description is required.
                </p>
            </div>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1>Stage Gate 0</h1>
            <shared-stage-gate [sgNumber]="0" [flatNodes]="stageGate1Flat" [treeNodes]="stageGate1Tree"
                [isEditable]="true" [isValidated]="isValidated"
                (triggerTypeChanged)="onTriggerTypeSelectionChanged($event, 0)"
                (trainingMoved)="onTrainingMoved($event)" (adjustTreeEvent)="onAdjustTree(0)"
                (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <p-divider></p-divider>
    <div class=" columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1 class="stage-header">Stage Gate 1</h1>
        </div>
        <div class="column">
            <p-inputNumber class="floatRight p-mb-1" [(ngModel)]="pathway.stageGate2.delay" prefix="Delay "
                suffix=" days" mode="decimal" [min]="1" [max]="pathway.stageGate3.delay - 1" [showButtons]="true"
                [allowEmpty]="false">
            </p-inputNumber>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <shared-stage-gate [sgNumber]="1" [flatNodes]="stageGate2Flat" [treeNodes]="stageGate2Tree"
                [isEditable]="true" [isValidated]="isValidated"
                (triggerTypeChanged)="onTriggerTypeSelectionChanged($event, 1)"
                (trainingMoved)="onTrainingMoved($event)" (adjustTreeEvent)="onAdjustTree(1)"
                (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <p-divider></p-divider>
    <div class=" columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1 class="stage-header">Stage Gate 2</h1>
        </div>
        <div class="column">
            <p-inputNumber class="floatRight p-mb-1" [(ngModel)]="pathway.stageGate3.delay" prefix="Delay "
                suffix=" days" [allowEmpty]="false" mode="decimal" [min]="pathway.stageGate2.delay + 1"
                [max]="pathway.stageGate4.delay - 1" [showButtons]="true">
            </p-inputNumber>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <shared-stage-gate [sgNumber]="2" [flatNodes]="stageGate3Flat" [treeNodes]="stageGate3Tree"
                [isEditable]="true" [isValidated]="isValidated"
                (triggerTypeChanged)="onTriggerTypeSelectionChanged($event, 2)"
                (trainingMoved)="onTrainingMoved($event)" (adjustTreeEvent)="onAdjustTree(2)"
                (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <p-divider></p-divider>
    <div class=" columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1 class="stage-header">Stage Gate 3</h1>
        </div>
        <div class="column">
            <p-inputNumber class="floatRight p-mb-1" [(ngModel)]="pathway.stageGate4.delay" prefix="Delay "
                suffix=" days" mode="decimal" [allowEmpty]="false" [min]="pathway.stageGate3.delay + 1"
                [showButtons]="true">
            </p-inputNumber>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <shared-stage-gate [sgNumber]="3" [flatNodes]="stageGate4Flat" [treeNodes]="stageGate4Tree"
                [isEditable]="true" [isValidated]="isValidated"
                (triggerTypeChanged)="onTriggerTypeSelectionChanged($event, 3)"
                (trainingMoved)="onTrainingMoved($event)" (adjustTreeEvent)="onAdjustTree(3)"
                (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <shared-curriculum-details #curriculumDetails></shared-curriculum-details>
</ng-container>