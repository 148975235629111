import { Component, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { JoyrideWrapperService } from '../services/joyride-wrapper.service';

@Component({
  selector: 'app-help-button',
  template: `
    <ng-template [ngIf]="isButtonEnabled"> 
      <button pButton 
        type="button"
        label="Help"
        icon="pi pi-info-circle"
        iconPos="left"
        class="p-button floatRight help-button"
        joyrideStep="global.help-button"
        title="Help"
        [stepContentParams]="{text: 'This button restarts the instructions.'}"
        (click)="helpButtonClick()"
        [stepContent]="stepContent">
      </button>
    </ng-template>
    `,
  styleUrls: ['./help-button.component.css']
})
export class HelpButtonComponent {

  public get stepContent(): TemplateRef<any> {
    return this.joyrideWrapperService.stepTemplate;
  }

  constructor(
    private readonly joyrideWrapperService: JoyrideWrapperService,
    private readonly router: Router
  ) { }

  public get isButtonEnabled(): boolean {
    return this.joyrideWrapperService.isTourActive;
  }

  public helpButtonClick(): void {
    let skipManagerTour = this.router.url === '/manager/learner/manage/training'
      || this.router.url === '/manager/learner/manage/roles'
      || this.router.url === '/manager/learner/manage/mylearning-plan';

    this.joyrideWrapperService.startTour(skipManagerTour).subscribe();
  }
}
