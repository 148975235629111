<div class="myGadget">

    <nav class="navbar" role="navigation">
        <div class="container is-fullhd">
            <div class="navbar-brand">
                <span class="navbar-item" style="padding: 0 .75rem;">
                    <a class="navbar-item logo" routerLink="/"><span class="logo1">my</span><span
                            class="logo2">Gadget</span></a>
                </span>
                <a *ngIf="user && user.isManager" class="navbar-item" routerLink="/manager"
                    routerLinkActive="is-active">Manager</a>
                <a *ngIf="user && user.isAdmin" class="navbar-item" routerLink="/admin"
                    routerLinkActive="is-active">Admin</a>
                <a *ngIf="user && user.isSuperAdmin" class="navbar-item" routerLink="/super-admin"
                    routerLinkActive="is-active">Super Admin</a>
            </div>

            <div class="navbar-menu" id="navbar" [ngClass]="{'is-active': mobileMenuActive}">
                <div class="navbar-start"></div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <p-menu #menu appendTo="body" [popup]="true" [model]="items"
                            (onShow)="adjustMenuItems()"></p-menu>
                        <button title="User Settings" type="button" button pButton pRipple icon="pi pi-cog"
                            class="p-button-rounded p-button-outlined p-button-secondary"
                            (click)="menu.toggle($event)"></button>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <app-banner [rawMessage]="message" [showBanner]="showBanner"></app-banner>
    <app-help-button></app-help-button>
    <router-outlet></router-outlet>
    <b-loading></b-loading>
    <b-message></b-message>
    <splash-screen #splashScreen></splash-screen>
</div>

<!-- tour button template -->

<ng-template #prevButton>
    <button pButton type="button" label="PREV"
        class="p-button-rounded p-button-outlined p-button-secondary stdColor"></button>
</ng-template>
<ng-template #nextButton>
    <button pButton type="button" label="NEXT" class="p-button-rounded stdColor"></button>
</ng-template>
<ng-template #doneButton>
    <button pButton type="button" label="DONE" class="p-button-rounded stdColor"></button>
</ng-template>
<ng-template #stepContent let-text="text">
    <app-tour-content [text]="text"></app-tour-content>
</ng-template>

<!-- END tour button template -->