import { Component, EventEmitter, Output } from '@angular/core';
import { Pathway } from '../../models/pathway';

@Component({
  selector: 'shared-display-pathway',
  templateUrl: './display-pathway.component.html',
  styleUrls: ['./display-pathway.component.css']
})
export class DisplayPathwayComponent {

  constructor() { }

  @Output() onModalClosed = new EventEmitter<void>();

  public display: boolean;
  public pathway: Pathway = {} as Pathway;
  public validated: boolean = false;
  public isProxyManager: boolean;

  ngOnInit(): void {
  }

  closeDialog() {
    this.display = false;
    this.onModalClosed.emit();
  }

  showDialog(pathway: Pathway) {
    this.pathway = pathway;
    this.display = true;
  }
}