import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { CurriculumDetailsComponent } from '../curriculum-details/curriculum-details.component';
import { Pathway } from '../../models/pathway';
import { PathwayTraining } from '../../models/pathwayTraining';
import { StageGate } from '../../models/stageGate';
import { UserBase } from '../../models/userBase';
import { PathwayService } from '../../services/pathway.service';

@Component({
    selector: 'shared-pathway-details',
    templateUrl: './pathway-details.component.html',
    styleUrls: ['./pathway-details.component.css'],
})
export class PathwayDetailsComponent implements OnInit, OnChanges {
    constructor(private readonly pathwayService: PathwayService) {}

    ngOnInit(): void {
        this.rebuildStageGates();
    }

    ngOnChanges(): void {
        this.rebuildStageGates();
    }

    public selectedOwner: UserBase;

    public stageGate1Flat: TreeNode<PathwayTraining>[];
    public stageGate1Tree: TreeNode<PathwayTraining>[];
    public stageGate2Flat: TreeNode<PathwayTraining>[];
    public stageGate2Tree: TreeNode<PathwayTraining>[];
    public stageGate3Flat: TreeNode<PathwayTraining>[];
    public stageGate3Tree: TreeNode<PathwayTraining>[];
    public stageGate4Flat: TreeNode<PathwayTraining>[];
    public stageGate4Tree: TreeNode<PathwayTraining>[];
    public showOldVersions: boolean = false;
    public oldPathwayVersions: Pathway[];

    @ViewChild('curriculumDetails')
    curriculumDetails: CurriculumDetailsComponent;

    @Input() public pathway: Pathway = {} as Pathway;
    @Input() public listOfOwners: UserBase[] = [];
    @Input() public isAdmin: boolean;

    public getTriggeredBy(
        training: PathwayTraining,
        stage: number
    ): PathwayTraining {
        return this.getStageGate(stage).training.find(
            (t) => t.roleTrainingId === training.trigger?.roleTrainingId
        );
    }

    public displayCurriculum(myLearningCode: string) {
        this.curriculumDetails.showModal(myLearningCode);
    }

    public rebuildStageGates() {
        this.pathway.stageGate1?.training.forEach((t) => {
            t.stageGate = 0;
            if (t.trigger) {
                t.trigger = this.getTriggeredBy(t, 0);
            }
        });

        this.pathway.stageGate2?.training.forEach((t) => {
            t.stageGate = 1;
            if (t.trigger) {
                t.trigger = this.getTriggeredBy(t, 1);
            }
        });

        this.pathway.stageGate3?.training.forEach((t) => {
            t.stageGate = 2;
            if (t.trigger) {
                t.trigger = this.getTriggeredBy(t, 2);
            }
        });

        this.pathway.stageGate4?.training.forEach((t) => {
            t.stageGate = 3;
            if (t.trigger) {
                t.trigger = this.getTriggeredBy(t, 3);
            }
        });

        this.stageGate1Flat = this.pathway.stageGate1?.training.map(
            (t) => <TreeNode<PathwayTraining>>{ data: t }
        );
        this.stageGate1Tree = this.buildTree(this.stageGate1Flat);

        this.stageGate2Flat = this.pathway.stageGate2?.training.map(
            (t) => <TreeNode<PathwayTraining>>{ data: t }
        );
        this.stageGate2Tree = this.buildTree(this.stageGate2Flat);

        this.stageGate3Flat = this.pathway.stageGate3?.training.map(
            (t) => <TreeNode<PathwayTraining>>{ data: t }
        );
        this.stageGate3Tree = this.buildTree(this.stageGate3Flat);

        this.stageGate4Flat = this.pathway.stageGate4?.training.map(
            (t) => <TreeNode<PathwayTraining>>{ data: t }
        );
        this.stageGate4Tree = this.buildTree(this.stageGate4Flat);
    }

    public dispalyOldVersions() {
        this.showOldVersions = true;
        this.pathwayService
            .getAllVersionsOfPathways(this.pathway.pathwayId)
            .subscribe((res) => {
                this.oldPathwayVersions = res;
            });
    }

    public versionSelected() {
        this.rebuildStageGates();
    }

    public reloadView() {
        this.showOldVersions = false;
    }

    private buildTree(
        nodes: TreeNode<PathwayTraining>[]
    ): TreeNode<PathwayTraining>[] {
        var map = {},
            node: TreeNode,
            roots = [],
            i: number;

        if (!nodes) {
            return;
        }

        for (i = 0; i < nodes.length; i += 1) {
            map[nodes[i].data.roleTrainingId] = i;
            nodes[i].children = [];
        }

        for (i = 0; i < nodes.length; i += 1) {
            node = nodes[i];
            node.expanded = true;
            if (node.data.trigger?.roleTrainingId) {
                nodes[map[node.data.trigger?.roleTrainingId]].children.push(
                    node
                );
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    private getStageGate(stage: number): StageGate {
        switch (stage) {
            case 0:
                return this.pathway.stageGate1;
            case 1:
                return this.pathway.stageGate2;
            case 2:
                return this.pathway.stageGate3;
            case 3:
                return this.pathway.stageGate4;
            default:
                throw new Error('Out of stages index');
        }
    }
}
