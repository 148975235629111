import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DisplayPathwayComponent } from '../display-pathway/display-pathway.component';
import { AdminRole } from '../../models/adminRole';
import { PathwayService } from '../../services/pathway.service';

@Component({
    selector: 'shared-read-only-role-metadata',
    templateUrl: './read-only-role-metadata.component.html',
    styleUrls: ['./read-only-role-metadata.component.css'],
})
export class ReadOnlyRoleMetadataComponent implements OnInit {
    constructor(private readonly pathwayService: PathwayService) {}

    @Input() role: AdminRole;

    @ViewChild('displayPathwayModal')
    displayPathwayModal: DisplayPathwayComponent;

    ngOnInit(): void {}

    displayPathway(event) {
        if (!this.role.pathwaysLoaded) {
            this.pathwayService
                .getRolePathways(this.role.roleId)
                .subscribe((res) => {
                    this.role.pathways = res;
                    this.role.pathwaysLoaded = true;
                    this.displayPathwayModal.showDialog(
                        this.role.pathways.find((p) => p.pk === event.value.pk)
                    );
                });
        } else {
            this.displayPathwayModal.showDialog(event.value);
        }
    }
}
