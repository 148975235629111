import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnChanges {

  @Input() rawMessage: string;
  @Input() showBanner: boolean;
  message: SafeHtml;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnChanges() {
    if (this.rawMessage) {
      this.message = this.sanitizer.bypassSecurityTrustHtml(this.rawMessage);
    }
  }

  closeBanner(): void {
    this.showBanner = false;
  }
}