import { Component, Input } from '@angular/core';

@Component({
    selector: 'shared-two-buttons-container',
    templateUrl: './two-buttons-container.component.html',
    styleUrls: ['./two-buttons-container.component.css'],
})
export class TwoButtonsContainerComponent {
    @Input() confirmLabel: string;
    @Input() cancelLabel: string;
    @Input() onConfirm: Function;
    @Input() onCancel: Function;
    @Input() confirmDisabled = false;
    @Input() cancelDisabled = false;
    @Input() showCancel = true;
    @Input() showConfirm = true;

    public handleCancel() {
        this.onCancel();
    }

    public handleConfirm() {
        this.onConfirm();
    }
}
