import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tour-content',
  template: `<span>{{text}}</span>`,
  styleUrls: ['./tour-content.component.css']
})
export class TourContentComponent {
  @Input() text: string;
}
