import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { MyLearningPlanItem } from '../models/myLearningPlanItem';
import { CombinedAssignment } from '../models/combinedAssignment';
import { LearnerStatus } from '../models/learnerStatus';
import { WaiverRequest } from '../models/waiverRequest';
import { DeferralRequest } from '../models/deferralRequest';
import { AssignRole } from '../models/assignRole';
import { SelectedByRelation } from '../models/selectedByRelation';
import { CallType } from '../models/callType';
import { GetLearnerRoleResponse } from '../models/getLearnerRoleResponse';
import { GetLearnerTrainingResponse } from '../models/getLearnerTrainingResponse';
import { ManageComplianceTrainingView } from '../models/enums/manageComplianceTrainingView';
import { GetAssignedTrainingsStatusesResponse } from '../models/getAssignedTrainingsStatusesResponse';
import { Training } from '../models/Training';

@Injectable({
    providedIn: 'root'
})
export class LearnerService {

    constructor(private http: HttpClient,
        private loadingService: LoadingService) { }

    getLearnerStatus(mudid: string): Observable<LearnerStatus[]> {
        this.loadingService.show();

        const endpoint = `/mygadget/learner/${mudid}/status`;
        return this.http.get<LearnerStatus[]>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    getLearnerRoles(mudid: string): Observable<GetLearnerRoleResponse> {
        this.loadingService.show();

        const endpoint = `/mygadget/learner/${mudid}/roles`;
        return this.http.get<GetLearnerRoleResponse>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    verifyViewStatus(mudId: string, date: Date, view: ManageComplianceTrainingView): Observable<boolean> {
        this.loadingService.show();

        const endpoint = `/mygadget/learner/verifyViewStatus?view=${view}&mudId=${mudId}&date=${date}`;
        return this.http.get<boolean>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    activateRoles(roles: any, isAdminRequest: boolean = false) {
        this.loadingService.show();

        const endpoint = isAdminRequest ?
            '/mygadget/managerProxy/learner/activateRoles' :
            '/mygadget/learner/activateRoles';
        return this.http.put(endpoint, roles).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    deactivateRoles(roles: any, isAdminRequest: boolean = false) {
        this.loadingService.show();

        const endpoint = isAdminRequest ?
            '/mygadget/managerProxy/learner/deactivateRoles' :
            '/mygadget/learner/deactivateRoles';
        return this.http.put<any>(endpoint, roles).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    addRoles(roles: AssignRole[], isActive: boolean, isAdminRequest: boolean = false) {
        this.loadingService.show();

        const endpoint = isAdminRequest ?
            `/mygadget/managerProxy/learner/addRoles?isActive=${isActive}` :
            `/mygadget/learner/addRoles?isActive=${isActive}`;
        return this.http.post(endpoint, roles).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    deleteRoles(roles: any, isAdminRequest: boolean = false) {
        this.loadingService.show();

        const endpoint = isAdminRequest ?
            '/mygadget/managerProxy/learner/deleteRoles/' :
            '/mygadget/learner/deleteRoles/';
        return this.http.put<any>(endpoint, roles).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }


    unassignPathways(roleAssignments: { roleId: number, userId: string }[], relationship: 'User' | 'Manager' | 'ProxyManager') {
        this.loadingService.show();

        const endpoint = `/mygadget/learner/unassignPathways?isManager=${(relationship === 'Manager' || relationship === 'ProxyManager') ? 'true' : 'false'}`;
        return this.http.put<{ roleId: number, userId: string }>(endpoint, roleAssignments).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    getLearnerTrainings(mudId: string): Observable<GetLearnerTrainingResponse> {
        this.loadingService.show();
        const endpoint = `/mygadget/learner/getTrainings?mudId=${mudId}`;

        return this.http.get<GetLearnerTrainingResponse>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    getTraingsForCurriculum(curriculumId: string): Observable<Training[]> {
        this.loadingService.show();
        const endpoint = `/mygadget/learner/getTrainingsForCurriculum?curriculumId=${curriculumId}`;

        return this.http.get<Training[]>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    confirmTraningSelection(trainings: Training[], assignee: string, assignedBy: string, selectedByRelation: SelectedByRelation) {
        this.loadingService.show();

        const endpoint = selectedByRelation === 'Admin' ?
            `/mygadget/managerProxy/learner/confirmSelectedTrainings?assignee=${assignee}&assignedBy=${assignedBy}` :
            `/mygadget/learner/confirmSelectedTrainings?assignee=${assignee}&assignedBy=${assignedBy}&selectedByRelation=${selectedByRelation}`;

        const headers = {
            'Content-Type': 'application/json'
        };

        return this.http.post(endpoint, JSON.stringify(trainings), { headers }).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    getAssignedTrainingsStatuses(mudId: string): Observable<GetAssignedTrainingsStatusesResponse> {
        this.loadingService.show();
        const endpoint = `/mygadget/learner/GetAssignedTrainingsStatuses?mudId=${mudId}`;

        return this.http.get<GetAssignedTrainingsStatusesResponse>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    updateMyLearningPlan(assignee: string, assignedBy: string, items: MyLearningPlanItem[], callType: CallType) {
        this.loadingService.show();

        const endpoint = callType === 'MyGadgetAdmin' ?
            `/mygadget/managerProxy/learner/updateMyLearningPlan?assignee=${assignee}&assignedBy=${assignedBy}` :
            `/mygadget/learner/updateMyLearningPlan?assignee=${assignee}&assignedBy=${assignedBy}&callType=${callType}`;

        const headers = {
            'Content-Type': 'application/json'
        };

        return this.http.put(endpoint, JSON.stringify(items), { headers }).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        )
    }

    checkIfRolesAreBeingProcessed(mudId: string): Observable<boolean> {
        this.loadingService.show();
        const endpoint = `/mygadget/learner/checkIfRolesAreBeingProcessed?mudId=${mudId}`;

        return this.http.get<boolean>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        )
    }

    splashScreenSettings(hide: boolean) {
        this.loadingService.show();
        const endpoint = `/mygadget/learner/splashScreenSettings?hide=${hide}`;

        return this.http.put(endpoint, null).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        )
    }

    checkSplashScreenSettings(): Observable<boolean> {
        this.loadingService.show();
        const endpoint = '/mygadget/learner/checkSplashScreenSettings';

        return this.http.get<boolean>(endpoint).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        )
    }

    assignTrainings(assignments: CombinedAssignment, callType: CallType) {
        this.loadingService.show();

        const endpoint = `/mygadget/learner/assignment?callType=${callType}`;
        return this.http.post<any>(endpoint, assignments).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    managerRoleOverride(overriddenRoles, delegatedManager: boolean, isAdminRequest: boolean = false) {
        this.loadingService.show();

        const endpoint = isAdminRequest
            ? '/mygadget/managerProxy/managerRoleOverride'
            : `/mygadget/learner/managerRoleOverride?delegatedManager=${delegatedManager}`;
        return this.http.put<any>(endpoint, overriddenRoles).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }

    checkIfItemIsAssignable(item: any, mudId: string, blockingCurriculum: string) {
        this.loadingService.show();
        const endpoint = `/mygadget/learner/checkIfItemIsAssignable?mudId=${mudId}&blockingCurriculum=${blockingCurriculum}`;

        const headers = {
            'Content-Type': 'application/json'
        };

        return this.http.put(endpoint, JSON.stringify(item), { headers }).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        )
    }

    handleWaiveRequest(request: WaiverRequest) {
        this.loadingService.show();

        const endpoint = `/mygadget/learner/handleWaiveRequest`;

        const headers = {
            'Content-Type': 'application/json'
        };

        return this.http.post(endpoint, JSON.stringify(request), { headers }).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        )
    }

    getAllPendingWaiverRequestsForDirectReports(managerMudid?: string): Observable<WaiverRequest[]> {
        this.loadingService.show();

        const endpoint = managerMudid ?
            `/mygadget/managerProxy/${managerMudid}/learner/waiverRequests` :
            `/mygadget/learner/waiverRequests`;

        return this.http.get<WaiverRequest[]>(endpoint).pipe(
            finalize(() => this.loadingService.hide())
        );
    }

    getAllPendingDeferralRequestsForDirectReports(managerMudid?: string): Observable<DeferralRequest[]> {
        this.loadingService.show();

        const endpoint = managerMudid ?
            `/mygadget/managerProxy/${managerMudid}/learner/deferralRequests` :
            `/mygadget/learner/deferralRequests`;

        return this.http.get<DeferralRequest[]>(endpoint).pipe(
            finalize(() => this.loadingService.hide())
        );
    }


    handleDeferralRequest(request: DeferralRequest) {
        this.loadingService.show();

        const endpoint = `/mygadget/learner/handleDeferralRequest`;

        const headers = {
            'Content-Type': 'application/json'
        };

        return this.http.post(endpoint, JSON.stringify(request), { headers }).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        )
    }
}
