import { Component, OnInit } from '@angular/core';
import { WaiverRequest } from '../models/waiverRequest';
import { LearnerService } from '../services/learner.service';
import { MessageService } from '../services/message.service';
import { ManagerProxyService } from '../manager-proxy.service';

@Component({
  selector: 'app-waiver-requests',
  templateUrl: './waiver-requests.component.html',
  styleUrls: ['./waiver-requests.component.css']
})
export class WaiverRequestsComponent implements OnInit {

  public itemForWaiver: WaiverRequest;
  public showWaiverConfirmation: boolean;
  public waiverRequests: WaiverRequest[];
  public globalSearchInput: string;

  constructor(private readonly learnerService: LearnerService,
    private readonly messageService: MessageService,
    private readonly managerProxyService: ManagerProxyService) { }

  ngOnInit(): void {
    this.learnerService.getAllPendingWaiverRequestsForDirectReports(this.managerProxyService.proxiedManager ?? undefined)
      .subscribe({
        next: waiverRequests => this.waiverRequests = waiverRequests,
        error: _ => this.messageService.add({ text: 'There was an error when retrieving waiver requests.', timeout: 30000, style: 'is-danger' })
      });
  }

  waiver(waiverRequest: WaiverRequest): void {
    this.itemForWaiver = {
      ...waiverRequest,
      userId: waiverRequest.userId,
      learnerName: waiverRequest.learnerName,
      revisionDate: waiverRequest.revisionDate,
      waiveDetails: waiverRequest.waiveDetails,
      itemTitle: waiverRequest.itemTitle,
      curriculumCode: waiverRequest.curriculumCode
    };
    this.showWaiverConfirmation = true;
  }

  onWaiverDialogSuccess(waiverRequest: WaiverRequest): void {
    if (waiverRequest.requestStatus !== 'Requested') {
      this.waiverRequests = this.waiverRequests.filter(wr => wr.trainingAssignmentId !== waiverRequest.trainingAssignmentId);
    }
  }
}
