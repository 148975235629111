import { Component } from '@angular/core';

@Component({
  selector: 'app-sso-logout',
  templateUrl: './sso-logout.component.html',
  styleUrls: ['./sso-logout.component.css']
})
export class SsoLogoutComponent {

  constructor() { }

}
