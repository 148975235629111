import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

import { IAuthService } from './auth/iAuthService';
import { UserFullData } from './models/userFullData';
import { JoyrideWrapperService } from './services/joyride-wrapper.service';
import { LearnerService } from './services/learner.service';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
    title = 'myGadget';
    showBanner: boolean = environment.showBanner;
    message: string = environment.bannerMessage;

    mobileMenuActive = false;

    user: UserFullData;
    items: MenuItem[];

    @ViewChild('prevButton') prevButton: TemplateRef<any>;
    @ViewChild('nextButton') nextButton: TemplateRef<any>;
    @ViewChild('doneButton') doneButton: TemplateRef<any>;
    @ViewChild('stepContent') stepContent: TemplateRef<any>;
    @ViewChild('splashScreen') splashScreen: SplashScreenComponent;

    constructor(
        private readonly authService: IAuthService,
        private readonly joyrideWrapperService: JoyrideWrapperService,
        private readonly learnerService: LearnerService,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.items = [
            {
                label: 'Splash Screen',
                command: () => {
                    this.showSplashScreen();
                },
            },
            {
                label: 'Log Out',
                command: () => {
                    this.logout();
                },
            },
        ];

        try {
            this.authService.userValue.subscribe((result) => {
                this.user = result;
            });
        } catch (e) {
            this.user = null;
        }

        this.authService.initAuthenticationAndGetRedirectPath();
    }

    ngAfterViewInit() {
        this.joyrideWrapperService.prevButtonTemplate = this.prevButton;
        this.joyrideWrapperService.nextButtonTemplate = this.nextButton;
        this.joyrideWrapperService.doneButtonTemplate = this.doneButton;
        this.joyrideWrapperService.stepTemplate = this.stepContent;
    }

    isLoggedIn(adminOnly: boolean) {
        return this.authService.isLoggedIn(adminOnly);
    }

    async logout() {
        await this.authService.logout();
    }

    public showSplashScreen() {
        this.splashScreen.openDialog();
        this.splashScreen.splashScreenResult.subscribe((result) => {
            this.learnerService
                .splashScreenSettings(result.dontShowAgain)
                .subscribe();

            if (result.destination === '/learner/manage') {
                this.router.navigate(['/learner/manage']);
            }
        });
    }

    public adjustMenuItems() {
        this.items.find((i) => i.label === 'Splash Screen').visible =
            this.router.url.startsWith('/learner');
    }
}
