export const environment = {
    production: false,
    environmentName: 'test',
    api_url: 'https://iwhuk-qa.gsk.com/learningapi',
    delegationManagementUrl: 'https://hcm2preview.sapsf.eu/sf/learning?destUrl=https%3a%2f%2fglaxosmith%2dstage%2eplateau%2ecom%2flearning%2fuser%2fdeeplink%5fredirect%2ejsp%3flinkId%3dEMPLOYEE%5fSUBORDINATES%26fromSF%3dY&company=glaxosmithT2',
    SSO: {
        useSSO: true,
        issuer: 'https://federation-qa.gsk.com',
        clientId: 'd33cd49cf7d64b63866e6203b8f8a166',
        logoutUrl: 'https://federation-qa.gsk.com/idp/startSLO.ping'
    },
    doNotStartTourGide: false,
    showBanner: false,
    bannerMessage: ''
};