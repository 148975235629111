import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { LocationsComponent } from 'src/app/shared/locations/locations.component';
import { ManagerProxyService } from 'src/app/manager-proxy.service';
import { IAdminReport } from 'src/app/models/adminReport';
import { RolesReportConfig } from 'src/app/models/reportsConfigs/rolesReportConfig';
import { ReportsService } from 'src/app/services/reports.service';
import { AdminsAndBackupadminsComponent } from '../admins-and-backupadmins/admins-and-backupadmins.component';
import { ContentOwnersComponent } from '../content-owners/content-owners.component';
import { HierarchiesComponent } from '../hierarchies/hierarchies.component';

@Component({
    selector: 'shared-roles-report',
    templateUrl: './roles-report.component.html',
    styleUrls: ['./roles-report.component.css'],
})
export class RolesReportComponent implements IAdminReport {
    constructor(
        private reportService: ReportsService,
        private managerProxyService: ManagerProxyService
    ) {}

    @ViewChild('hierarchies') hierarchies: HierarchiesComponent;
    @ViewChild('locations') locations: LocationsComponent;
    @ViewChild('admins') admins: AdminsAndBackupadminsComponent;
    @ViewChild('contentOwners') contentOwners: ContentOwnersComponent;

    rolesOption: 'AllRoles' | 'JustMine' = 'AllRoles';

    set filterOption(value: 'Hierarchy' | 'Location') {
        this._filterOption = value;
        this.transverseRoles = false;
    }

    get filterOption() {
        return this._filterOption;
    }

    transverseRoles: boolean = false;
    associatedTraining: boolean = false;

    private _filterOption: 'Hierarchy' | 'Location' = 'Hierarchy';

    public generateReport(): Observable<void> {
        const config: RolesReportConfig = {
            rolesOption: this.rolesOption,
            transverseRoles: this.transverseRoles,
            associatedTraining: this.associatedTraining,
            hierarchy: this.hierarchies?.hierarchy,
            location: this.locations?.location,
            admins: this.admins.adminsSelected,
            contentOwners: this.contentOwners.contentOwnerSelected,
        };
        return this.reportService.addRolesReport(
            config,
            this.managerProxyService.proxiedManager ?? undefined
        );
    }

    public reset() {
        this.hierarchies?.clearSelections();
        this.locations?.clearSelections();
        this.admins.clearSelections();
        this.contentOwners.clearSelections();
        this.associatedTraining = false;
        this.transverseRoles = false;
        this.rolesOption = 'AllRoles';
    }
}
