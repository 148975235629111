import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { IAuthService } from './auth/iAuthService';
import { Router } from '@angular/router';

@Injectable()
export class RefreshAuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private authService: IAuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.includes(environment.api_url)) {
            if (!this.authService.isLoggedIn()) {
                this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
            }
        }

        return next.handle(request);
    }
}