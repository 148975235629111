<div class="container is-fullhd">
    <div class="columns">
        <div class="column" style="margin:1rem">
            <p-table #dt [value]="waiverRequests" styleClass="p-datatable-sm" [paginator]="true" [rows]="10"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [rowsPerPageOptions]="[10,25,50]" [filterDelay]="0"
                [globalFilterFields]="['myLearningCode','itemTitle','learnerName','curriculumCode','curriculumTitle']"
                [sortOrder]="1" dataKey="TrainingAssignmentId">
                <ng-template pTemplate="caption" class="narrow">
                    <div class="columns">
                        <div class="column is-3 is-offset-9 short narrow">
                            <span class="p-input-icon-left fill">
                                <i class="pi pi-search"></i>
                                <input class="fill" pInputText type="text" [(ngModel)]="globalSearchInput"
                                    (input)="dt.filterGlobal(globalSearchInput, 'contains')"
                                    placeholder="Global Search" />
                            </span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="learnerName">User Name<p-sortIcon field="learnerName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="myLearningCode">myL Code<p-sortIcon field="myLearningCode"></p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="title">myL Title<p-sortIcon field="itemTitle"></p-sortIcon>
                        </th>
                        <th pSortableColumn="curriculumCode">Curriculum Code<p-sortIcon field="curriculumCode">
                            </p-sortIcon>
                        </th>
                        <th colspan="2" pSortableColumn="curriculumTitle">Curriculum Title<p-sortIcon
                                field="curriculumTitle"></p-sortIcon>
                        </th>
                        <th pSortableColumn="launch">Action<p-sortIcon field="launch"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-waiverRequest>
                    <tr>
                        <td>{{ waiverRequest.learnerName }}</td>
                        <td>{{ waiverRequest.myLearningCode }}</td>
                        <td colspan="2">{{ waiverRequest.itemTitle }}</td>
                        <td>{{ waiverRequest.curriculumCode }}</td>
                        <td colspan="2">{{ waiverRequest.curriculumTitle }}</td>
                        <td>
                            <div #waiverContainer class="center tooltip-container tooltip-narrow">
                                <span pTooltip="Waiver Request Pending" tooltipPosition="top"
                                    [appendTo]="waiverContainer">
                                    <button type="button" class="p-button-rounded pi pi-bars no-label small-icon"
                                        pButton (click)="waiver(waiverRequest)" style="margin-left: 1em;"></button>
                                </span>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No requests found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<waiver-confirmation [(showDialog)]='showWaiverConfirmation' [request]='itemForWaiver'
    (requestStateChanged)='onWaiverDialogSuccess($event)' [isManager]='true'></waiver-confirmation>