
export const MY_DATE_FORMATS =
{
    parse: {
        dateInput: { day: 'numeric', month: 'long', year: 'numeric' }
    },
    display: {
        dateInput: { day: 'numeric', month: 'long', year: 'numeric' },
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};