import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { LearnerService } from '../services/learner.service';
import { MessageService } from '../services/message.service';
import { MetadataService } from '../services/metadata.service';


@Component({
  selector: 'splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit {

  @Output() splashScreenResult = new EventEmitter<any>();
  donotShowAgain: boolean = false;

  showDialog: boolean = false;
  content: string;

  constructor(
    private readonly metadataService: MetadataService,
    private readonly messageService: MessageService,
    private readonly learnerService: LearnerService
  ) { }

  ngOnInit(): void {
  }

  openDialog() {
    this.loadData();
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
  }

  sendResult(destination: string) {
    this.splashScreenResult.emit({ dontShowAgain: this.donotShowAgain, destination: destination });
    this.closeDialog();
  }

  loadData() {
    this.metadataService.getSplashScreenContent().subscribe({
      next: content => {
        this.content = content;
      },
      error: () => this.messageService.add({ text: 'Error while fetching splash screen content.', style: 'is-danger', timeout: 15000 })
    });
    this.learnerService.checkSplashScreenSettings().subscribe(setting => this.donotShowAgain = setting)
  }
}