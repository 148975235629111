<div class="columns">
    <div class="column is-4">
        <p class="title">Role Name</p>
        <p>{{role.roleName}}</p>
        <p class="title">Description</p>
        <p>{{role.roleDescription}}</p>
        <p class="title">Pathways</p>
        <p-listbox *ngIf="role.pathways" [options]="role.pathways" optionLabel="name"
            (onDblClick)="displayPathway($event)"></p-listbox>
    </div>
    <div class="column is-4">
        <p class="title">Hierarchy</p>
        <p class="sub-title">Business Unit</p>
        <p>{{role.hierarchy?.businessUnit?.value || 'n/a'}}</p>
        <p class="sub-title">Bus Area/Dept</p>
        <p>{{role.hierarchy?.businessArea?.value || 'n/a'}}</p>
        <p class="sub-title">Group</p>
        <p>{{role.hierarchy?.group?.value || 'n/a'}}</p>
        <p class="sub-title">Country</p>
        <p>{{role.location?.country?.value || 'n/a'}}</p>
        <p class="sub-title">Site</p>
        <p>{{role.location?.site?.value || 'n/a'}}</p>
    </div>
    <div class="column is-4">
        <p class="title">Metadata</p>
        <p class="sub-title">Admin</p>
        <p>{{ role.adminName ?? role.adminId }}</p>
        <p class="sub-title">Backup Admins</p>
        <p>{{role.backupAdmins | userBases}}</p>
        <p class="sub-title">Content Owner</p>
        <p>{{role.roleContentOwnerName}} ({{role.roleContentOwnerId}})</p>
        <p class="sub-title">eMail Contact</p>
        <a href="mailto:{{role.contactEmail}}">{{role.contactEmail}}</a>
        <p class="sub-title">Last Review Date</p>
        <p>{{role.lastReviewDate | date:'dd-MMM-yyyy'}}</p>
    </div>
</div>
<shared-display-pathway #displayPathwayModal></shared-display-pathway>