<ng-container>
    <div class="column is-half">
        <div class="columns">
            <div class="column is-one-third">
                <p-radioButton class="p-mr-3 p-mb-2" name="rolesOption" label="All Roles" value="AllRoles"
                    [(ngModel)]="rolesOption">
                </p-radioButton>
                <p-radioButton class="p-mr-3 p-mb-2" name="rolesOption" label="Just Mine" value="JustMine"
                    [(ngModel)]="rolesOption">
                </p-radioButton>
            </div>
            <div class="column">
                <div class="p-mb-2">
                    <p-checkbox [disabled]="!hierarchies?.hierarchy?.businessUnit && !locations?.location?.country"
                        class=" p-mr-3" [(ngModel)]="transverseRoles" [binary]="true" inputId="transverseRoles">
                    </p-checkbox>
                    <label class="p-mr-3" for="transverseRoles">Include Transverse Roles</label>
                </div>
                <div>
                    <p-checkbox class="p-mr-3" [(ngModel)]="associatedTraining" [binary]="true"
                        inputId="associatedTraining"></p-checkbox>
                    <label for="associatedTraining">Include Associated Training</label>
                </div>
            </div>
        </div>
    </div>
    <div class="column is-half">
        <div class="control">
            <p>Search by:</p>
            <div class="columns">
                <div class="column is-one-third" style="padding: 0px;">
                    <p-radioButton class="p-mr-3 p-mb-2" name="filterOptions" label="Hierarchy" value="Hierarchy"
                        [(ngModel)]="filterOption">
                    </p-radioButton>
                    <p-radioButton class="p-mr-3 p-mb-2" name="filterOptions" label="Location" value="Location"
                        [(ngModel)]="filterOption">
                    </p-radioButton>
                </div>
            </div>
        </div>
    </div>
    <shared-hierarchies *ngIf="filterOption === 'Hierarchy'" #hierarchies style="display: contents;"></shared-hierarchies>
    <shared-locations *ngIf="filterOption === 'Location'" #locations style="display: contents;"></shared-locations>
    <shared-admins-and-backupadmins #admins style="display: contents;"></shared-admins-and-backupadmins>
    <shared-content-owners #contentOwners style="display: contents;"></shared-content-owners>
</ng-container>