<div class="modal is-active" *ngIf="display">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">Pathway Details</p>
            <shared-pathway-details #pathwayDetails [isAdmin]="false" [pathway]="pathway">
            </shared-pathway-details>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeDialog()"></button>
</div>