import { Pipe, PipeTransform } from '@angular/core';
import { Hierarchy } from '../../models/hierarchy';

@Pipe({
  name: 'hierarchy'
})
export class HierarchyPipe implements PipeTransform {

  transform(value: Hierarchy): string {
    const hierarchyVals = [value.businessUnit?.value, value.businessArea?.value, value.group?.value]
      .filter(v => v !== undefined);
    return hierarchyVals.join(' / ');
  }

}
