import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SortEvent, FilterService } from 'primeng/api';

import { MessageService } from '../services/message.service';
import { LearnerService } from '../services/learner.service';
import { IAuthService } from '../auth/iAuthService';
import { UtilService } from '../services/util.service';
import { Training } from '../models/Training';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { HasTour } from '../has-tour/has-tour';
import { JoyrideWrapperService } from '../services/joyride-wrapper.service';
import { SelectedByRelation } from '../models/selectedByRelation';
import { UserFullData } from '../models/userFullData';
import { DirectReport } from '../models/directReport';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { ManageComplianceTrainingView } from '../models/enums/manageComplianceTrainingView';
import { CurriculumDetailsComponent } from '../shared/curriculum-details/curriculum-details.component';

@Component({
    selector: 'app-learner-training',
    templateUrl: './learner-training.component.html',
    styleUrls: ['./learner-training.component.css'],
})
export class LearnerTrainingComponent
    extends HasTour
    implements OnInit, OnDestroy
{
    user: UserFullData | DirectReport;
    manager: UserFullData;
    trainings: Training[];
    originalTranings: Training[];
    listOfComplianceSettings: any;
    listOfAssignedBy: any;
    expandedRows: {} = {};
    areRowsExpanded: boolean = false;
    showAdvSearch: boolean = false;
    modalOpened: boolean = false;
    curriculumTrainings: Training[];
    isManager: boolean;
    isManagerProxy: boolean = false;
    globalSearchInput: string;
    roleInput: string;
    myLCodeInput: string;
    titleInput: string;

    private viewGeneratedOn: Date;
    private subData: Subscription;
    private subUser: Subscription;
    private subManager: Subscription;

    public tourSteps = [
        'learner-training.compliance-setting',
        'learner-training.table-checkbox',
        'learner-training.confirm',
    ];
    protected tourLocalStorageKey = 'LearnerTraining';

    @ViewChild('confirmationDialog')
    confirmationDialog: ConfirmationDialogComponent;
    @ViewChild('cutrriculumDetails')
    cutrriculumDetails: CurriculumDetailsComponent;
    constructor(
        private readonly router: Router,
        private readonly messageService: MessageService,
        private readonly learnerService: LearnerService,
        private readonly authService: IAuthService,
        public readonly utilService: UtilService,
        private readonly filterService: FilterService,
        private readonly route: ActivatedRoute,
        private readonly userService: UserService,
        joyrideWrapperService: JoyrideWrapperService
    ) {
        super(joyrideWrapperService, router);
    }

    ngOnInit() {
        this.subData = this.route.data.subscribe((data) => {
            this.isManager = data.manager;
            this.isManagerProxy = data.managerProxy;

            if (this.isManager) {
                this.subUser = this.authService.directsMud.subscribe(
                    (result) => {
                        this.user = result;
                    }
                );
                this.subManager = this.authService.userValue.subscribe(
                    (result) => {
                        this.manager = result;
                    }
                );
            } else {
                this.subUser = this.authService.userValue.subscribe(
                    (result) => {
                        this.user = result;
                    }
                );
            }
        });

        this.userService.getUserViewData(this.user.mudId).subscribe((res) => {
            this.authService.userViewData = res;
        });

        this.getDataToDisplay();

        this.filterService.register(
            'customFilter',
            this.utilService.seearchInsideCurriculaFilter
        );

        return super.ngOnInit();
    }

    private getDataToDisplay() {
        this.learnerService.getLearnerTrainings(this.user.mudId).subscribe({
            next: (result) => {
                this.listOfComplianceSettings = result.training
                    .map((item) => item.complianceSetting)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.listOfAssignedBy = result.training
                    .map((item) => item.displaySelectedBy)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.trainings = result.training;
                this.viewGeneratedOn = result.generatedOn;
                this.originalTranings = JSON.parse(
                    JSON.stringify(result.training)
                );
            },
            error: () => {
                this.messageService.add({
                    text: 'There was an error when retrieving user data.',
                    timeout: 30000,
                    style: 'is-danger',
                });
            },
        });
    }

    ngOnDestroy() {
        this.subData.unsubscribe();
        this.subUser.unsubscribe();
        if (this.subManager) {
            this.subManager.unsubscribe();
        }
        this.confirmationDialog.confirmationDialogAccept.unsubscribe();
        this.confirmationDialog.confirmationDialogReject.unsubscribe();

        return super.ngOnDestroy();
    }

    toggleAdvSearch(event: boolean) {
        this.showAdvSearch = event;
    }

    toggleAllRows() {
        const thisRef = this;
        if (!this.areRowsExpanded) {
            this.trainings.forEach(function (training) {
                if (training.status !== 'RoleDeleted') {
                    thisRef.expandedRows[training.roleTrainingId] = true;
                }
            });
            this.areRowsExpanded = true;
        } else {
            this.expandedRows = {};
            this.areRowsExpanded = false;
        }
    }

    openModal(myLearningCode: string) {
        this.cutrriculumDetails.showModal(myLearningCode);
    }

    closeModal() {
        this.modalOpened = false;
    }

    openConfirmationPopUp() {
        this.learnerService
            .verifyViewStatus(
                this.user.mudId,
                this.viewGeneratedOn,
                ManageComplianceTrainingView.SelectTraining
            )
            .subscribe((viewUpToDate) => {
                if (!viewUpToDate) {
                    this.confirmationDialog.showYes = false;
                    this.confirmationDialog.title =
                        'Manage Compliance Training: Refresh Needed';
                    this.confirmationDialog.text = `The myGadget Select Training view has synchronously been changed by your Manager/Direct Report or an Admin. The 'Select Training' view will be refreshed. You will see updated selections and be able to make any additional changes.`;
                    this.confirmationDialog.noText = 'OK';
                    this.confirmationDialog.confirmationDialogReject.subscribe(
                        () => this.ngOnInit()
                    );
                    this.confirmationDialog.openDialog();
                } else {
                    this.confirmationDialog.showYes = true;
                    this.confirmationDialog.title =
                        'MANAGE COMPLIANCE TRAINING: 3 STEP PROCESS';
                    this.confirmationDialog.text =
                        'Are you sure you want to confirm and continue?';
                    this.confirmationDialog.warning =
                        "IMPORTANT NOTE: Managing compliance training is a 3-step process including 1) Select Role, 2) Select Training, and 3) Manage myLearning Plan. If you confirm changes in this step, they are confirmed ONLY for this step. You are required to “Confirm” through step 3 for those changes to be updated into the learner's myLearning Plan.";
                    this.confirmationDialog.noText = 'CANCEL';
                    this.confirmationDialog.yesText = 'CONFIRM';
                    this.confirmationDialog.confirmationDialogAccept.subscribe(
                        () => this.confirmTrainingAssignments()
                    );
                    this.confirmationDialog.openDialog();
                }
            });
    }

    confirmTrainingAssignments() {
        this.trainings.forEach((t) => {
            const originalTraining = this.originalTranings.find(
                (ot) => ot.roleTrainingId === t.roleTrainingId
            );
            if (originalTraining.isSelected && !t.isSelected) {
                t.status = 'Unselected';
            }
        });
        const trainingsToConfirm = this.trainings.filter(
            (t) =>
                t.isSelected ||
                t.status === 'Unselected' ||
                t.status === 'RoleDeleted'
        );
        this.learnerService
            .confirmTraningSelection(
                trainingsToConfirm,
                this.user.mudId,
                this.isManager ? this.manager.mudId : this.user.mudId,
                this.resolveSelectedByRelation()
            )
            .subscribe({
                next: () => {
                    this.router.navigate(['mylearning-plan'], {
                        relativeTo: this.route.parent,
                    });
                },
                error: () => {
                    this.messageService.add({
                        text: 'There was a problem while confirming selection.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                },
            });
    }

    private resolveSelectedByRelation(): SelectedByRelation {
        if (!this.isManager) {
            return 'User';
        }
        if (this.isManagerProxy) {
            return 'Admin';
        }
        if (
            (this.user as DirectReport).delegatedTo ===
            this.authService.user.mudId
        ) {
            return 'DelegatedManager';
        }
        return 'Manager';
    }

    toggleManagerOverride(training) {
        if (training.managerMudIdForOverride) {
            training.managerMudIdForOverride = null;
        } else {
            training.managerMudIdForOverride = this.manager.mudId;
        }
    }

    checkIfItemIsAssignable(training) {
        training.revisionDate = training.myLearningRevisionDate;
        this.learnerService
            .checkIfItemIsAssignable(
                training,
                this.user.mudId,
                training.blockingCurriculumCode
            )
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.getDataToDisplay();
                        this.messageService.add({
                            text: `This item is no longer blocked from assignment`,
                            timeout: 15000,
                            style: 'is-success',
                        });
                    } else {
                        this.messageService.add({
                            text: `This item is still blocked by an assigned curriculum: ${training.blockingCurriculumCode}.`,
                            timeout: 15000,
                            style: 'is-warning',
                        });
                    }
                },
                error: () => {
                    this.messageService.add({
                        text: 'There was a problem while checking the item. Please try again later.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                },
            });
    }

    customSort(event: SortEvent) {
        this.utilService.sortArray(event.data, event.field, event.order);
    }

    resolveSelectedBy(training: Training): string {
        if (training.displaySelectedBy) {
            return training.displaySelectedBy;
        }

        return training.isSelected ? 'Pending' : '';
    }

    public excelExport = () => {
        this.utilService.exportToExcel(
            this.getExcelOutputData(this.trainings.filter((t) => t.isSelected)),
            'Trainings',
            'Select_Trainings_export'
        );
    };

    private getExcelOutputData(data: Training[]): excelOutput {
        const excelOutput: excelOutput = [];
        data.forEach((d) =>
            excelOutput.push({
                Role: d.roleName,
                'Topic Area': d.topicArea,
                'myLearning Code': d.myLearningCode,
                Title: d.title,
                'myLearning Description': d.description,
                'Compliance Setting': d.complianceSetting,
                'Selected By': d.selectedBy,
            })
        );
        return excelOutput;
    }
}

type excelOutput = Array<{
    Role: string;
    'Topic Area': string;
    'myLearning Code': string;
    Title: string;
    'myLearning Description': string;
    'Compliance Setting': string;
    'Selected By': string;
}>;
