import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { ManagerSearchItem } from '../models/managerSearchItem';

@Injectable({
    providedIn: 'root'
})
export class ManagerService {

    constructor(private http: HttpClient,
        private loadingService: LoadingService) { }

    Search(mudid: string, directReports: string[], query: string) {
        this.loadingService.show();

        const endpoint = `/mygadget/manager/${mudid}/searchTrainings?query=${query}`;
        return this.http.put<ManagerSearchItem[]>(endpoint, directReports).pipe(
            finalize(() => {
                this.loadingService.hide();
            })
        );
    }
}
