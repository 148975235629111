<ng-container *ngIf="user">
    <div class="container is-fullhd userData">
        <p><strong>{{user.displayName}}</strong></p>
        <p>{{user.jobTitle}}</p>
    </div>

    <div class="container is-fullhd menu">
        <div class="columns is-gapless">
            <div class="column">
                <a class="tab" routerLink="manage" routerLinkActive="is-active">MANAGE COMPLIANCE TRAINING</a>
            </div>
            <div class="column">
                <a class="tab" routerLink="status/false" routerLinkActive="is-active">VIEW LEARNING
                    STATUS/LAUNCH TRAINING</a>
            </div>
        </div>
    </div>

    <div class="container is-fullhd">
        <router-outlet></router-outlet>
    </div>
</ng-container>