import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { IAuthService } from '../iAuthService';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-sso-login',
    templateUrl: './sso-login.component.html',
    styleUrls: ['./sso-login.component.css'],
})
export class SsoLoginComponent implements OnInit {
    private returnUrl: string;
    public message = 'Redirecting to the identity provider...';

    constructor(
        private readonly ssoAuthService: IAuthService,
        private readonly router: Router,
        private readonly userService: UserService,
        private readonly route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

        const authFlowReturnUrl =
            await this.ssoAuthService.initAuthenticationAndGetRedirectPath();

        if (this.ssoAuthService.isLoggedIn()) {
            this.message = 'Logging in...';

            try {
                const userClaims = this.ssoAuthService.getClaims() as any;
                if (
                    !this.ssoAuthService.user ||
                    this.ssoAuthService.user.mudId != userClaims.sub
                ) {
                    const displayName = `${userClaims.given_name} ${userClaims.family_name}`;

                    await lastValueFrom(
                        this.userService.updateUser({
                            mudId: userClaims.sub,
                            displayName: displayName,
                            onLongTermLeave: false,
                        })
                    );

                    const user = await lastValueFrom(
                        this.userService.getUser(userClaims.sub)
                    );
                    const listOfReports = await lastValueFrom(
                        this.userService.getDirectReports()
                    );

                    user.displayName = displayName;

                    if (listOfReports && listOfReports.length > 0) {
                        this.ssoAuthService.reports = listOfReports;
                        user.isManager = true;
                    }

                    this.ssoAuthService.user = user;

                    const userViewData = await lastValueFrom(
                        this.userService.getUserViewData(user.mudId)
                    );
                    this.ssoAuthService.userViewData = userViewData;
                }
                this.router.navigateByUrl(
                    authFlowReturnUrl
                        ? decodeURIComponent(authFlowReturnUrl)
                        : this.returnUrl
                );
            } catch (error) {
                console.error(error);
            }
        } else {
            this.ssoAuthService.login(this.returnUrl);
        }
    }
}
