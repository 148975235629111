import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { JoyrideWrapperService } from "../services/joyride-wrapper.service";

@Component({ template: '' })
export abstract class HasTour implements OnInit, OnDestroy {

    public get prevButton(): TemplateRef<any> {
        return this.joyrideWrapperService.prevButtonTemplate;
    }
    public get nextButton(): TemplateRef<any> {
        return this.joyrideWrapperService.nextButtonTemplate;
    }
    public get doneButton(): TemplateRef<any> {
        return this.joyrideWrapperService.doneButtonTemplate;
    }
    public get stepContent(): TemplateRef<any> {
        return this.joyrideWrapperService.stepTemplate;
    }

    constructor(
        private readonly joyrideWrapperService: JoyrideWrapperService,
        private readonly r: Router
    ) { }

    protected abstract tourSteps: string[];

    ngOnDestroy(): never {
        this.joyrideWrapperService.removeSteps(this.tourSteps);

        // returning 'undefined as never' is a way to enforce using:
        // return super.ngOnDestroy();
        // if the child component implements OnDestroy
        return undefined as never;
    }

    ngOnInit(): never {
        this.joyrideWrapperService.addSteps(this.tourSteps);
        return undefined as never;
    }
}
