import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem("token");

        req = req.clone({
            withCredentials: true
        });

        if (!req.headers.has("Authorization") && token) {
            req = req.clone({
                headers: req.headers.set("Authorization", `Bearer ${token}`)
            });
        }

        return next.handle(req);
    }
}