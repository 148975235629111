import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectReport } from '../models/directReport';
import { IAuthService } from '../auth/iAuthService';
import { UserService } from '../services/user.service';
import { ManagerService } from '../services/manager.service';
import { MessageService } from '../services/message.service';
import { environment } from '../../environments/environment';
import { ManagerSearchComponent } from '../manager-search/manager-search.component';
import { HasTour } from '../has-tour/has-tour';
import { JoyrideWrapperService } from '../services/joyride-wrapper.service';
import { Subscription } from 'rxjs';
import { UserFullData } from '../models/userFullData';

@Component({
    selector: 'app-manager',
    templateUrl: './manager.component.html',
    styleUrls: ['./manager.component.css', '../shared-styles/tabs.css'],
})
export class ManagerComponent extends HasTour implements OnInit, OnDestroy {
    user: UserFullData | DirectReport;
    directReports: Array<DirectReport>;
    selectedId: string;
    delegationManagementUrl: string;

    private subReports: Subscription;
    private subUser: Subscription;
    private subDueDates: Subscription;

    @ViewChild('managerSearch') managerSearch: ManagerSearchComponent;

    public tourSteps = [
        'manager.direct-reports',
        'manager.waiver',
        'manager.deferral',
        'manager.pathway',
        'manager.reporting',
        'manager.search',
    ];
    protected tourLocalStorageKey = 'ManagerComponent';

    constructor(
        private readonly router: Router,
        private readonly authService: IAuthService,
        private readonly userService: UserService,
        private readonly messageService: MessageService,
        private readonly managerService: ManagerService,
        joyrideWrapperService: JoyrideWrapperService,
        private readonly route: ActivatedRoute
    ) {
        super(joyrideWrapperService, router);
    }

    ngOnInit() {
        this.subUser = this.authService.userValue.subscribe((result) => {
            this.user = result;
        });
        this.subReports = this.authService.reportList.subscribe((result) => {
            this.directReports = result;
            //check the due dates in the background
            if (this.subDueDates && !this.subDueDates.closed) {
                this.subDueDates.unsubscribe();
            }
            this.subDueDates = this.userService
                .checkDueDatesForDirectReports(result)
                .subscribe((updatedResult) => {
                    this.directReports = updatedResult;
                });
        });
        this.authService.direct = undefined;

        this.delegationManagementUrl = environment.delegationManagementUrl;

        return super.ngOnInit();
    }

    ngOnDestroy() {
        if (this.subReports && !this.subReports.closed) {
            this.subReports.unsubscribe();
        }
        if (this.subUser && !this.subUser.closed) {
            this.subUser.unsubscribe();
        }
        if (this.subDueDates && !this.subDueDates.closed) {
            this.subDueDates.unsubscribe();
        }
        this.clearUserSelection();

        return super.ngOnDestroy();
    }

    directReportSelected(report: DirectReport) {
        this.selectedId = report.userId;
        this.refreshOnLeaveState(report.userId);

        this.authService.direct = new DirectReport({
            userId: report.userId,
            userName: report.userName,
            jobTitle: report.jobTitle,
            delegatedFrom: report.delegatedFrom,
            onLongTermLeave: report.onLongTermLeave,
            delegatedTo: undefined,
            hasPastDueItems: undefined,
            mudId: undefined,
            displayName: undefined,
            manageMyLearningPlanDirty: undefined,
            selectTrainingDirty: undefined,
        });

        this.router
            .navigate(['./'], { relativeTo: this.route })
            .then(() =>
                this.router.navigate(['./learner'], { relativeTo: this.route })
            );
    }

    clearUserSelection(): void {
        this.authService.direct = undefined;
        this.selectedId = undefined;
    }

    initSearch(eventTarget: EventTarget) {
        const searchString = (eventTarget as HTMLInputElement).value;
        if (!searchString) return;

        const directReports = this.directReports.map((x) => x.userId);

        this.managerService
            .Search(this.user.mudId, directReports, searchString)
            .subscribe({
                next: (result) => {
                    this.managerSearch.openDialog(result);
                },
                error: (e) => {
                    this.messageService.add({
                        text: e.error,
                        timeout: 600000,
                        style: 'is-danger',
                    });
                },
            });
    }

    private refreshOnLeaveState(mudId: string): void {
        this.userService.getUser(mudId).subscribe({
            next: (user) => {
                const reportList = this.authService.reports;
                reportList.find(
                    (x) => x.userId === user.mudId
                ).onLongTermLeave = user.onLongTermLeave;
                this.authService.reports = reportList;
                if (
                    this.authService.direct.userId === user.mudId &&
                    this.authService.direct.onLongTermLeave !==
                        user.onLongTermLeave
                ) {
                    this.authService.direct = new DirectReport({
                        ...this.authService.direct,
                        onLongTermLeave: user.onLongTermLeave,
                        mudId: undefined,
                        displayName: undefined,
                    });
                }
            },
        });
    }
}
