import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { BusinessArea } from '../models/businessArea';
import { BusinessUnit } from '../models/businessUnit';
import { Group } from '../models/group';
import { Country } from '../models/country';
import { Site } from '../models/site';
import { Locations } from '../models/locations';
import { Hierarchies } from '../models/hierarchies';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root',
})
export class MetadataService {
    constructor(
        private http: HttpClient,
        private loadingService: LoadingService,
        private utilService: UtilService
    ) {}

    getBusinessUnits(): Observable<BusinessUnit[]> {
        this.loadingService.show();

        const endpoint = `/mygadget/metadata/businessUnits`;
        return this.http
            .get<BusinessUnit[]>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    updateBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/businessUnits/${businessUnit.id}`;

        return this.http
            .put<BusinessUnit>(endpoint, businessUnit)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    createBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/businessUnits`;

        return this.http
            .post<BusinessUnit>(endpoint, businessUnit)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    deleteBusinessUnit(businessUnit: BusinessUnit): Observable<any> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/businessUnits/${businessUnit.id}`;

        return this.http
            .delete(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getBusinessAreas(): Observable<BusinessArea[]> {
        this.loadingService.show();

        const endpoint = `/mygadget/metadata/businessAreas`;
        return this.http
            .get<BusinessArea[]>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    updateBusinessArea(businessArea: BusinessArea): Observable<BusinessArea> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/businessAreas/${businessArea.id}`;

        return this.http
            .put<BusinessArea>(endpoint, businessArea)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    createBusinessArea(businessArea: BusinessArea): Observable<BusinessArea> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/businessAreas`;

        return this.http
            .post<BusinessArea>(endpoint, businessArea)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    deleteBusinessArea(businessArea: BusinessArea): Observable<any> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/businessAreas/${businessArea.id}`;

        return this.http
            .delete(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getGroups(): Observable<Group[]> {
        this.loadingService.show();

        const endpoint = `/mygadget/metadata/groups`;
        return this.http
            .get<Group[]>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    updateGroup(group: Group): Observable<Group> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/groups/${group.id}`;

        return this.http
            .put<Group>(endpoint, group)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    createGroup(group: Group): Observable<Group> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/groups`;

        return this.http
            .post<Group>(endpoint, group)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    deleteGroup(group: Group): Observable<any> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/groups/${group.id}`;

        return this.http
            .delete(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getCountries(): Observable<Country[]> {
        this.loadingService.show();

        const endpoint = `/mygadget/metadata/countries`;
        return this.http
            .get<Country[]>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    updateCountry(country: Country): Observable<Country> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/countries/${country.id}`;

        return this.http
            .put<Country>(endpoint, country)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    createCountry(country: Country): Observable<Country> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/countries`;

        return this.http
            .post<Country>(endpoint, country)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    deleteCountry(country: Country): Observable<any> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/countries/${country.id}`;

        return this.http
            .delete(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getSites(): Observable<Site[]> {
        this.loadingService.show();

        const endpoint = `/mygadget/metadata/sites`;
        return this.http
            .get<Site[]>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    updateSite(site: Site): Observable<Site> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/sites/${site.id}`;

        return this.http
            .put<Site>(endpoint, site)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    createSite(site: Site): Observable<Site> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/sites`;

        return this.http
            .post<Site>(endpoint, site)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    deleteSite(sountry: Site): Observable<any> {
        this.loadingService.show();
        const endpoint = `/mygadget/metadata/sites/${sountry.id}`;

        return this.http
            .delete(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getRoles(
        buId: number,
        baId: number,
        groupId: number,
        countryId: number,
        siteId: number,
        getTransverse: boolean = true
    ) {
        this.loadingService.show();

        const p1 = buId == null ? '' : buId;
        const p2 = baId == null ? '' : baId;
        const p3 = groupId == null ? '' : groupId;
        const p4 = countryId == null ? '' : countryId;
        const p5 = siteId == null ? '' : siteId;

        const endpoint = `/mygadget/roles?buId=${p1}&baId=${p2}&groupId=${p3}&countryId=${p4}&siteId=${p5}&getTransverse=${getTransverse}`;
        return this.http
            .get<any>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    getLocations(locations: Locations): Observable<Locations> {
        const observables: { [key: string]: any } = {};
        const observableKeys: string[] = [];
        const subject = new Subject<Locations>();

        if (!locations) {
            locations = { countries: [], sites: [] };
        }

        if (!locations.countries?.length) {
            observables['countries'] = this.getCountries();
            observableKeys.push('countries');
        }
        if (!locations.sites?.length) {
            observables['sites'] = this.getSites();
            observableKeys.push('sites');
        }

        forkJoin(observables).subscribe({
            next: (result) => {
                if (result.countries) {
                    locations.countries = this.utilService.sortArray(
                        result.countries as Country[],
                        'value',
                        1
                    ) as Country[];
                }
                if (result.sites) {
                    locations.sites = this.utilService.sortArray(
                        result.sites as Site[],
                        'value',
                        1
                    ) as Site[];
                }
                subject.next(locations);
            },
        });

        return subject.asObservable();
    }

    getHierarchies(hierarchies: Hierarchies): Observable<Hierarchies> {
        const observables: { [key: string]: any } = {};
        const observableKeys: string[] = [];
        const subject = new Subject<Hierarchies>();

        if (!hierarchies) {
            hierarchies = { businessUnits: [], businessAreas: [], groups: [] };
        }

        if (!hierarchies.businessUnits?.length) {
            observables['businessUnits'] = this.getBusinessUnits();
            observableKeys.push('businessUnits');
        }
        if (!hierarchies.businessAreas?.length) {
            observables['businessAreas'] = this.getBusinessAreas();
            observableKeys.push('businessAreas');
        }
        if (!hierarchies.groups?.length) {
            observables['groups'] = this.getGroups();
            observableKeys.push('groups');
        }

        forkJoin(observables).subscribe({
            next: (result) => {
                if (result.businessUnits) {
                    hierarchies.businessUnits =
                        this.utilService.sortBusinessUnits(
                            result.businessUnits as BusinessUnit[]
                        );
                }
                if (result.businessAreas) {
                    hierarchies.businessAreas = this.utilService.sortArray(
                        result.businessAreas as BusinessArea[],
                        'value',
                        1
                    ) as BusinessArea[];
                }
                if (result.groups) {
                    hierarchies.groups = this.utilService.sortArray(
                        result.groups as Group[],
                        'value',
                        1
                    ) as Group[];
                }
                subject.next(hierarchies);
            },
        });

        return subject.asObservable();
    }

    getSplashScreenContent(): Observable<string> {
        this.loadingService.show();

        const endpoint = '/mygadget/metadata/splashScreenContent';
        return this.http
            .get<string>(endpoint)
            .pipe(finalize(() => this.loadingService.hide()));
    }

    updateSplashScreenContent(
        content: string,
        redisplay = false
    ): Observable<any> {
        this.loadingService.show();

        const endpoint = '/mygadget/metadata/splashScreenContent';
        const params = new HttpParams()
            .append('content', content)
            .append('redisplay', redisplay);
        return this.http
            .put(endpoint, null, { params: params })
            .pipe(finalize(() => this.loadingService.hide()));
    }
}
