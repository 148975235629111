<div class="container is-fullhd tabs-menu">
    <div class="columns is-gapless">
        <div class="column is-2">
            <a class="tab" routerLink="roles" routerLinkActive="is-active">SELECT ROLE(S)</a>
        </div>
        <div class="column is-2">
            <a class="tab" pTooltip="Confirmation required to process changes." tooltipPosition="top"
                tooltipStyleClass="tooltip-narrow" [tooltipDisabled]="!userViewData.selectTrainingDirty"
                [class.is-dirty]="userViewData.selectTrainingDirty" routerLink="training"
                routerLinkActive="is-active">SELECT
                TRAINING{{userViewData.selectTrainingDirty ? '*' : ''}}</a>
        </div>
        <div class="column is-3">
            <a class="tab" pTooltip="Update required to process changes." tooltipPosition="top"
                tooltipStyleClass="tooltip-narrow" [tooltipDisabled]="!userViewData.manageMyLearningPlanDirty"
                [class.is-dirty]="userViewData.manageMyLearningPlanDirty" routerLink="mylearning-plan"
                routerLinkActive="is-active">MANAGE MYLEARNING
                PLAN{{userViewData.manageMyLearningPlanDirty ? '*' :
                ''}}</a>
        </div>
    </div>
</div>

<div class="container is-fullhd">
    <router-outlet></router-outlet>
</div>