import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Country } from '../../models/country';
import { Location } from '../../models/location';
import { Locations } from '../../models/locations';
import { Site } from '../../models/site';
import { MetadataService } from '../../services/metadata.service';

@Component({
    selector: 'shared-locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.css'],
})
export class LocationsComponent implements OnInit {
    constructor(private metadataService: MetadataService) {}

    @Output() selectionChanged = new EventEmitter();

    public locations: Locations = { countries: [], sites: [] };
    public location: Location = {} as Location;

    listOfSitesFiltered: Site[];
    ddSitesDisabled: boolean = true;

    ngOnInit(): void {
        this.metadataService
            .getLocations(this.locations)
            .subscribe((l) => (this.locations = l));
    }

    public clearSelections() {
        this.listOfSitesFiltered = null;
        this.location.country = null;
        this.ddSitesDisabled = true;
        this.location.site = null;
        this.selectionChanged.emit();
    }

    countrySelected(country: Country) {
        if (!country) {
            this.listOfSitesFiltered = null;
            this.location.country = null;
            this.ddSitesDisabled = true;
            this.location.site = null;
            this.selectionChanged.emit();
            return;
        }

        this.ddSitesDisabled = false;
        if (this.locations.sites) {
            this.listOfSitesFiltered = this.locations.sites.filter(
                (x) => x.countryId === country?.id
            );
        }
        this.selectionChanged.emit();
    }
}
