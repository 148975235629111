import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { SsoLoginComponent } from './auth/sso-login/sso-login.component';
import { LearnerStatusComponent } from './learner-status/learner-status.component';
import { LearnerRolesComponent } from './learner-roles/learner-roles.component';
import { LearnerManageComponent } from './learner-manage/learner-manage.component';
import { LearnerTrainingComponent } from './learner-training/learner-training.component';
import { LearnerMylearningPlanComponent } from './learner-mylearning-plan/learner-mylearning-plan.component';
import { ManagerComponent } from './manager/manager.component';
import { LearnerComponent } from './learner/learner.component';
import { WaiverRequestsComponent } from './waiver-requests/waiver-requests.component';
import { environment } from 'src/environments/environment';
import { AdminComponent } from './admin/admin.component';
import { PathwaysComponent } from './admin/pathways/pathways.component';
import { SsoLogoutComponent } from './auth/sso-logout/sso-logout.component';
import { SuperAdminComponent } from './super-admin/super-admin.component';
import { DeferralRequestsComponent } from './deferral-requests/deferral-requests.component';
import { ManagerReportingComponent } from './manager-reporting/manager-reporting.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
    { path: '', redirectTo: '/learner/status/true', pathMatch: 'full' },
    { path: 'error', component: ErrorComponent },
    {
        path: 'login',
        component: environment.SSO.useSSO ? SsoLoginComponent : LoginComponent,
    },
    {
        path: 'logout',
        component: environment.SSO.useSSO ? SsoLogoutComponent : undefined,
        redirectTo: environment.SSO.useSSO ? undefined : '/learner/status/true',
    },
    {
        path: 'manager',
        component: ManagerComponent,
        canActivate: [AuthGuard],
        data: { manager: true },
        children: [
            {
                path: 'learner',
                component: LearnerComponent,
                children: [
                    { path: '', redirectTo: 'manage/roles', pathMatch: 'full' },
                    {
                        path: 'status/:showSplashScreen',
                        component: LearnerStatusComponent,
                        canActivate: [AuthGuard],
                        data: { manager: true },
                    },
                    {
                        path: 'manage',
                        component: LearnerManageComponent,
                        children: [
                            {
                                path: '',
                                redirectTo: 'roles',
                                pathMatch: 'full',
                            },
                            {
                                path: 'roles',
                                component: LearnerRolesComponent,
                                data: { manager: true },
                            },
                            {
                                path: 'training',
                                component: LearnerTrainingComponent,
                                data: { manager: true },
                            },
                            {
                                path: 'mylearning-plan',
                                component: LearnerMylearningPlanComponent,
                                data: { manager: true },
                            },
                        ],
                        canActivate: [AuthGuard],
                    },
                ],
                data: { manager: true },
            },
            {
                path: 'waiverrequests',
                component: WaiverRequestsComponent,
                canActivate: [AuthGuard],
                data: { manager: true },
            },
            {
                path: 'deferralrequests',
                component: DeferralRequestsComponent,
                canActivate: [AuthGuard],
                data: { manager: true },
            },
            {
                path: 'manager-reporting',
                component: ManagerReportingComponent,
                canActivate: [AuthGuard],
                data: { manager: true },
            },
            {
                path: 'pathways',
                component: PathwaysComponent,
                canActivate: [AuthGuard],
                data: { manager: true, admin: false },
            },
        ],
    },
    {
        path: 'learner',
        component: LearnerComponent,
        children: [
            { path: '', redirectTo: 'status/false', pathMatch: 'full' },
            {
                path: 'status/:showSplashScreen',
                component: LearnerStatusComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'manage',
                component: LearnerManageComponent,
                children: [
                    { path: '', redirectTo: 'roles', pathMatch: 'full' },
                    {
                        path: 'roles',
                        component: LearnerRolesComponent,
                        data: { manager: false },
                    },
                    {
                        path: 'training',
                        component: LearnerTrainingComponent,
                        data: { manager: false },
                    },
                    {
                        path: 'mylearning-plan',
                        component: LearnerMylearningPlanComponent,
                        data: { manager: false },
                    },
                ],
                canActivate: [AuthGuard],
            },
        ],
        canActivate: [AuthGuard],
    },
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        data: { adminOnly: true },
        loadChildren: () =>
            import('./admin/admin.module').then((m) => m.AdminModule),
    },
    {
        path: 'super-admin',
        component: SuperAdminComponent,
        canActivate: [AuthGuard],
        data: { superAdmin: true },
        loadChildren: () =>
            import('./super-admin/super-admin.module').then(
                (m) => m.SuperAdminModule
            ),
    },
    { path: '**', redirectTo: '/learner/status/true', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
