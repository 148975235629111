import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SsoAuthInterceptor implements HttpInterceptor {

  constructor(private readonly authStorage: OAuthStorage,
    private readonly errorHandler: OAuthResourceServerErrorHandler) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.api_url)) {
      const token = this.authStorage.getItem('access_token');
      let headers = request.headers.set('Requesting-Application', 'myGadget');
      headers = headers.set('Authorization', `Bearer ${token}`);

      request = request.clone({
        headers: headers
      });
    }

    return next.handle(request).pipe(
      catchError(err => this.errorHandler.handleError(err))
    );
  }
}
