import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const ssoConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.SSO.issuer,

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/login',

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: environment.SSO.clientId,

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first four are defined by OIDC.
    // Important: Request offline_access to get a refresh token
    // The api scope is a usecase specific one
    scope: 'profile openid offline_access',

    // showDebugInformation: true,

    logoutUrl: environment.SSO.logoutUrl
};