<div class="container is-fullhd">
    <div class="columns">
        <div class="column is-6 is-offset-3" [joyrideStep]="tourSteps[5]" title="Search" [stepContent]="stepContent"
            [stepContentParams]="{text:'Search for training by myLearning ID or mylearning item or curriculum title to see the training completion statuses for your staff.'}">
            <span class="p-input-icon-left fill">
                <i class="pi pi-search"></i>
                <input class="fill" pInputText type="search" (keyup.enter)="initSearch($event.target)"
                    placeholder="Search assigned trainings for your direct reports" />
            </span>
        </div>
    </div>
    <div class="container is-fullhd menu">
        <div class="columns is-gapless">
            <div class="column" [joyrideStep]="tourSteps[1]" title="Waiver Requests" [stepContent]="stepContent"
                [stepContentParams]="{text:'Click on this menu option to review and approve or deny pending Waiver requests from your staff.'}">
                <a class="tab" routerLink='./waiverrequests' routerLinkActive="is-active"
                    (click)="clearUserSelection()">My Waiver Requests</a>
            </div>
            <div class="column" [joyrideStep]="tourSteps[2]" title="Deferral Requests" [stepContent]="stepContent"
                [stepContentParams]="{text:'Click on this menu option to review and approve or deny pending Deferral requests from your staff.'}">
                <a class="tab" routerLink="./deferralrequests" routerLinkActive="is-active"
                    (click)="clearUserSelection()">My Deferral Requests</a>
            </div>
            <div class="column" [joyrideStep]="tourSteps[3]" title="Pathways" [stepContent]="stepContent"
                [stepContentParams]="{text:'Click on this menu option manage pathways.'}">
                <a class="tab" routerLink='./pathways' routerLinkActive="is-active"
                    (click)="clearUserSelection()">Pathways</a>
            </div>
            <div class="column" [joyrideStep]="tourSteps[4]" title="Manager Reporting" [stepContent]="stepContent"
                [stepContentParams]="{text:'Click on this menu option to generater manager reports.'}">
                <a class="tab" routerLink='./manager-reporting' routerLinkActive="is-active"
                    (click)="clearUserSelection()">Reporting</a>
            </div>

        </div>
    </div>
    <div class="columns is-gapless">
        <div class="column is-2 report-list" style="max-height: 700px; overflow-y: auto;">
            <div [joyrideStep]="tourSteps[0]" title="Direct Reports" [prevTemplate]="prevButton"
                [nextTemplate]="nextButton" [doneTemplate]="doneButton" [stepContent]="stepContent"
                [stepContentParams]="{text:'Select a direct report to set up or review their compliance training.'}">
                <h2>my Direct Reports</h2>
                <a href='{{delegationManagementUrl}}' target="_blank"
                    class="subtext underline-hover is-size-7">myLearning:
                    Alternate Manager Settings</a>
                <div *ngFor="let report of directReports" class="direct-report"
                    [ngClass]="{'is-active':selectedId === report.userId}" (click)="directReportSelected(report)">
                    <p class="text-row is-size-6">{{report.userName}}
                        <span *ngIf="report.hasPastDueItems" class="pi pi-exclamation-triangle red"
                            pTooltip="One of the trainings is past due date!"></span>
                        <span *ngIf="report.onLongTermLeave" class="pi pi-briefcase red"
                            pTooltip="Learner is currently on long term leave."></span>
                    </p>
                    <p class="text-row is-size-7">{{report.jobTitle}}</p>
                    <p *ngIf="report.delegatedTo" class="text-row is-size-7">Delegated to {{report.delegatedTo}}</p>
                    <p *ngIf="report.delegatedFrom" class="text-row is-size-7">Delegated from {{report.delegatedFrom}}
                    </p>
                </div>
            </div>
        </div>
        <div class="column">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<manager-search #managerSearch></manager-search>