import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EditablePathwayDetailsComponent } from '../editable-pathway-details/editable-pathway-details.component';
import { ManagerProxyService } from '../../manager-proxy.service';
import { Pathway } from '../../models/pathway';
import { UserBase } from '../../models/userBase';
import { MessageService } from '../../services/message.service';
import { PathwayService } from '../../services/pathway.service';

@Component({
    selector: 'admin-add-pathway',
    templateUrl: './add-pathway.component.html',
    styleUrls: ['./add-pathway.component.css'],
})
export class AddPathwayComponent implements OnInit, OnDestroy {
    constructor(
        private pathwayService: PathwayService,
        private readonly managerProxyService: ManagerProxyService,
        private route: ActivatedRoute,
        private messageService: MessageService
    ) {}

    ngOnDestroy(): void {
        this.subData.unsubscribe();
    }

    @ViewChild('pathwayDetails')
    pathwayDetails: EditablePathwayDetailsComponent;

    @Input() listOfOwners: UserBase[] = [];
    @Input() isAdmin: boolean;
    @Output() pathwayAdded = new EventEmitter<Pathway>();
    public display: boolean;
    public pathway: Pathway = {} as Pathway;
    public validated: boolean = false;
    public isProxyManager: boolean;

    private subData: Subscription;

    public ngOnInit(): void {
        this.subData = this.route.data.subscribe(
            (data) => (this.isProxyManager = data.managerProxy)
        );
    }

    public closeDialog = () => (this.display = false);

    public submit = () => {
        this.pathwayDetails.validate();

        if (Pathway.isValid(this.pathway)) {
            this.pathwayService
                .addPathway(
                    this.pathway,
                    this.isProxyManager
                        ? this.managerProxyService.proxiedManager
                        : undefined
                )
                .subscribe({
                    next: (_) => {
                        this.pathwayAdded.emit(this.pathway);
                        this.display = false;
                    },
                    error: (error) => {
                        this.messageService.add({
                            text: error.error,
                            style: 'is-danger',
                            timeout: 10000,
                        });
                    },
                });
        }
    };

    public showDialog(pathway: Pathway) {
        this.pathway = pathway;
        this.display = true;
    }
}
