<div class="modal is-active" *ngIf="display">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box" *ngIf="step==='learner'">
            <p class="menu-label">Waiver Request</p>
            <div class="columns">
                <div class="column is-full">
                    <p>In requesting a Waiver credit for this training item, you are confirming that you have
                        already completed a different but equivalent training or have already acquired the knowledge
                        elsewhere, and therefore do not need to complete this training item.
                    </p>
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <p>
                        Please provide details of the previously completed training or competency acquired.​
                    </p>
                    <textarea [rows]="2" [cols]="40" pInputTextarea [(ngModel)]="request.waiveDetails"
                        autoResize="autoResize" maxlength="250" style="padding: 2px; width: 100%;"></textarea>
                    <div class="is-danger">Character limit 250.</div>
                    <div class="help is-danger" *ngIf="inputError">Field required.</div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-10">
                    <button pButton type="button" class="p-button-rounded p-button-outlined stdColor"
                        label="Send Request to Manager" (click)="sendRequest()"></button>
                </div>
                <div class="column is-2">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Cancel"
                        (click)="closeDialog()"></button>
                </div>
            </div>
        </div>
        <div class="box" *ngIf="step==='manager-1'">
            <p class="menu-label">Waiver Confirmation</p>
            <div class="columns">
                <div class="column is-full">
                    <p>In granting a Waiver credit for this training item, you are confirming that the learner has
                        already completed
                        a different but equivalent training or has already acquired the knowledge elsewhere, and
                        therefore does not need to complete this training item.
                    </p>
                    <p>
                        You will be prompted to provide/confirm details of the previously completed training or
                        competency acquired by the learner.
                    </p>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <button *ngIf="request.requestStatus==='Requested'" pButton type="button"
                        class="p-button-rounded p-button-outlined stdColor" label="Deny the Request"
                        (click)="sendReject()"></button>
                </div>
                <div class="column is-2 is-offset-4">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Approve"
                        (click)="approve()"></button>
                </div>
                <div class="column is-2">
                    <button pButton type="button" class="p-button-rounded p-button-outlined stdColor" label="Cancel"
                        (click)="closeDialog()"></button>
                </div>
            </div>
        </div>
        <div class="box" *ngIf="step==='manager-2'">
            <p class="menu-label">Documentation of Waiver Approval</p>
            <div class="columns">
                <div class="column is-full">
                    <p>
                        <b>Learner:</b> {{request.learnerName}}
                        <br>
                        <b>Completion Status:</b> Waiver for training (_W Completion Status in myLearning)​
                        <br>
                        <b>Item:</b> {{request.itemTitle}} ({{request.myLearningCode}})
                    </p>
                </div>
            </div>
            <div class="columns">
                <div class="column is-5">
                    <p>
                        <b>myLearning Waived Completion Comment:</b>
                        Provide details of the previously completed training ​or competency acquired by the learner. If
                        it’s internal ​GSK training, provide the applicable myLearning item ID.​
                    </p>
                </div>
                <div class=" column is-5">
                    <textarea [rows]="7" [cols]="40" pInputTextarea [(ngModel)]="request.waiveDetails"
                        autoResize="autoResize" maxlength="250" style="padding: 2px;"></textarea>
                    <div class="help">(Character limit 250)</div>
                    <div class="help is-danger" *ngIf="inputError">Field required.</div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-3 is-offset-7 ml-2">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Grant Waiver"
                        (click)="sendGrantWaiver()"></button>
                </div>
                <div class="column is-2">
                    <button pButton type="button" class="p-button-rounded p-button-outlined stdColor" label="Cancel"
                        (click)="goBack()"></button>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeDialog()"></button>
</div>