import { Component } from "@angular/core";
import { MessageService } from "../services/message.service";
import { Message } from "../models/message";

@Component({
    selector: 'b-message',
    templateUrl: './message.component.html',
    styleUrls: ['message.component.css']
}) export class MessageComponent {

    get messages() {
        return this.messageService.messages;
    };

    constructor(public messageService: MessageService) { }

    dismiss(message: Message) {
        this.messageService.dismiss(message);
    }

}