import { Component, OnInit } from '@angular/core';
import { Training } from '../../models/Training';
import { LearnerService } from '../../services/learner.service';
import { MessageService } from '../../services/message.service';

@Component({
    selector: 'shared-curriculum-details',
    templateUrl: './curriculum-details.component.html',
    styleUrls: ['./curriculum-details.component.css'],
})
export class CurriculumDetailsComponent implements OnInit {
    constructor(
        private readonly learnerService: LearnerService,
        private readonly messageService: MessageService
    ) {}

    public training: Training[];
    public isDisplayed: boolean;

    ngOnInit(): void {}

    public showModal(curriculumId: string) {
        this.learnerService.getTraingsForCurriculum(curriculumId).subscribe({
            next: (result) => {
                this.training = result;
                this.isDisplayed = true;
            },
            error: () => {
                this.messageService.add({
                    text: 'There was a problem while retriving curriculum details.',
                    timeout: 30000,
                    style: 'is-danger',
                });
            },
        });
    }

    public closeModal() {
        this.isDisplayed = false;
    }
}
