<div class="modal is-active" *ngIf="showDialog">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">{{title}}</p>
            <div class="columns">
                <div class="column is-full">
                    <span [innerHtml]="text"></span>
                    <p class="warning" [innerHtml]="warning"></p>
                </div>

            </div>
            <div class="columns">
                <div class="column">
                    <shared-two-buttons-container [confirmLabel]="yesText" [cancelLabel]="noText"
                        [onConfirm]="sendResult" [onCancel]="noClick" [showConfirm]="showYes">
                    </shared-two-buttons-container>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="noClick()"></button>
</div>