import { BehaviorSubject } from 'rxjs';
import { DirectReport } from '../models/directReport';
import { UserFullData } from '../models/userFullData';
import { UserViewData } from '../models/userViewData';

export abstract class IAuthService {
    abstract isLoggedIn(): boolean;
    abstract isLoggedIn(adminOnly: boolean): boolean;
    abstract login(redirectUrl?: string): Promise<void>;
    abstract login(login: string, password: string): Promise<any>;
    abstract logout(): Promise<void>;

    // --- TODO - get rid of it (either separate to a dedicated service, or change the behavior, so the subscription are not needed) ---
    abstract userValue: BehaviorSubject<UserFullData>;
    abstract directsMud: BehaviorSubject<DirectReport>;
    abstract reportList: BehaviorSubject<DirectReport[]>;
    abstract userView: BehaviorSubject<UserViewData>;
    abstract directsView: BehaviorSubject<UserViewData>;
    abstract set user(val: UserFullData);
    abstract get user(): UserFullData;
    abstract set userViewData(val: UserViewData);
    abstract get userViewData(): UserViewData;
    abstract set directsViewData(val: UserViewData);
    abstract get directsViewData(): UserViewData;
    abstract set direct(val: DirectReport);
    abstract get direct(): DirectReport;
    abstract set reports(val: DirectReport[]);
    abstract get reports(): DirectReport[];
    // ----------------------------------------------------------------------------------------------------------------------------------

    // sso only
    abstract initAuthenticationAndGetRedirectPath(): Promise<string>;
    abstract getClaims(): object;
}