import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAuthService } from '../iAuthService';
import { UserService } from '../../services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { from, lastValueFrom } from 'rxjs';
import { UserFullData } from 'src/app/models/userFullData';

const jwt = new JwtHelperService();

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    signinForm: FormGroup;

    isSubmitted: boolean = false;
    isLoading: boolean = false;
    error: string;

    returnUrl: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        public authService: IAuthService,
        public userService: UserService
    ) {}

    ngOnInit(): void {
        this.signinForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });

        // get return url from route parameters or default to '/activities'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    get f() {
        return this.signinForm.controls;
    }

    signin() {
        this.isSubmitted = true;
        this.error = '';

        if (this.signinForm.invalid) {
            return;
        }

        this.isLoading = true;
        from(
            this.authService.login(this.f.username.value, this.f.password.value)
        ).subscribe({
            next: async (response) => {
                const decodedToken = jwt.decodeToken(response.token);

                lastValueFrom(
                    this.userService.updateUser({
                        mudId: decodedToken.sub,
                        displayName: decodedToken.displayName,
                        onLongTermLeave: false,
                    })
                );

                const userData = {
                    mudId: decodedToken.sub,
                    displayName: decodedToken.displayName,
                    jobTitle: decodedToken.title,
                    isAdmin: false,
                    isManager: false,
                } as UserFullData;

                if (decodedToken.roles.indexOf('mygadget_admin') === -1) {
                    userData.isAdmin = false;
                } else {
                    userData.isAdmin = true;
                }

                if (decodedToken.roles.indexOf('mygadget_super_admin') === -1) {
                    userData.isSuperAdmin = false;
                } else {
                    userData.isSuperAdmin = true;
                }

                const listOfReports = await lastValueFrom(this.userService
                    .getDirectReports());

                if (listOfReports && listOfReports.length > 0) {
                    this.authService.reports = listOfReports;
                    userData.isManager = true;
                }

                this.authService.user = userData;

                this.userService
                    .getUserViewData(userData.mudId)
                    .subscribe((res) => (this.authService.userViewData = res));

                this.isLoading = false;

                this.router.navigateByUrl(this.returnUrl);
            },
            error: (error) => {
                this.error = error;
                this.isSubmitted = false;
                this.isLoading = false;
            },
        });
    }
}
