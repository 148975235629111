export class CombinedAssignment {
    itemAssignments: ItemAssignmentRequest[];
    curriculumAssignments: CurriculumAssignmentRequest[];
}

class AssignmentRequest {
    userId: string;
    assignmentDate: string;
    assigner: string;
}

export class ItemAssignmentRequest extends AssignmentRequest {
    itemId: string;
    itemTypeId: string;
    revisionDate: Date;
    assignmentType: string;
}

export class CurriculumAssignmentRequest extends AssignmentRequest {
    curriculumId: string;
}
