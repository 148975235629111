<div class="modal is-active" *ngIf="showModal">
    <div class="modal-background"></div>
    <div class="modal-content box" style="width: 80%; max-width: 1536px;">
        <p class="menu-label">Edit Pathway</p>
        <p-divider></p-divider>
        <div class="columns">
            <div class="column" #roleMetadataWrapper>
                <admin-editable-pathway-details #pathwayDetails [pathway]="pathway" [isAdmin]="isAdmin"
                    [listOfOwners]="listOfOwners">
                </admin-editable-pathway-details>
            </div>
        </div>
        <p-divider></p-divider>
        <div class="columns">
            <div class="column">
                <shared-two-buttons-container confirmLabel="SAVE" cancelLabel="CANCEL" [onConfirm]="save"
                    [onCancel]="close"></shared-two-buttons-container>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="close()"></button>
</div>