import { Component, OnInit } from '@angular/core';
import { UserBase } from '../../models/userBase';
import { AdminService } from '../../services/admin.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'shared-content-owners',
  templateUrl: './content-owners.component.html',
  styleUrls: ['./content-owners.component.css']
})
export class ContentOwnersComponent implements OnInit {

  constructor(private adminService: AdminService, private messageService: MessageService) { }

  listOfContentOwners: UserBase[] = [];
  contentOwnerSelected: UserBase[];

  ngOnInit(): void {
    this.adminService.getListOfContentOwners().subscribe({
      next: res => this.listOfContentOwners = res,
      error: _ => this.messageService.add({ text: 'There was an error when fetching content owners.', timeout: 30000, style: 'is-danger' })
    });
  }

  public clearSelections() {
    this.contentOwnerSelected = null;
  }
}
