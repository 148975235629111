import { Component, OnInit } from '@angular/core';
import { UserBase } from '../../models/userBase';
import { AdminService } from '../../services/admin.service';
import { MessageService } from '../../services/message.service';

@Component({
    selector: 'shared-admins-and-backupadmins',
    templateUrl: './admins-and-backupadmins.component.html',
    styleUrls: ['./admins-and-backupadmins.component.css'],
})
export class AdminsAndBackupadminsComponent implements OnInit {
    constructor(
        private adminService: AdminService,
        private messageService: MessageService
    ) {}

    listOfAdmins: UserBase[] = [];
    adminsSelected: UserBase[];

    ngOnInit(): void {
        this.adminService.getListOfAdmins().subscribe({
            next: (res) => (this.listOfAdmins = res),
            error: (_) =>
                this.messageService.add({
                    text: 'There was an error when fetching admins.',
                    timeout: 30000,
                    style: 'is-danger',
                }),
        });
    }

    public clearSelections() {
        this.adminsSelected = null;
    }
}
