<ng-container>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Content Owners</p>
            <p-multiSelect [options]="listOfContentOwners" placeholder="Content Owners" appendTo="body"
                [resetFilterOnHide]="true" styleClass="p-column-filter" optionValue="mudId" optionLabel="name"
                [(ngModel)]="contentOwnerSelected">
            </p-multiSelect>
        </div>
    </div>
</ng-container>