export class DirectReport {
    userId: string;
    userName: string;
    jobTitle: string;
    delegatedTo: string;
    delegatedFrom: string;
    hasPastDueItems: boolean;
    onLongTermLeave: boolean;
    selectTrainingDirty: boolean;
    manageMyLearningPlanDirty: boolean;

    public constructor(base: DirectReport) {
        this.userId = base.userId;
        this.userName = base.userName;
        this.jobTitle = base.jobTitle;
        this.delegatedTo = base.delegatedTo;
        this.delegatedFrom = base.delegatedFrom;
        this.hasPastDueItems = base.hasPastDueItems;
        this.onLongTermLeave = base.onLongTermLeave;
    }

    public get mudId(): string {
        return this.userId;
    }
    public get displayName(): string {
        return this.userName;
    }
}