import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UrlSelectorInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.isValidUrl(request.url)) {
      const url = environment.api_url + request.url.toString();

      request = request.clone({
        url: url
      });
    }

    return next.handle(request);
  }

  private isValidUrl(val: string): boolean {
    let url: URL;

    try {
      url = new URL(val);
    } catch {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
}
