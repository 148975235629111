import { Component, Input, Output, EventEmitter } from '@angular/core';
import { WaiverRequest } from '../models/waiverRequest';
import { LearnerService } from "../services/learner.service";
import { MessageService } from '../services/message.service';

@Component({
  selector: 'waiver-confirmation',
  templateUrl: './waiver-confirmation.component.html',
  styleUrls: ['./waiver-confirmation.component.css']
})
export class WaiverConfirmationComponent {

  step: 'learner' | 'manager-1' | 'manager-2';
  display = false;
  inputError = false;

  @Input() request: WaiverRequest;
  @Input() isManager: boolean;
  @Input() set showDialog(showDialog: boolean) {
    if (showDialog && this.isManager === undefined) { throw 'isManager property not set' }
    if (showDialog) { this.step = this.isManager ? 'manager-1' : 'learner' }
    this.display = showDialog;
  }

  @Output() showDialogChange = new EventEmitter<boolean>();
  @Output() requestStateChanged = new EventEmitter<WaiverRequest>();

  constructor(private learnerService: LearnerService, private messageService: MessageService) { }

  closeDialog() {
    this.showDialogChange.emit(false);
  }

  goBack() {
    this.step = 'manager-1'
  }

  approve() {
    this.step = 'manager-2';
  }

  sendRequest() {
    if (!this.validate()) {
      this.inputError = true;
      return
    }
    this.request.requestStatus = 'Requested';
    this.handleWaive(this.request);
  }

  sendReject() {
    this.request.requestStatus = 'Rejected';
    this.handleWaive(this.request);
  }

  sendGrantWaiver() {
    if (!this.validate()) {
      this.inputError = true;
      return
    }

    this.request.requestStatus = 'Approved';
    this.handleWaive(this.request);
  }

  private validate(): boolean {
    return !!this.request.waiveDetails;
  }

  private handleWaive(request: WaiverRequest): void {
    this.learnerService.handleWaiveRequest(request).subscribe({
      next: _ => {
        this.requestStateChanged.emit(request);
        this.closeDialog();
      },
      error: _ => this.messageService.add({ text: 'There was an error when raising waive request.', timeout: 30000, style: 'is-danger' })
    });
  }

}
