import { Injectable } from '@angular/core';
import * as fileSaver from 'file-saver';
import { WorkBook } from 'xlsx';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    constructor() {}

    public sortArray(
        data: Array<object>,
        field: string,
        order: number
    ): Array<object>;
    public sortArray(
        data: Array<object>,
        fields: Array<string>,
        order: number
    ): Array<object>;
    public sortArray(
        data: Array<object>,
        fields: any,
        order: number
    ): Array<object> {
        data.sort((data1, data2) => {
            if (typeof fields === 'string') {
                fields = [fields];
            }

            const value1 = this.getFieldForSorting(data1, fields);
            const value2 = this.getFieldForSorting(data2, fields);
            let result = null;

            if (value1 == null && value2 != null) result = -1;
            else if (value1 != null && value2 == null) result = 1;
            else if (value1 == null && value2 == null) result = 0;
            else if (typeof value1 === 'string' && typeof value2 === 'string')
                result = value1.localeCompare(value2, undefined, {
                    sensitivity: 'accent',
                });
            else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

            return order * result;
        });
        return data;
    }

    public sortBusinessUnits<T extends { value: string | number; id: number }>(
        list: T[]
    ): T[] {
        return list.sort((a, b) =>
            a.id === 1 ? -1 : b.id === 1 ? 1 : a.value < b.value ? -1 : 1
        );
    }

    private getFieldForSorting(data: object, fields: Array<string>): any {
        let fieldForSorting = undefined;
        fields.forEach((field) => {
            if (fieldForSorting === undefined && data[field])
                fieldForSorting = data[field];
        });
        return fieldForSorting;
    }

    isNewTraining(revisionDate: Date) {
        const revDate = new Date(revisionDate);
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);

        return revDate.getTime() >= currentDate.getTime();
    }

    seearchInsideCurriculaFilter(value, filter): boolean {
        const stringCompare = function (value, filter): boolean {
            if (!value) return false;
            return value
                .toString()
                .toLowerCase()
                .includes(filter.toLowerCase());
        };

        if (!filter) return true;

        if (!value) return false;

        const basicComparison = stringCompare(value, filter);
        if (Array.isArray(value) && value.length > 0) {
            let internalComparison = false;
            for (let i = 0; i < value.length; i++) {
                const item = value[i];
                internalComparison =
                    stringCompare(item.myLearningCode, filter) ||
                    stringCompare(item.title, filter) ||
                    stringCompare(item.description, filter);
                if (internalComparison) {
                    break;
                }
            }

            return basicComparison || internalComparison;
        } else {
            return basicComparison;
        }
    }

    public exportToExcel(
        data: Array<Object>,
        sheetName: string,
        fileName: string
    ): void {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook: WorkBook = { Sheets: {}, SheetNames: [sheetName] };
            workbook.Sheets[sheetName] = worksheet;
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });

            this.saveAsExcelFile(excelBuffer, fileName);
        });
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        fileSaver.saveAs(data, `${fileName}_${new Date().getTime()}.xlsx`);
    }
}
