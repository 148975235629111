<div class="box box-shadow">
    <div class="column is-4 is-offset-4">
        <h4 class="subtitle has-text-grey has-text-centered">Log in</h4>
        <form [formGroup]="signinForm" (ngSubmit)="signin()">
            <div class="field">
                <div class="control has-icons-left">
                    <input class="input" type="text" formControlName="username" placeholder="Username"
                        [class.is-danger]="(isSubmitted && f.username.errors) || error" />
                    <span class="icon is-small is-left"><i class="fas fa-user"></i></span>
                </div>
                <p *ngIf="isSubmitted && f.username.errors" class="help is-danger">Username is required.</p>
            </div>
            <div class="field">
                <div class="control has-icons-left">
                    <input class="input" type="password" formControlName="password" placeholder="Password"
                        [class.is-danger]="(isSubmitted && f.password.errors) || error" />
                    <span class="icon is-small is-left"><i class="fas fa-key"></i></span>
                </div>
                <p *ngIf="isSubmitted && f.password.errors" class="help is-danger">Password is required.</p>
                <p *ngIf="error" class="help is-danger">{{error}}</p>
            </div>
            <div class="field">
                <button class="button is-block is-link is-fullwidth" [class.is-loading]="isLoading">Log in</button>
            </div>
        </form>
    </div>
</div>