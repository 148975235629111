<ng-container>
    <div class="columns">
        <div class="column is-4">
            <div class="control p-mb-2">
                <p><strong>Pathway Id</strong></p>
                <p>{{pathway.readableId}}</p>
            </div>
            <div class="control">
                <p><strong>Pathway Name</strong></p>
                <p>{{pathway.name}}</p>
            </div>
            <div class="control p-mb-2">
                <p><strong>Pathway Version</strong></p>
                <div *ngIf="!showOldVersions">
                    <p class="p-mr-2 p-mt-2" style="font-size: 16;">{{pathway.version}} - {{pathway.createdAt |
                        date:'medium'}} (UTC)</p>
                    <button *ngIf="pathway.version > 1" pButton pRipple type="button" label="Show Other Versions"
                        class="p-button-rounded p-button-outlined" (click)="dispalyOldVersions()"></button>
                </div>
                <ng-container *ngIf="showOldVersions">
                    <p-dropdown [options]="oldPathwayVersions" placeholder="Version" appendTo="body"
                        optionLabel="version" [(ngModel)]="pathway" (onChange)="versionSelected()" dataKey="pk">
                    </p-dropdown> {{pathway.createdAt | date:'medium'}} (UTC)
                </ng-container>

            </div>
            <div class="control">
                <p><strong>Role Name</strong></p>
                <p>{{pathway.roleName}}</p>
            </div>
        </div>
        <div class="column is-4">
            <div class="control p-mb-4">
                <p><strong>Owner</strong></p>
                <p>{{pathway.owner?.name}} ({{pathway.owner?.mudId}})</p>
            </div>
            <div class="control">
                <p><strong>Back-up Owners</strong></p>
                <p>{{pathway.backupOwners | userBases}}</p>
            </div>
        </div>
        <div class="column is-4">
            <div class="control p-mb-4">
                <p><strong>Description</strong></p>
                <textarea title="Pathway Description" [rows]="10" pInputTextarea [(ngModel)]="pathway.description"
                    autoResize="autoResize" maxlength="2500" style="padding: 2px; width: 100%; resize:none;"
                    [disabled]="true">
                </textarea>
            </div>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1>Stage Gate 0</h1>
            <shared-stage-gate [sgNumber]="0" [flatNodes]="stageGate1Flat" [treeNodes]="stageGate1Tree"
                [isEditable]="false" [isValidated]="false" (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1 class="stage-header">Stage Gate 1</h1>
        </div>
        <div class="column">
            <h4 class="floatRight p-mb-1">Delay {{pathway.stageGate2?.delay}} days</h4>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <shared-stage-gate [sgNumber]="1" [flatNodes]="stageGate2Flat" [treeNodes]="stageGate2Tree"
                [isEditable]="false" [isValidated]="false" (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1 class="stage-header">Stage Gate 2</h1>
        </div>
        <div class="column">
            <h4 class="floatRight p-mb-1">Delay {{pathway.stageGate3?.delay}} days</h4>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <shared-stage-gate [sgNumber]="2" [flatNodes]="stageGate3Flat" [treeNodes]="stageGate3Tree"
                [isEditable]="false" [isValidated]="false" (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <p-divider></p-divider>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <h1 class="stage-header">Stage Gate 3</h1>
        </div>
        <div class="column">
            <h4 class="floatRight p-mb-1">Delay {{pathway.stageGate4?.delay}} days</h4>
        </div>
    </div>
    <div class="columns" style="padding-bottom: 1em;">
        <div class="column">
            <shared-stage-gate [sgNumber]="3" [flatNodes]="stageGate4Flat" [treeNodes]="stageGate4Tree"
                [isEditable]="false" [isValidated]="false" (displayCurriculumEvent)="displayCurriculum($event)">
            </shared-stage-gate>
        </div>
    </div>
    <shared-curriculum-details #curriculumDetails></shared-curriculum-details>
</ng-container>

<ng-template #emptymessage>
    <div class="columns">
        <div class="column" style="text-align: center;">
            No trainings defined in this stage.
        </div>
    </div>
</ng-template>

<ng-template #tableHeader>
    <tr>
        <th style="width: 48%;">Training Title</th>
        <th style="width: 16%;">Trigger</th>
        <th style="width: 23%;">Triggered By</th>
        <th style="width: 13%;">Delay in days</th>
    </tr>
</ng-template>