import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { TreeNode } from 'primeng/api';
import { PathwayTraining } from '../../models/pathwayTraining';

@Component({
  selector: 'shared-stage-gate',
  templateUrl: './stage-gate.component.html',
  styleUrls: ['./stage-gate.component.css']
})
export class StageGateComponent implements OnInit {

  constructor() { }

  @Input() flatNodes: TreeNode<PathwayTraining>[];
  @Input() treeNodes: TreeNode<PathwayTraining>[];
  @Input() isEditable: boolean = false;
  @Input() isValidated: boolean = false;
  @Input() sgNumber: number;

  @Output() displayCurriculumEvent = new EventEmitter<string>();
  @Output() adjustTreeEvent = new EventEmitter<void>();
  @Output() triggerTypeChanged = new EventEmitter<string>();
  @Output() trainingMoved = new EventEmitter<{ node: TreeNode<PathwayTraining>, source: number, dest: number }>();
  ngOnInit(): void {
  }


  public displayCurriculum(myLearningCode: string) {
    this.displayCurriculumEvent.emit(myLearningCode);
  }

  public getTriggerOption(rowNode: any): string[] {
    return this.getTriggeredByOption(rowNode).length > 0
      ? ['StageGate', 'TrainingCompletion']
      : ['StageGate']
  }

  public getTriggeredByOption(rowNode: any): PathwayTraining[] {
    let training = [] as PathwayTraining[];

    for (let n of this.treeNodes) {
      training.push(...this.getTriggerByOptions(n, rowNode.node));
    }
    return training;
  }

  public getTriggerByOptions(fromNode: TreeNode<PathwayTraining>, forNode: TreeNode<PathwayTraining>): PathwayTraining[] {
    let options = [] as PathwayTraining[]

    if (fromNode.data.roleTrainingId !== forNode.data.roleTrainingId) {
      options.push(fromNode.data);
      for (let child of fromNode.children) {
        options.push(...this.getTriggerByOptions(child, forNode))
      }
    }

    return options;
  }

  public isChild(node: TreeNode<PathwayTraining>, training: PathwayTraining): boolean {
    if (node.data.roleTrainingId === training.roleTrainingId) {
      return true;
    }

    if (node.children?.length > 0) {
      for (let element of node.children) {
        if (element.data.roleTrainingId === training.roleTrainingId) {
          return true;
        } else if (this.isChild(element, training)) {
          return true;
        }
      }
    }

    return false;
  }

  public displayStageGateDropdown(rowNode: any): boolean {
    return !rowNode.node.parent;
  }

  public moveTraining(event, training: any) {
    this.trainingMoved.emit({ node: training.node, source: this.sgNumber, dest: event.value })

  }

  public adjustTree() {
    this.adjustTreeEvent.emit();
  }

  public adjustTrigerType(event, training: PathwayTraining) {
    if (event.value === 'StageGate') {
      training.trigger = undefined;
      training.delay = undefined;
      this.triggerTypeChanged.emit(event.value);
    }
  }
}
