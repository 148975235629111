import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'shared-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent {
    @Input() title = 'Confirm deletion';
    @Input() text = 'Are you sure you want to delete this item?';
    @Input() warning = '';
    @Input() noText = 'Cancel';
    @Input() yesText = 'Delete';
    @Input() showYes = true;

    @Output() confirmationDialogAccept = new EventEmitter<void>();
    @Output() confirmationDialogReject = new EventEmitter<void>();

    showDialog: boolean = false;

    constructor() {}

    public openDialog() {
        this.showDialog = true;
    }

    public closeDialog() {
        this.showDialog = false;
    }

    public noClick = () => {
        this.confirmationDialogReject.emit();
        this.closeDialog();
    };

    public sendResult = () => {
        this.confirmationDialogAccept.emit();
        this.closeDialog();
    };
}
