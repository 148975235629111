import { Component, OnInit } from '@angular/core';
import { ManagerProxyService } from '../manager-proxy.service';
import { DeferralRequest } from '../models/deferralRequest';
import { LearnerService } from '../services/learner.service';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-deferral-requests',
  templateUrl: './deferral-requests.component.html',
  styleUrls: ['./deferral-requests.component.css']
})
export class DeferralRequestsComponent implements OnInit {

  public itemForDeferral: DeferralRequest;
  public showDeferralConfirmation: boolean;
  public deferralRequests: DeferralRequest[];
  public globalSearchInput: string;

  constructor(private readonly learnerService: LearnerService,
    private readonly messageService: MessageService,
    private readonly managerProxyService: ManagerProxyService) { }

  ngOnInit(): void {
    this.learnerService.getAllPendingDeferralRequestsForDirectReports(this.managerProxyService.proxiedManager ?? undefined)
      .subscribe({
        next: deferralRequests => this.deferralRequests = deferralRequests,
        error: _ => this.messageService.add({ text: 'There was an error when retrieving deferral requests.', timeout: 30000, style: 'is-danger' })
      });
  }

  deferral(deferralRequest: DeferralRequest): void {
    this.itemForDeferral = {
      ...deferralRequest,
      userId: deferralRequest.userId,
      learnerName: deferralRequest.learnerName,
      revisionDate: deferralRequest.revisionDate,
      itemTitle: deferralRequest.itemTitle,
      curricula: deferralRequest.curricula
    };
    this.showDeferralConfirmation = true;
  }

  onDeferralDialogSuccess(deferralRequest: DeferralRequest): void {
    if (deferralRequest.requestStatus !== 'Requested') {
      this.deferralRequests = this.deferralRequests.filter(dr =>
        dr.myLearningCode !== deferralRequest.myLearningCode
        || dr.myLearningTypeId !== deferralRequest.myLearningTypeId
        || dr.revisionDate !== deferralRequest.revisionDate);
    }
  }

  getCurriculaDescriptions(request: DeferralRequest): string {
    return request.curricula.sort((a, b) => a.code?.localeCompare(b.code)).map(c => `${c.code ?? 'Single Item'} ${c.title ? '-' : ''} ${c.title ?? ''}`).join(',\n');
  }
}
