import { StageGate } from "./stageGate";
import { UserBase } from "./userBase";

export class Pathway {
  pathwayId: string;
  pk: string;
  name: string;
  roleId: number;
  roleName: string;
  version: number;
  description: string;
  owner: UserBase;
  backupOwners: UserBase[];
  stageGate1: StageGate;
  stageGate2: StageGate;
  stageGate3: StageGate;
  stageGate4: StageGate;
  readableId: string;
  createdAt: Date;

  static isValid(pathway: Pathway): boolean {
    return (
      !!pathway.name &&
      !!pathway.roleId &&
      !!pathway.description &&
      !!pathway.owner &&
      !!pathway.owner.mudId &&
      !!pathway.backupOwners &&
      pathway.backupOwners.length > 0 &&
      !!pathway.stageGate1 &&
      !!pathway.stageGate2 &&
      !!pathway.stageGate3 &&
      !!pathway.stageGate4 &&
      !pathway.backupOwners.map(bo => bo.mudId).includes(pathway.owner.mudId) &&
      !pathway.stageGate1.training.filter(t => t.triggerType === "TrainingCompletion").some(t => !t.trigger) &&
      !pathway.stageGate2.training.filter(t => t.triggerType === "TrainingCompletion").some(t => !t.trigger) &&
      !pathway.stageGate3.training.filter(t => t.triggerType === "TrainingCompletion").some(t => !t.trigger) &&
      !pathway.stageGate4.training.filter(t => t.triggerType === "TrainingCompletion").some(t => !t.trigger)
    );
  }
}


