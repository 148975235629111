<div class="modal is-active" *ngIf="showDialog">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">Search</p>
            <div class="columns">
                <div class="column is-full">
                    <p-table #dtResults [value]="searchResults" styleClass="p-datatable-sm"
                        [expandedRowKeys]="expandedRows" sortField="userName" [sortOrder]="1" dataKey="rowId"
                        [filterDelay]="0"
                        [globalFilterFields]="['userName','roleName','roleDescription','trainingType','myLearningCode','trainingTitle','completionStatus']">
                        <ng-template pTemplate="caption" class="narrow">
                            <div class="columns">
                                <div class="column is-6 short narrow">
                                </div>
                                <div class="column is-3 is-offset-3 short narrow">
                                    <span class="p-input-icon-left fill">
                                        <i class="pi pi-search"></i>
                                        <input class="fill" pInputText type="text" [(ngModel)]="globalSearchInput"
                                            (input)="dtResults.filterGlobal(globalSearchInput, 'contains')"
                                            placeholder="Filter results" />
                                    </span>
                                </div>
                            </div>
                            <div class="columns">
                                <div class="column is-2 is-offset-10 short narrow important">
                                    <shared-toggle-table-filters-button (buttonToggled)="toggleAdvSearch($event)">
                                    </shared-toggle-table-filters-button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem"></th>
                                <th pSortableColumn="userName">User Name
                                    <p-sortIcon field="userName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="roleName">Role Name
                                    <p-sortIcon field="roleName"></p-sortIcon>
                                </th>
                                <th pSortableColumn="myLearningCode">Training Id
                                    <p-sortIcon field="myLearningCode"></p-sortIcon>
                                </th>
                                <th pSortableColumn="trainingType">Type
                                    <p-sortIcon field="trainingType"></p-sortIcon>
                                </th>
                                <th pSortableColumn="trainingTitle">Title
                                    <p-sortIcon field="trainingTitle"></p-sortIcon>
                                </th>
                                <th pSortableColumn="completionStatus">Status
                                    <p-sortIcon field="completionStatus"></p-sortIcon>
                                </th>
                            </tr>
                            <tr #advSearch [ngClass]="{'collapsedContent': showAdvSearch === false}" class="notAHeader">
                                <th></th>
                                <th>
                                    <input pInputText type="text" [(ngModel)]="userNameInput"
                                        (input)="dtResults.filter(userNameInput, 'userName', 'contains')"
                                        placeholder="Search user name" class="p-column-filter">
                                </th>
                                <th>
                                    <input pInputText type="text" [(ngModel)]="roleInput"
                                        (input)="dtResults.filter(roleInput, 'roleName', 'contains')"
                                        placeholder="Search role name" class="p-column-filter">
                                </th>
                                <th>
                                    <input pInputText type="text" [(ngModel)]="trainingInput"
                                        (input)="dtResults.filter(trainingInput, 'myLearningCode', 'contains')"
                                        placeholder="Search training id" class="p-column-filter">
                                </th>
                                <th>
                                    <p-multiSelect [options]="advSearchTrainingType" appendTo="body" placeholder="All"
                                        (onChange)="dtResults.filter($event.value, 'trainingType', 'in')"
                                        styleClass="p-column-filter" [resetFilterOnHide]="true">
                                        <ng-template let-option pTemplate="item">
                                            <div class="p-multiselect-representative-option">
                                                {{option.label}}
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </th>
                                <th>
                                    <input pInputText type="text" [(ngModel)]="titleInput"
                                        (input)="dtResults.filter(titleInput, 'trainingTitle', 'contains')"
                                        placeholder="Search training title" class="p-column-filter">
                                </th>
                                <th>
                                    <p-multiSelect [options]="advSearchCompletionStatus" appendTo="body"
                                        placeholder="All"
                                        (onChange)="dtResults.filter($event.value, 'completionStatus', 'in')"
                                        styleClass="p-column-filter" [resetFilterOnHide]="true">
                                        <ng-template let-option pTemplate="item">
                                            <div class="p-multiselect-representative-option">
                                                {{option.label}}
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-result let-expanded="expanded"
                            style="overflow: auto !important">
                            <tr>
                                <td><button type="button" pButton pRipple [pRowToggler]="result"
                                        class="p-button-text p-button-rounded p-button-plain"
                                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
                                <td>{{ result.userName }}</td>
                                <td>{{ result.roleStatusId === 0 ? result.roleName : result.roleName + " (inactive)" }}
                                </td>
                                <td>{{ result.myLearningCode }}</td>
                                <td>{{ result.trainingType }}</td>
                                <td>{{ result.trainingTitle }}</td>
                                <td>{{ result.completionStatus }}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-result>
                            <tr class="additionalInfo">
                                <td></td>
                                <td colspan="3" class="CellWithComment">
                                    <strong>Role Description</strong><br>
                                    <div>{{ result.roleDescription }}</div>
                                </td>
                                <td colspan="1">{{ result.parentId }}</td>
                                <td colspan="2">{{ result.parentTitle }}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">No roles or trainings found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="columns">
                <div class="column is-2 is-offset-10">
                    <button pButton type="button" class="p-button-rounded p-button-outlined stdColor" label="Close"
                        (click)="closeDialog()"></button>
                </div>
            </div>
        </div>
    </div>
    <button class="modal-close is-large" aria-label="close" (click)="closeDialog()"></button>
</div>