import { ComplianceSetting } from "./complianceSetting";

export class DeferralRequest {
    userId: string;
    learnerName: string;
    myLearningCode: string;
    myLearningTypeId: string;
    revisionDate: Date;
    requestStatus: 'Requested' | 'Approved' | 'Rejected' | 'Error' | 'Completed';
    itemTitle: string;
    trainingAssignmentId: number;
    curricula: Curriculum[];
    complianceSetting: ComplianceSetting;
    dueDate: Date;
    trainingAssignedBy: 'Learner' | 'Manager';
    deferSingleItem: boolean;
}

export class Curriculum {
    code: string;
    title: string;

    public constructor(code: string, title: string) {
        this.code = code;
        this.title = title;
    }
}