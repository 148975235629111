<div class="columns">
    <div class="column is-10">
        <div class="columns is-multiline is-mobile" [ngSwitch]="selectedReport">
            <div class="column is-half">
                <div class="control p-mb-4">
                    <p>Report Name</p>
                    <p-dropdown [options]="listOfReports" appendTo="body" autoWidth="false" [(ngModel)]="selectedReport"
                        styleClass="p-column-filter" placeholder="Select Report" [showClear]="true" [filter]="true">
                    </p-dropdown>
                </div>
            </div>
            <shared-roles-report #rolesReport style="display: contents"
                *ngSwitchCase="'Roles Report'"></shared-roles-report>
            <app-learner-status-report #learnerStatusReport style="display: contents;"
                *ngSwitchCase="'Learner Status Report'"></app-learner-status-report>
        </div>
        <div class="columns">
            <div *ngIf="selectedReport" class="column is-6 is-offset-6">
                <button pButton type="button" label="RESET" class="p-button-rounded p-button-outlined stdColor"
                    style="width: 48%;" (click)="reset()"></button>
                <button pButton type="button" label="GENERATE REPORT" class="floatRight p-button-rounded stdColor"
                    style="width: 48%;" (click)="generateButton()"></button>
            </div>
        </div>
        <p-table #dt [value]="generatedReports" styleClass="p-datatable-sm" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" sortField="generatedOn" [sortOrder]="-1"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[10,25,50]">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="reportName" style="width: 33%;">Report Name<p-sortIcon field="reportName">
                        </p-sortIcon>
                    </th>
                    <th pSortableColumn="generatedOn" style="width: 33%;">Last 30 days<p-sortIcon field="generatedOn">
                        </p-sortIcon>
                    </th>
                    <th style="width: 33%;">Actions</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-report>
                <tr>
                    <td>{{ report.reportName }}</td>
                    <td>{{ report.generatedOn | date:'medium' }}</td>
                    <td>
                        <div class="center">
                            <a class="p-mr-3" (click)="delete(report)">
                                <span class="icon"><i class="pi pi-trash"></i></span>
                            </a>
                            <a (click)="download(report)">
                                <span class="icon"><i class="pi pi-download"></i></span>
                            </a>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">No reports found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>