import { Pipe, PipeTransform } from '@angular/core';
import { Location } from '../../models/location'
@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {

  transform(value: Location): string {
    const locationVals = [value.country?.value, value.site?.value]
      .filter(v => v !== undefined);
    return locationVals.join(' / ');
  }
}
