import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';
import { Report } from '../models/report';
import { RolesReportConfig } from '../models/reportsConfigs/rolesReportConfig';
import { WaiversAndDeferralsReportConfig } from '../models/reportsConfigs/waiversAndDeferralsReportConfig';
import { UsersReportConfig } from '../models/reportsConfigs/usersReportConfig';
import { BusinessLevelHierarchy } from '../models/BusinessLevelHierarchy';
import { LocationHierarchy } from '../models/locationHierarchy';
import { RolesHistoryReportConfig } from '../models/reportsConfigs/rolesHistoryReportConfig';
import { DirectReport } from '../models/directReport';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {

  constructor(private http: HttpClient,
    private loadingService: LoadingService) { }

  getAllReports(managerMudId?: string): Observable<Report[]> {
    const endpoint = managerMudId ?
      `/mygadget/managerProxy/${managerMudId}/reports/all` :
      `/mygadget/reports/all`;

    this.loadingService.show();
    return this.http.get<Report[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  addRolesReport(config: RolesReportConfig, managerMudId?: string) {
    const endpoint = managerMudId ?
      `/mygadget/managerProxy/${managerMudId}/reports/rolesReport` :
      `/mygadget/reports/rolesReport`;

    this.loadingService.show();

    return this.http.post<void>(endpoint, config).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  addWaiversAndDeferralsReport(config: WaiversAndDeferralsReportConfig) {
    const endpoint = `/mygadget/reports/waiversAndDeferralsReport`;

    this.loadingService.show();

    return this.http.post<void>(endpoint, config).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  addUsersReport(config: UsersReportConfig) {
    const endpoint = `/mygadget/reports/usersReport`;

    this.loadingService.show();

    return this.http.post<void>(endpoint, config).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  addRolesHistoryReport(config: RolesHistoryReportConfig) {
    const endpoint = `/mygadget/reports/rolesHistoryReport`;

    this.loadingService.show();

    return this.http.post<void>(endpoint, config).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  addLearnerStatusReport(config: DirectReport[], managerMudId?: string) {
    const endpoint = managerMudId ?
      `/mygadget/managerProxy/${managerMudId}/reports/learnerStatusReport` :
      `/mygadget/reports/learnerStatusReport`;

    this.loadingService.show();

    return this.http.post<void>(endpoint, config).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  deleteReport(report: Report, managerMudId?: string) {
    this.loadingService.show();

    const endpoint = managerMudId ?
      `/mygadget/managerProxy/${managerMudId}/reports/report` :
      `/mygadget/reports/report`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: report,
    };

    return this.http
      .delete(endpoint, options)
      .pipe(finalize(() => this.loadingService.hide()));
  }

  downloadReport(report: number, managerMudId?: string): Observable<Blob> {
    this.loadingService.show();

    const endpoint = managerMudId ?
      `/mygadget/managerProxy/${managerMudId}/reports/download?reportId=${report}` :
      `/mygadget/reports/download?reportId=${report}`;

    return this.http.get<Blob>(endpoint, { responseType: 'blob' as 'json' })
      .pipe(finalize(() => this.loadingService.hide()));
  }

  getBlsHierarchy(): Observable<BusinessLevelHierarchy[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/reports/blsHierarchy`;
    return this.http.get<BusinessLevelHierarchy[]>(endpoint)
      .pipe(finalize(() => this.loadingService.hide()));
  }

  getLocationsHierarchy(): Observable<LocationHierarchy[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/reports/locationHierarchy`;
    return this.http.get<LocationHierarchy[]>(endpoint)
      .pipe(finalize(() => this.loadingService.hide()));
  }

  getAllCurrentRoles(): Observable<Role[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/reports/roles`;
    return this.http.get<Role[]>(endpoint)
      .pipe(finalize(() => this.loadingService.hide()));
  }

  getRolesByHistoricalTitles(query: string, justMine: boolean): Observable<Role[]> {
    this.loadingService.show();
    const endpoint = `/mygadget/reports/searchRoles?query=${query}&justMine=${justMine}`;
    return this.http.get<Role[]>(endpoint)
      .pipe(finalize(() => this.loadingService.hide()));
  }
}
