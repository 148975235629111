import { Injectable, TemplateRef } from '@angular/core';
import { JoyrideService } from 'ngx-joyride';
import { JoyrideOptions } from 'ngx-joyride/lib/models/joyride-options.class';
import { JoyrideStepInfo } from 'ngx-joyride/lib/models/joyride-step-info.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JoyrideWrapperService {

  private readonly _globalStepName = 'global.help-button';

  private readonly _tourSteps: string[] = [];


  public addSteps(steps: string[]): void {
    this._tourSteps.push(...steps);
  }

  public removeSteps(steps: string[]): void {
    steps.forEach(step => this._tourSteps.splice(this._tourSteps.indexOf(step), 1));
  }

  public get isTourActive(): boolean {
    return this._tourSteps !== undefined && this._tourSteps.length > 0;
  }

  public prevButtonTemplate: TemplateRef<any>;
  public nextButtonTemplate: TemplateRef<any>;
  public doneButtonTemplate: TemplateRef<any>;
  public stepTemplate: TemplateRef<any>;

  constructor(
    private readonly joyrideService: JoyrideService
  ) { }

  public startTour(skipManagerTour: boolean, options?: JoyrideOptions): Observable<JoyrideStepInfo> {
    if (!this.isTourActive) {
      throw new Error('Tour undefined');
    }

    if (!options) {
      options = { steps: this._tourSteps };
    }

    if (skipManagerTour) {
      options.steps = this.removeManagerTourInNestedView(options.steps);
    }

    options.steps = [...options.steps, this._globalStepName];
    return this.joyrideService.startTour(options);
  }

  public removeManagerTourInNestedView(steps: string[]) {
    return steps.filter(s => !s.includes('manager.'))
  }
}
