import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'shared-toggle-table-filters-button',
  templateUrl: './toggle-table-filters-button.component.html',
  styleUrls: ['./toggle-table-filters-button.component.css']
})
export class ToggleTableFiltersButtonComponent {

  @Output() buttonToggled = new EventEmitter<boolean>();

  public showAdvSearch = false;

  public toggleShowAdvSearch = () => {
    this.showAdvSearch = !this.showAdvSearch;
    this.buttonToggled.emit(this.showAdvSearch);
  }

  public getLabel = () => this.showAdvSearch ? 'Hide Filters' : 'Show Filters';
}
