<ng-container>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Admins</p>
            <p-multiSelect #adminMultiSelect [options]="listOfAdmins" placeholder="Admin/Back-up Admin" appendTo="body"
                styleClass="p-column-filter" optionValue="mudId" optionLabel="name" [(ngModel)]="adminsSelected"
                [resetFilterOnHide]="true">
            </p-multiSelect>
        </div>
    </div>
</ng-container>