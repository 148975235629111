import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthStorage } from 'angular-oauth2-oidc';

@Injectable()
export class SsoAuthStorage implements OAuthStorage {
    private readonly logged_in_key = "is_logged_in";
    private readonly access_token_key = "access_token";

    constructor(private readonly router: Router) {
        // when other browser tab logs out, delete session storage
        window.addEventListener("storage", (event: StorageEvent) => {
            if (event.key === this.logged_in_key) {
                sessionStorage.clear();
                this.router.navigateByUrl('/logout');
            }
        });
    }

    getItem(key: string): string {
        return sessionStorage.getItem(key);
    }

    removeItem(key: string): void {
        sessionStorage.removeItem(key);
        if (key === this.access_token_key) {
            // triggers eventListener from constructor
            localStorage.removeItem(this.logged_in_key);
        }
    }

    setItem(key: string, data: string): void {
        sessionStorage.setItem(key, data);
        if (key === this.access_token_key) {
            localStorage.setItem(this.logged_in_key, "true");
        }
    }
}