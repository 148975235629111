<div class="modal is-active" *ngIf="showDialog">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="box">
            <p class="menu-label">myGadget: Getting Started</p>
            <div class="columns">
                <div class="column is-full" style="white-space:pre-wrap;">
                    {{content}}
                </div>
            </div>
            <div class="columns">
                <div class="column is-1">
                    <p-checkbox [binary]="true" [(ngModel)]="donotShowAgain"></p-checkbox>
                </div>
                <div class="column is-3">
                    <label>Do not show it again</label>
                </div>
                <div class="column is-5">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Manage Compliance Training"
                        (click)="sendResult('/learner/manage')"></button>
                </div>
                <div class="column is-3">
                    <button pButton type="button" class="p-button-rounded stdColor" label="Default View"
                        (click)="sendResult('')"></button>
                </div>
            </div>
        </div>
    </div>
</div>