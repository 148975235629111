<ng-container>
    <div class="column is-half">
        <div class="control p-mb-4">
            <p>Direct Reports</p>
            <p-multiSelect #directsMultiSelect [options]="listOfDirects" placeholder="Direct Reports" appendTo="body"
                styleClass="p-column-filter" optionValue="userId" optionLabel="userName" [(ngModel)]="selectedDirects"
                selectedItemsLabel="{0} directs selected" [resetFilterOnHide]="true">
            </p-multiSelect>
        </div>
    </div>
</ng-container>