import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IAuthService } from './iAuthService';
import { Router } from '@angular/router';

import { throwError, BehaviorSubject, lastValueFrom } from 'rxjs';
import { UserFullData } from '../models/userFullData';
import { UserViewData } from '../models/userViewData';
import { DirectReport } from '../models/directReport';

const jwt = new JwtHelperService();

@Injectable()
export class AuthService implements IAuthService {
    userValue = new BehaviorSubject(this.user);
    directsMud = new BehaviorSubject(this.direct);
    reportList = new BehaviorSubject(this.reports);
    userView = new BehaviorSubject(this.userViewData);
    directsView = new BehaviorSubject(this.directsViewData);

    constructor(private http: HttpClient, private router: Router) {}

    set user(value: UserFullData) {
        this.userValue.next(value); // this will make sure to tell every subscriber about the change.
        if (value) {
            localStorage.setItem('user', JSON.stringify(value));
        } else {
            localStorage.removeItem('user');
        }
    }

    get user(): UserFullData {
        if (localStorage.getItem('user')) {
            return JSON.parse(localStorage.getItem('user'));
        } else {
            return null;
        }
    }

    set userViewData(value: UserViewData) {
        this.userView.next(value); // this will make sure to tell every subscriber about the change.
        if (value) {
            localStorage.setItem('userViewData', JSON.stringify(value));
        } else {
            localStorage.removeItem('userViewData');
        }
    }

    get userViewData(): UserViewData {
        if (localStorage.getItem('userViewData')) {
            return JSON.parse(localStorage.getItem('userViewData'));
        } else {
            return null;
        }
    }

    set directsViewData(value: UserViewData) {
        this.userView.next(value); // this will make sure to tell every subscriber about the change.
        if (value) {
            localStorage.setItem('directsViewData', JSON.stringify(value));
        } else {
            localStorage.removeItem('directsViewData');
        }
    }

    get directsViewData(): UserViewData {
        if (localStorage.getItem('directsViewData')) {
            return JSON.parse(localStorage.getItem('directsViewData'));
        } else {
            return null;
        }
    }

    set direct(value: DirectReport) {
        this.directsMud.next(value);
        if (value) {
            localStorage.setItem('direct', JSON.stringify(value));
        } else {
            localStorage.removeItem('direct');
        }
    }

    get direct(): DirectReport {
        if (localStorage.getItem('direct')) {
            return JSON.parse(localStorage.getItem('direct'));
        } else {
            return null;
        }
    }

    set reports(value) {
        this.reportList.next(value);
        if (value) {
            localStorage.setItem('reportList', JSON.stringify(value));
        } else {
            localStorage.removeItem('reportList');
        }
    }

    get reports() {
        if (localStorage.getItem('reportList')) {
            return JSON.parse(localStorage.getItem('reportList'));
        } else {
            return null;
        }
    }

    login(): Promise<any>;
    login(username: string, password: string): Promise<any>;
    login(username?: any, password?: any): Promise<any> {
        const headers = {
            Authorization: `Basic ${btoa(
                `${username.toLowerCase()}:${password}`
            )}`,
            'Requesting-Application': 'myGadget',
        };

        return lastValueFrom(
            this.http.get<any>(`/token`, { headers }).pipe(
                catchError((_) => {
                    return throwError(
                        () => new Error('Username and/or password incorrect.')
                    );
                }),
                tap(async (response) => {
                    if (response && response.token) {
                        localStorage.setItem('token', response.token);
                    }
                })
            )
        );
    }

    isLoggedIn(): boolean;
    isLoggedIn(adminOnly: boolean): boolean;
    isLoggedIn(adminOnly?: any): boolean {
        const token = localStorage.getItem('token');

        if (jwt.isTokenExpired(token)) {
            this.internalLogout();
            return false;
        }

        if (adminOnly) {
            if (this.user && this.user.isAdmin == true) {
                return true;
            }
            return false;
        }

        return true;
    }

    private internalLogout() {
        localStorage.removeItem('token');

        this.user = null;
        this.reports = null;
        this.direct = null;
    }

    logout() {
        this.internalLogout();
        this.router.navigateByUrl('/login');
        return Promise.resolve();
    }

    // declared for interface compatibility - can`t be used here
    initAuthenticationAndGetRedirectPath(): Promise<string> {
        return Promise.resolve('');
    }
    getClaims(): object {
        throw new Error('Method not implemented.');
    }
}
