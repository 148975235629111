import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { IAuthService } from '../auth/iAuthService';
import { DirectReport } from '../models/directReport';
import { UserFullData } from '../models/userFullData';
import { UserViewData } from '../models/userViewData';

@Component({
  selector: 'app-learner-manage',
  templateUrl: './learner-manage.component.html',
  styleUrls: ['./learner-manage.component.css']
})
export class LearnerManageComponent implements OnInit, OnDestroy {

  constructor(private readonly authService: IAuthService) { }

  private subUserView: Subscription;

  userViewData: UserViewData;

  ngOnInit(): void {
    this.subUserView = this.authService.userView.subscribe(
      r => { this.userViewData = r; }
    )
  }

  ngOnDestroy() {
    this.subUserView.unsubscribe();
  }
}
