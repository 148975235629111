import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddRoleComponent } from './add-role/add-role.component';
import { SharedModule } from '../shared/shared.module';
import { RoleTrainingsComponent } from './role-trainings/role-trainings.component';
import { AddRoleTrainingComponent } from './add-role-training/add-role-training.component';
import { TrainingBulkSearchComponent } from './training-bulk-search/training-bulk-search.component';
import { RoleMetadataComponent } from './role-metadata/role-metadata.component';
import { CopyRolesComponent } from './copy-roles/copy-roles.component';
import { DeleteRoleConfirmationComponent } from './delete-role-confirmation/delete-role-confirmation.component';
import { EditRolesComponent } from './edit-roles/edit-roles.component';
import { AdministratorsComponent } from './administrators/administrators.component';
import { TrainingManagementComponent } from './training-management/training-management.component';



@NgModule({
  declarations: [
    AddRoleComponent,
    AddRoleTrainingComponent,
    AdministratorsComponent,
    CopyRolesComponent,
    DeleteRoleConfirmationComponent,
    EditRolesComponent,
    RoleTrainingsComponent,
    RoleMetadataComponent,
    TrainingBulkSearchComponent,
    TrainingManagementComponent
    
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    AddRoleComponent,
    AddRoleTrainingComponent,
    AdministratorsComponent,
    CopyRolesComponent,
    DeleteRoleConfirmationComponent,
    EditRolesComponent,
    RoleTrainingsComponent,
    RoleMetadataComponent,
    TrainingBulkSearchComponent,
    TrainingManagementComponent
  ]
})
export class AdminSharedModule { }
