import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { IAuthService } from '../auth/iAuthService';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { DisplayPathwayComponent } from '../shared/display-pathway/display-pathway.component';
import { HasTour } from '../has-tour/has-tour';
import { CallType } from '../models/callType';
import { DirectReport } from '../models/directReport';
import { ManageComplianceTrainingView } from '../models/enums/manageComplianceTrainingView';

import { MyLearningPlanItem } from '../models/myLearningPlanItem';
import { UserFullData } from '../models/userFullData';
import { JoyrideWrapperService } from '../services/joyride-wrapper.service';
import { LearnerService } from '../services/learner.service';
import { MessageService } from '../services/message.service';
import { PathwayService } from '../services/pathway.service';
import { UserService } from '../services/user.service';
import { UtilService } from '../services/util.service';

@Component({
    selector: 'app-learner-mylearning-plan',
    templateUrl: './learner-mylearning-plan.component.html',
    styleUrls: ['./learner-mylearning-plan.component.css'],
})
export class LearnerMylearningPlanComponent
    extends HasTour
    implements OnInit, OnDestroy
{
    @ViewChild('confirmationDialog')
    confirmationDialog: ConfirmationDialogComponent;
    @ViewChild('displayPathwayModal')
    displayPathwayModal: DisplayPathwayComponent;

    myLearningItems: MyLearningPlanItem[];
    showAdvSearch: boolean = false;
    user: UserFullData | DirectReport;
    manager: UserFullData;

    listOfSettings: any;
    types: any;
    listOfAssignedBy: any;
    private isManager: boolean;
    private isManagerProxy: boolean;
    private subData: Subscription;
    private subUser: Subscription;
    private subManager: Subscription;
    private viewGeneratedOn: Date;
    myLCodeInput: string;
    globalSearchInput: string;
    titleInput: string;
    roleNameInput: string;

    public tourSteps = [
        'learner-mylearning-plan.assign',
        'learner-mylearning-plan.update',
    ];
    protected tourLocalStorageKey = 'LearnerMylearningPlan';

    constructor(
        private readonly learnerService: LearnerService,
        private readonly authService: IAuthService,
        private readonly messageService: MessageService,
        public readonly utilService: UtilService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly userService: UserService,
        private readonly datePipe: DatePipe,
        private readonly pathwayService: PathwayService,
        joyrideWrapperService: JoyrideWrapperService
    ) {
        super(joyrideWrapperService, router);
    }

    ngOnInit() {
        this.subData = this.route.data.subscribe((data) => {
            this.isManager = data.manager;
            this.isManagerProxy = data.managerProxy;

            if (this.isManager) {
                this.subUser = this.authService.directsMud.subscribe(
                    (result) => {
                        this.user = result;
                    }
                );
                this.subManager = this.authService.userValue.subscribe(
                    (result) => {
                        this.manager = result;
                    }
                );
            } else {
                this.subUser = this.authService.userValue.subscribe(
                    (result) => {
                        this.user = result;
                    }
                );
            }
        });

        this.userService.getUserViewData(this.user.mudId).subscribe((res) => {
            this.authService.userViewData = res;
        });

        this.learnerService
            .getAssignedTrainingsStatuses(this.user.mudId)
            .subscribe((result) => {
                this.listOfSettings = result.training
                    .map((item) => item.complianceSetting)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.types = result.training
                    .map((item) => item.trainingType)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.listOfAssignedBy = result.training
                    .map((item) => item.displayAssignedBy)
                    .filter(
                        (value, index, self) => self.indexOf(value) === index
                    )
                    .map((i) => {
                        return { label: i, value: i };
                    })
                    .sort((a, b) => (a.label < b.label ? -1 : 1));

                this.viewGeneratedOn = result.generatedOn;

                result.training.forEach((element) => {
                    if (element.dueDate) {
                        element.displayDueDate = this.datePipe
                            .transform(element.dueDate, 'dd-MMM-yyyy')
                            .toString();
                    }
                });

                this.myLearningItems = result.training;
            });

        return super.ngOnInit();
    }

    ngOnDestroy() {
        this.subData.unsubscribe();
        this.subUser.unsubscribe();
        if (this.subManager) {
            this.subManager.unsubscribe();
        }

        return super.ngOnDestroy();
    }

    toggleAdvSearch(event: boolean) {
        this.showAdvSearch = event;
    }

    toggleTraining(training) {
        training.assigned = !training.assigned;
        training.isStruckThrough = !training.isStruckThrough;
    }

    confirmTrainingAssignments() {
        this.learnerService
            .checkIfRolesAreBeingProcessed(this.user.mudId)
            .subscribe((result) => {
                if (result) {
                    this.messageService.add({
                        text: 'One of your roles is being processed. Please try again later.',
                        timeout: 30000,
                        style: 'is-danger',
                    });
                } else {
                    this.learnerService
                        .verifyViewStatus(
                            this.user.mudId,
                            this.viewGeneratedOn,
                            ManageComplianceTrainingView.ManageMyLearningPlan
                        )
                        .subscribe((viewUpToDate) => {
                            if (!viewUpToDate) {
                                this.confirmationDialog.showYes = false;
                                this.confirmationDialog.title =
                                    'Manage Compliance Training: Refresh Needed';
                                this.confirmationDialog.text = `The myGadget Manage myLearning Plan view has synchronously been changed by your Manager/Direct Report or an Admin. The 'Manage myLearning Plan' view will be refreshed. You will see updated selections and be able to make any additional changes.`;
                                this.confirmationDialog.noText = 'OK';
                                this.confirmationDialog.confirmationDialogReject.subscribe(
                                    () => this.ngOnInit()
                                );
                                this.confirmationDialog.openDialog();
                            } else {
                                this.learnerService
                                    .updateMyLearningPlan(
                                        this.user.mudId,
                                        this.isManager
                                            ? this.manager.mudId
                                            : this.user.mudId,
                                        this.myLearningItems.filter(
                                            (t) =>
                                                !(
                                                    t.status === 'Active' &&
                                                    !t.assigned &&
                                                    !t.assignedOriginal
                                                )
                                        ),
                                        this.resolveCallType()
                                    )
                                    .subscribe({
                                        next: () =>
                                            this.router.navigate(
                                                ['../status/false'],
                                                {
                                                    relativeTo:
                                                        this.route.parent,
                                                }
                                            ),
                                        error: (e) => {
                                            this.messageService.add({
                                                text: e.error,
                                                timeout: 600000,
                                                style: 'is-danger',
                                            });
                                            this.router.navigate(
                                                ['../status/false'],
                                                {
                                                    relativeTo:
                                                        this.route.parent,
                                                }
                                            );
                                        },
                                    });
                            }
                        });
                }
            });
    }

    private resolveCallType(): CallType {
        if (this.isManagerProxy) {
            return 'MyGadgetAdmin';
        }
        if ((this.user as DirectReport).delegatedFrom) {
            return 'MyGadgetManagerDelegate';
        }
        if (this.isManager) {
            return 'MyGadgetManager';
        }
        return 'MyGadgetSelfAssign';
    }

    toggleManagerOverride(training) {
        if (training.managerMudIdForOverride) {
            training.managerMudIdForOverride = null;
        } else {
            training.managerMudIdForOverride = this.manager.mudId;
        }
    }

    customSort(event: SortEvent) {
        this.utilService.sortArray(event.data, event.field, event.order);
    }

    assignNow(training: MyLearningPlanItem) {
        training.status = 'Active';
        training.assigned = true;
        training.isPendingOverride = true;
    }

    undoAssignNow(training: MyLearningPlanItem) {
        training.status = 'PathwayAssignment';
        training.assigned = false;
        training.isPendingOverride = undefined;
    }

    showPathwayDetails(pathwayPk: string) {
        this.pathwayService.getPathway(pathwayPk).subscribe((res) => {
            this.displayPathwayModal.showDialog(res);
        });
    }
}
