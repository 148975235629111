<div class="container is-fullhd menu">
    <div class="columns is-gapless">
        <div class="column">
            <a class="tab" routerLink="roles" routerLinkActive="is-active">ROLES</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="pathways" routerLinkActive="is-active">PATHWAYS</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="role-assignment" routerLinkActive="is-active">ROLE ASSIGNMENT</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="view-other-roles" routerLinkActive="is-active">VIEW OTHER ROLES</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="reporting" routerLinkActive="is-active">REPORTING</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="administrators" routerLinkActive="is-active">ADMINISTRATORS</a>
        </div>
        <div class="column">
            <a class="tab" routerLink="./manager-proxy" routerLinkActive="is-active">MANAGER PROXY</a>
        </div>
    </div>
</div>

<div class="container is-fullhd">
    <router-outlet></router-outlet>
</div>