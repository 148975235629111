import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pathway } from '../../models/pathway';
import { RoleBase } from '../../models/roleBase';
import { PathwayService } from '../../services/pathway.service';

@Component({
    selector: 'shared-pathway-selection',
    templateUrl: './pathway-selection.component.html',
    styleUrls: ['./pathway-selection.component.css'],
})
export class PathwaySelectionComponent implements OnInit {
    constructor(private readonly pathwayService: PathwayService) {}

    public selectedPathway: Pathway;
    public pathways: Pathway[];
    public display: boolean;
    public role: RoleBase;

    @Output() pathwaySelected = new EventEmitter<{
        pathway: Pathway;
        roleId: number;
    }>();

    ngOnInit(): void {}

    openModal(role: RoleBase) {
        this.role = role;
        if (!this.role.pathwaysLoaded) {
            this.pathwayService
                .getRolePathways(this.role.roleId)
                .subscribe((res) => {
                    this.role.pathways = res;
                    this.role.pathwaysLoaded = true;
                });
        }

        if (this.role.selectedPathway) {
            this.selectedPathway = this.role.selectedPathway;
        }

        this.display = true;
    }

    submit() {
        this.pathwaySelected.emit({
            pathway: this.selectedPathway,
            roleId: this.role.roleId,
        });
        this.closeModal();
    }

    closeModal() {
        this.selectedPathway = undefined;
        this.display = false;
    }
}
