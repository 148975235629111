import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';
import { PathwayTraining } from '../models/pathwayTraining';
import { AdminRole } from '../models/adminRole';

@Injectable({
  providedIn: 'root',
})
export class RoleService {

  constructor(private http: HttpClient,
    private loadingService: LoadingService) { }

  getRoleTraining(roleId: number) {
    const endpoint = `/mygadget/roleTraining/${roleId}`;

    this.loadingService.show();

    return this.http.get<PathwayTraining[]>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }

  getRoleMetadata(roleId: number) {
    const endpoint = `/mygadget/roleMetadata/${roleId}`;

    this.loadingService.show();

    return this.http.get<AdminRole>(endpoint).pipe(
      finalize(() => {
        this.loadingService.hide();
      }));
  }
}
